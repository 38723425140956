<template>
    <b-modal v-model="isOpen" title="Send a Message" hide-footer>
        <div class=" modal-container">
            <!-- <div style="margin: 10px; width: 100%;"> -->
            <div class="merge-fields-container mb-2 d-flex align-items-center">
                <label class="me-2 bold"><b>Merge Fields:</b></label>
                <div class="merge-fields">
                    <button v-for="field in mergeFields" :key="field" class="btn btn-sm merge-field-btn"
                        @click="insertField(field)">
                        {{ field }}
                    </button>
                </div>
            </div>
            <!-- Broadcast Message Input -->
            <textarea v-model="broadcastMessage" class="prompt-input" placeholder="  Enter your broadcast message..."
                rows="6" style="
                width: 440px;
                resize: none;
                max-height: 90px;
                overflow-y: auto;
                border-style: solid;
                border-width: 1px;
                border-color: #ccc;
                border-radius: 8px;
              "></textarea>
            <!-- <small>{{ broadcastMessage.length }}/320</small> -->
            <!-- </div> -->

            <!-- User Group Dropdown -->
            <div class="mt-3 d-flex align-items-center">
                <label class="me-2 bold"><b>Select User Group:</b></label>
                <select v-model="selectedGroup" class="form-control user-group-dropdown">
                    <option v-for="group in userGroups" :key="group.value" :value="group.value">
                        {{ group.label }}
                    </option>
                </select>
            </div>

            <!-- Individual Reps Selection -->
            <div class="mt-3">
                <label class="bold"><b>Individual Reps:</b></label>
                <div class="d-flex align-items-center mb-2">
                    <input type="checkbox" id="selectAll" v-model="selectAllChecked" @change="toggleSelectAll" />
                    <label for="selectAll" class="ms-2 ml-2">Select All</label>
                </div>
                <!-- switch reps with filteredReps -->
                <div class="scrollable-list d-flex">
                    <div class="rep-column" v-for="(column, columnIndex) in repsInColumns" :key="columnIndex">
                        <div v-for="rep in column" :key="rep.id" class="rep-item">
                            <input type="checkbox" v-model="selectedReps" :value="rep.id" />
                            {{ `${rep.firstName || ''} ${rep.lastName || ''}`.trim() }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="button-group call-button-right-aligned gap-2 mt-3">
                <button class="btn solid danger" type="button" @click="closeModal"
                    style="margin-right: 10px;">Cancel</button>
                <button class="btn solid danger" type="button" @click="sendBroadcast">
                    <i class="fa-solid fa-envelope"></i> Send Message
                </button>
            </div>
        </div>
    </b-modal>
</template>

<script>
import axios from 'axios';

export default {
    name: "teamChatBroadcastModal",
    props: {
        isOpen: {
            type: Boolean,
            required: true,
        },
        salesReps: {
            type: Array,
            default: () => [],
        },
        adminId: {
            type: [String, Number],
            required: true,
        },
    },
    data() {
        return {
            broadcastMessage: "",
            mergeFields: ["{{FirstName}}", "{{LastName}}", "{{Email}}", "{{Phone}}"],
            userGroups: [
                { value: "all", label: "All Sales Reps" },
                // { value: "salesToday", label: "Reps With Sales Today" },
                // { value: "salesTodayWithoutReps", label: "Reps Without Sales Today" },
                // { value: "salesTodayUnder2Hours", label: "Reps With Under 2 Hours of Talk Time Today" },
                // { value: "salesWithNoSales", label: "Reps With No Sales This Week" },
            ],
            selectedReps: [],
            selectAllChecked: true,
            selectedGroup: "",
        };
    },
    mounted() {
        this.selectedReps = this.salesReps.map((rep) => rep.id); // Select all reps by default
    },
    computed: {
        filteredReps() {
            return this.salesReps.filter(rep => rep.status === this.userStatus);
        },
        repsInColumns() {
            const columns = [[], [], []];
            this.salesReps.forEach((rep, index) => {
                columns[index % 3].push(rep); // Distribute reps into columns
            });
            return columns;
        },
    },
    methods: {

        closeModal() {
            this.$emit("update:isOpen", false);
        },
        insertField(field) {
            this.broadcastMessage += field;
        },
        toggleSelectAll() {
            if (this.selectAllChecked) {
                this.selectedReps = this.salesReps.map((rep) => rep.id);
            } else {
                this.selectedReps = [];
            }
        },

        async sendBroadcast() {
            if (!this.broadcastMessage.trim()) {
                alert("Broadcast message cannot be empty.");
                return;
            }

            if (this.selectedReps.length === 0) {
                alert("Please select at least one sales rep.");
                return;
            }

            try {

                await Promise.all(
                    this.selectedReps.map(async (salesRepId) => {
                        const roomExists = await this.checkOrCreateRoom(salesRepId);
                        if (!roomExists) {
                            throw new Error(`Failed to create room for sales rep ID: ${salesRepId}`);
                        }
                    })
                );
                // Prepare the payload
                const payload = {
                    selectedSalesReps: this.selectedReps, // Array of selected sales rep IDs
                    text: this.broadcastMessage, // Broadcast message
                    adminId: this.adminId, // Replace with the actual admin ID (can be dynamic)
                };

                // Send the API request
                const response = await axios.post("https://123avatars.com/v2/send-broadcast-messages", payload);

                if (response.status === 200) {
                    alert("Broadcast sent successfully!");
                    console.log("Broadcast Response:", response.data);

                    // Clear the modal fields after successful submission
                    this.broadcastMessage = "";
                    this.selectedReps = [];
                    this.selectAllChecked = false;
                    this.selectedGroup = "";

                    // Close the modal
                    this.closeModal();
                } else {
                    throw new Error("Failed to send broadcast. Please try again.");
                }
            } catch (error) {
                console.error("Error sending broadcast:", error);
                alert("Failed to send broadcast. Please try again.");
            }

        },

        async checkOrCreateRoom(salesRepId) {
            try {
                // Check if the room exists
                const response = await axios.get(
                    `https://123avatars.com/v2/team-check-room/${this.adminId}/${salesRepId}`
                );

                if (response.data.roomExists) {
                    console.log(`Room already exists for sales rep ID: ${salesRepId}`);
                    return true; // Room exists
                }

                // Create a new room if it doesn't exist
                const createRoomResponse = await axios.post("https://123avatars.com/v2/team-create-room", {
                    adminId: this.adminId,
                    managerId: null, // Assuming no manager is involved
                    salesId: salesRepId,
                    type: "single", // Room type
                });

                if (createRoomResponse.status === 200) {
                    console.log(`Room created successfully for sales rep ID: ${salesRepId}`);
                    return true; // Room created successfully
                }

                return false; // Failed to create room
            } catch (error) {
                console.error(`Error checking or creating room for sales rep ID: ${salesRepId}`, error);
                return false; // Error occurred
            }
        },
    },
};
</script>

<style scoped>
.merge-fields {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-left: 5px;
}

.merge-field-btn {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 0 6px;
    font-size: small;
}

.user-group-dropdown {
    width: 100%;
    font-size: 12px;
    margin-left: 10px !important;
}

.scrollable-list {
    max-height: 200px;
    overflow-y: auto;
    /* padding: 5px; */
}

.rep-row {
    flex: 1;
}

.rep-column {
    flex: 1;
}

.rep-item {
    display: flex;
    align-items: center;
    gap: 10px;
    flex: 1;
}

.call-button-right-aligned {
    display: flex;
    justify-content: flex-end;
}
</style>