<template>
  <div id="search">
    <UIBox>
      <template v-slot:title>
        <template >
                  <button 
                    class="btn play-video-button custom-pl-2"
                    @click="openVideo('mKx4b6MY39A')"
                  >
                  <i class="fa fa-play"></i>
                  </button>
            <YouTubePopup 
              :videoId="youtubeVideoId" 
              :show.sync="showYouTubePopup" 
              @update:show="showYouTubePopup = $event" 
            />
                    </template>

        <span class="bold">Search</span>
      </template>
      <template>
        <div>
          <div class="search-bar-with-button">
            <vue-typeahead-bootstrap
              class="search"
              inputClass="search-icon"
              :autoClose="false"
              v-model="temp"
              :data="searchResults ? searchResults : []"
              placeholder="Search by: Phone, Company Name, Owner's Name"
            />
            <b-button @click="handleSearch" class="search-button">
              Search
            </b-button>
            <span class="italic">Searching Through 
            <span class="bold">{{ searchableCount }}</span>
             of Your Leads</span>
          </div>
        </div>
        <div class="table-fullwidth table-icons">
          <b-table
            :items="searchResults"
            :fields="searchFields"
            :key-field="'id'"
            :per-page="rowsPerPage"
            :current-page="currentPage"
            aria-controls="search-controls"
            :busy="loading"
        >
            <template #table-busy>
                <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                </div>
            </template>

            <!-- Header Tooltip Template -->
            <template #head()="data">
                <div>
                    {{ data.label }}
                    <a v-b-popover.hover.top="data.field.tooltip" class="info-hover" v-if="data.field.tooltip">
                      <i class="fa-solid fa-circle-info"></i>
                    </a>
                </div>
            </template>

            <!-- Phone Cell with Tooltip -->
            <template #cell(phone)="row">
                <div class="search-number">
                    <div class="number-icon">
                        <b-button
                            @click="callProspect(row.item.salesRepId, row.item.leadId, row.item.unformattedPhone)"
                            class="icon"
                            v-b-popover.hover.top="'Click to call prospect'"
                        >
                            <PhoneSVG />
                        </b-button>
                    </div>
                    <div class="number">{{ row.item.phone }}</div>
                </div>
            </template>

            <!-- URL Cell with Tooltip -->
            <template #cell(url)="row">
                <div>
                    <div v-if="row.item.url && row.item.url !== '‐'">
                        <b-button
                            @click="openUrl(row.item.url)"
                            class="icon"
                            v-b-popover.hover.top="'Open external link'"
                        >
                            <ExtlinkSVG />
                        </b-button>
                    </div>
                    <div v-else>
                        -
                    </div>
                </div>
            </template>

            <!-- Tags Cell with Tooltip -->
            <template #cell(tags)="row">
                <div class="tags-container">
                    <span
                        v-for="(tag, index) in row.item.showAllTags ? row.item.tags : row.item.tags.slice(0, 2)"
                        :key="index"
                        class="tag-item"
                        contenteditable="true"
                        @blur="updateTag(row.item.id, index, $event.target.textContent)"
                        v-b-popover.hover.top="'Edit tag'"
                    >
                        {{ tag }}
                    </span>
                    <span
                        v-if="row.item.tags.length > 2"
                        class="tag-more"
                        @click="toggleShowAllTags(row.item)"
                        v-b-popover.hover.top="row.item.showAllTags ? 'Show fewer tags' : 'Show all tags'"
                    >
                        {{ row.item.showAllTags ? '-' : `+${row.item.tags.length - 2}` }}
                    </span>
                </div>
            </template>

            <template #cell(shortcuts)="row">
              <div class="flex items-center gap-2" style="display: flex !important; align-items: center; gap: 0.5rem">
                  <!-- <div style="cursor: pointer" @click="starUnstar($event, row)">
                    <starSVG v-if="row.starred" :size="24" :fill="roleColor()" />
                    <starOutlineSVG v-if="!row.starred" :size="24" :fill="roleColor()" />
                  </div>
                  <button class="btn outline" @click="openMessage($event, row, false)">
                    <emailSVG :size="24" :fill="roleColor()" />
                  </button>
                  <button class="btn outline" @click="makeDirectCall($event, row)">
                    <phoneSVG :size="16" :fill="roleColor()" />
                  </button>
                  <button type v-b-modal.callbackModal class="btn outline" @click="setCallBack($event, row)">
                    <callincomingSVG :size="16" :fill="roleColor()" />
                  </button>
                  <button class="btn outline" @click="sendDirectMessage($event, row)">
                    <chatSVG :size="16" :fill="roleColor()" />
                  </button>
                  <button class="btn outline" @click="setTags($event, row)">
                    <tagsSVG :size="16" :fill="roleColor()" />
                  </button> -->
                  <button class="btn outline" @click="callProspect(userId, row.item.leadId, row.item.nonPrettyPhone)">
                    <launchindialerSVG :size="24" :fill="roleColor()" />
                  </button>
                </div>
            </template>
        </b-table>
        </div>

        <div class="bottom-input">
          <div class="per-page">
            <div class="select">
              Per Page
              <select
                class="per-page-drop"
                v-model="rowsPerPage"
                @change="perpageChange"
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
              </select>
            </div>
          </div>
          <div class="pagin">
            <b-pagination
              v-model="currentPage"
              :total-rows="rowSearch"
              :per-page="rowsPerPage"
              aria-controls="search-controls"
            >
              <template #first-text>
                <PaginleftdoubleSVG />
              </template>
              <template #prev-text>
                <PaginleftSVG />
              </template>
              <template #next-text>
                <PaginrightSVG />
              </template>
              <template #last-text>
                <PaginrightdoubleSVG />
              </template>
            </b-pagination>
          </div>
          <div class="current-page">Current Page: {{ currentPage }}</div>
        </div>
      </template>
    </UIBox>
  </div>
</template>



<script setup>
import { ref, computed } from 'vue'
import axios from 'axios'
import debounce from 'lodash.debounce'
import UIBox from './uibox.vue'
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'

import LaunchinDialerSVG from './SVG-icons/launchindialerSVG.vue'
import ExtlinkSVG from './SVG-icons/extlinkSVG.vue'
import PhoneSVG from './SVG-icons/phoneSVG.vue'
import PaginleftdoubleSVG from './SVG-icons/paginleftdoubleSVG.vue';
import PaginleftSVG from './SVG-icons/paginleftSVG.vue';
import PaginrightdoubleSVG from './SVG-icons/paginrightdoubleSVG.vue';
import PaginrightSVG from './SVG-icons/paginrightSVG.vue';
import TooltipSVG from './SVG-icons/tooltipSVG.vue';
import launchindialerSVG from './SVG-icons/launchindialerSVG.vue';
import { defineEmits } from 'vue';
import AuthService from '../_services/AuthService.js';
import { onMounted } from 'vue';
import { watch } from 'vue';
import YouTubePopup from './YouTubePopup.vue';
const showYouTubePopup = ref(false);
const youtubeVideoId = ref(null);

const openVideo = (videoId) => {
  youtubeVideoId.value = videoId;
  showYouTubePopup.value = true;
};

const temp = ref('')
const searchResults = ref([])
const totalSearchCount = ref(0)
const rowsPerPage = ref(10)
const currentPage = ref(1)
const role = ref('')
const userId = ref('')
// const loading = ref(false);
const isTagModalVisible = ref(false);
const selectedTags = ref([]);
const selectedItemId = ref(null);
const searchableCount = ref(0);

const loading = ref(false);

const rowSearch = computed(() => searchResults.value.length)

async function initUser() {
  role.value = await AuthService.getUserRole();
  console.log('role: ', role.value);
  userId.value = await AuthService.getCognitoSub();
}

const searchFields = [
  { key: 'launchInDialer', label: '', variant: 'bold' },
  { key: 'ownerName', label: 'Name', tooltip: 'The first name of the owner of the business.' },
  { key: 'company', label: 'Company', tooltip: 'The name of the business.' },
  { key: 'phone', label: 'Phone Number', tooltip: 'The phone number of the business owner.' },
  { key: 'talkTime', label: 'Total Talk Time', tooltip: 'The total amount of time the prospect has been engaged on the phone.' },
  { key: 'email', label: 'Email', tooltip: 'The email of the business owner.' },
  { key: 'url', label: 'URL', tooltip: 'The website for the business.' },
  { key: 'tags', label: 'Tags', tooltip: 'Relevant points regarding the prospects sales pipeline.' },
  { key: 'shortcuts', label: 'Shortcuts', tooltip: 'Use these to take immediate actions on your prospects.' },
];

const toggleShowAllTags = (item) => {
  item.showAllTags = !item.showAllTags; // Toggle between showing all tags and only the first 2
};

const formatPhoneNumber = (phoneNumber) => {
  phoneNumber = phoneNumber.toString();
  if (phoneNumber.length > 10) {
  phoneNumber = phoneNumber.substring(phoneNumber.length - 10, phoneNumber.length);
  }
  const areaCode = phoneNumber.slice(0, 3);
  const centralOfficeCode = phoneNumber.slice(3, 6);
  const stationCode = phoneNumber.slice(6, 10);
  const formattedPhoneNumber = `(${areaCode}) ${centralOfficeCode}-${stationCode}`;
  return formattedPhoneNumber;
}

const emit = defineEmits(['open-call-prospect']);

const callProspect = (salesRepId, leadId, phone) => {
  emit('open-call-prospect', { salesRepId, leadId, phone, makeCall: false });
};

const roleColor = () => {
      const colors = {
        admins: '#A73A18',
        managers: '#2653C7',
        operators: '#46A57C',
        roaming_closer: '#0B7D8A',
      };
      return colors[role.value.toLowerCase()] || '#46A57C';
    };

const formatTime = (seconds) => {
  if (seconds <= 0) {
    return '-';
  }

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;

  let formattedTime = '';

  if (hours > 0) {
    formattedTime += `${hours}h `;
  }
  if (minutes > 0 || hours > 0) {
    formattedTime += `${minutes}m `;
  }
  formattedTime += `${secs}s`;

  return formattedTime.trim();
}

const fetchSuggestions = debounce(async () => {
  // loading.value = true;
  try {
    searchResults.value = [];
  if (role.value === "Admins" && (!temp.value || temp.value.length <= 2)) {
    // Admins must provide a query longer than 2 characters
    console.error("Admins must provide a query with more than 2 characters.");
    totalSearchCount.value = 0;
    searchResults.value = [];
  } else {
      let _role = role?.value == null || role?.value == '' ? 'Operators' : role.value;
      loading.value = true;
      const response = await axios.get(`https://123avatars.com/v2/search`, {
        params: {
          query: temp.value,
          role: _role, 
          id: userId.value, 
          rowsPerPage: rowsPerPage.value, 
          currentPage: currentPage.value, 
        }
      });
      const newSearch = response.data.results.map((res) => ({
        id: res.id || '‐',
        leadId: res.lead_id,
        salesRepId: res.uuid,
        ownerName: res.Owners_Name || '‐',
        company: res.company_name  || '‐',
        nonPrettyPhone: res.phone,
        phone: formatPhoneNumber(res.phone),
        unformattedPhone: res.phone,
        talkTime: formatTime(res.total_talk_time),
        email: res.email  || '‐', 
        url: res.Website || null,
        tags: res.tags ? JSON.parse(res.tags) : [],
        showAllTags: false, 
      }));
      searchResults.value = [...newSearch]
      totalSearchCount.value = response.data.total
      loading.value = false;
    } 
  } catch (error) {
    console.error('Error fetching search results:', error);
  } finally {
    // loading.value = false;
  }

}, 300);

const fetchCount = debounce(async () => {
  // loading.value = true;
  try {
      let _role = role?.value == null || role?.value == '' ? 'Operators' : role.value;
      console.log('searchRole: ', role.value);
      console.log('queryRole: ', _role);
      const response = await axios.get(`https://123avatars.com/v2/search/count`, {
        params: {
          role: _role, 
          id: userId.value, 
        }
      });

      searchableCount.value = response.data.total
  } catch (error) {
    console.error('Error fetching search results:', error);
  } finally {
    // loading.value = false;
  }

}, 300);

const updateTag = (itemId, tagIndex, newTag) => {
  const item = searchResults.value.find((res) => res.id === itemId);
  if (item && item.tags) {
    // Update the tag locally
    item.tags[tagIndex] = newTag;

    // Save the updated tags to the backend as JSON
    axios
      .put(`https://123avatars.com/v2/sales-rep-leads/${itemId}/tags`, {
        tags: JSON.stringify(item.tags), // Save tags as a JSON string
      })
      .then(() => {
        console.log(`Tags for item ${itemId} updated successfully.`);
      })
      .catch((error) => {
        console.error(`Error updating tags for item ${itemId}:`, error);
      });
  }
};


const handleSearch = () => {
  fetchSuggestions();
};

const openUrl = (website) => {
  if (website) {
    window.open(website, '_blank');
  }
};

function perpageChange() {
  fetchSuggestions()
}

onMounted(async () => {
  await initUser();
  await fetchCount();
});

</script>

