<template>
  <div class="bs-container">
    <div class="breaks-schedule-container">
      <div class="breaks-schedule" :class="{ shake: isShaking }">
        <div class="d-flex align-items-center">
          <div class="status-icon"><i :class="currentStatus.icon"></i></div>
          <div class="status-text">
            <h6 v-if="isNotified">{{ isNotified.title }}</h6>
            <h6 v-else>{{ currentStatus.title }}</h6>
            <span v-if="isNotified">{{ isNotified.text }}</span>
            <span v-else-if="!isCountingDown">{{ currentStatus.time }}</span>
            <span v-else>{{ countdown }}</span>
          </div>
        </div>
        <a id="popover-target" class="info-hover">
          <TooltipSVG />
        </a>
        <b-popover target="popover-target" triggers="hover focus" placement="bottom" custom-class="custom-popover">
          <div class="schedule-popup">
            <h5 class="text-center text-primary font-weight-bold mb-2">📅 Operator Daily Schedule</h5>
            <p class="text-center text-muted">8:00 AM PST - 5:00 PM {{ userTimezone }}</p>

            <b-table
              :items="schedule"
              :fields="scheduleFields"
              small
              bordered
              responsive
              class="schedule-table"
            >
              <template #cell(status)="data">
                <b-badge :variant="data.item.badge">{{ data.item.status }}</b-badge>
              </template>
            </b-table>
          </div>
        </b-popover>
        <!-- <div class="confirm-btn" @click="stopNotification" v-if="isNotified"><checkSVG size="13" fill="#fff"></checkSVG></div> -->
      </div>
    </div>
    <audio id="breaks-notification"></audio>
    <!-- <div class="dark-overlay" :class="{ show: isNotified }"></div> -->
  </div>
</template>

<script>
import moment from 'moment-timezone';
import CheckSVG from './SVG-icons/checkSVG.vue';
import TooltipSVG from './SVG-icons/tooltipSVG.vue';

export default {
  components: {
    CheckSVG, TooltipSVG
  },
  props: {
    userTimezone: { type: String, required: true },
    statusSchedule: { type: Array, required: true },
  },
  data() {
    return {
      currentStatus: { title: '', icon: '', time: '' },
      countdown: null,
      isCountingDown: false,
      isShaking: false,
      isNotified: false,
      updateTimeout: null,
      countdownInterval: null,
      scheduleFields: [
        { key: "status", label: "Status" },
        { key: "time", label: "Time" }
      ],
      schedule: [
        { status: "Closing Sales", time: "8:00 AM - 10:00 AM", badge: "success" },
        { status: "1st Break", time: "10:00 AM - 10:15 AM", badge: "warning" },
        { status: "Closing Sales", time: "10:15 AM - 12:00 PM", badge: "success" },
        { status: "Lunch Break", time: "12:00 PM - 1:00 PM", badge: "danger" },
        { status: "Closing Sales", time: "1:00 PM - 3:00 PM", badge: "success" },
        { status: "2nd Break", time: "3:00 PM - 3:15 PM", badge: "warning" },
        { status: "Closing Sales", time: "3:15 PM - 5:00 PM", badge: "success" }
      ]
    };
  },
  watch: {
    userTimezone: "updateStatus"
  },
  methods: {
    getTimezone() {
      const timezones = {
        EST: "America/New_York", EDT: "America/New_York",
        CST: "America/Chicago", CDT: "America/Chicago",
        MST: "America/Denver", MDT: "America/Denver",
        PST: "America/Los_Angeles", PDT: "America/Los_Angeles",
        AKST: "America/Anchorage", AKDT: "America/Anchorage",
        HST: "Pacific/Honolulu", HDT: "Pacific/Honolulu"
      };
      return timezones[this.userTimezone] || "America/Los_Angeles";
    },
    formatTime(time) {
      return moment.tz(time, "HH:mm", this.getTimezone()).format("h:mm A");
    },
    stopNotification() {
      this.isNotified = false;
      const audioElement = document.getElementById('breaks-notification');
      audioElement.pause();
      audioElement.currentTime = 0;
    },
    async playNotification() {
      try {
        const audio = document.getElementById('breaks-notification');
        audio.src = 'https://123avatars.com/uploads/breaks_bell_notification.mp3';
        audio.currentTime = 0;
        await audio.play();

        setTimeout(() => (this.isNotified = false), 5000);
      } catch (error) {
        
      }
    },
    updateStatus() {
      clearTimeout(this.updateTimeout); // Prevent multiple executions

      const timezone = this.getTimezone();
      const currentMinute = moment().tz(timezone).format("HH:mm:ss");
      const now = moment.tz(currentMinute, "HH:mm:ss", timezone);

      // **Handle last status to show "Day Ends in" countdown**
      const lastStatus = this.statusSchedule[this.statusSchedule.length - 1];
      const lastEndTime = moment.tz(lastStatus.end, "HH:mm:ss", timezone);
      const timeToEnd = lastEndTime.diff(now, "seconds");

      const firstStatus = this.statusSchedule[0];
      const firstStartTime = moment.tz(firstStatus.start, "HH:mm:ss", timezone);

      const midnight = moment.tz("00:00:00", "HH:mm:ss", timezone);

      if (moment().tz(timezone).isAfter(midnight) && moment().tz(timezone).isBefore(firstStartTime)) {
        this.isNotified = false;
        this.currentStatus = {
          title: firstStatus.title,
          icon: firstStatus.icon,
          time: `${this.formatTime(firstStatus.start)} - ${this.formatTime(firstStatus.end)}`
        };
      } else if (timeToEnd < 1) {
        if (timeToEnd === 0) {
          this.isNotified = {
            title: `Great Job Today`,
            text: `We will See you Tomorrow`
          };

          this.playNotification();

          this.isShaking = true;
          setTimeout(() => (this.isShaking = false), 400);
        }

        this.currentStatus = { title: "Great Job Today", time: "We will See you Tomorrow", icon: "fa-solid fa-smile" };
        this.isCountingDown = false;
        this.countdown = null;
      } else {
        for (let i = 0; i < this.statusSchedule.length; i++) {
          const status = this.statusSchedule[i];
          const nextStatus = this.statusSchedule[i + 1];

          // **Trigger countdown if the next status has notify: true**
          if (nextStatus && nextStatus.notify) {
            const nextStart = moment.tz(nextStatus.start, "HH:mm:ss", timezone);
            const timeDiff = nextStart.diff(now, "seconds");

            if (timeDiff <= 900 && timeDiff > 0 && !this.isCountingDown) {
              this.startCountdown(timeDiff, `${nextStatus.title} Starts in`);
            }

            if (timeDiff === 0) {
              this.isNotified = {
                title: `${nextStatus.title} Started`,
                text: `${this.formatTime(nextStatus.start)} - ${this.formatTime(nextStatus.end)}`
              };

              this.playNotification();

              this.isShaking = true;
              setTimeout(() => (this.isShaking = false), 400);
            }
          }

          // **Trigger countdown before the current status ends if it has notify: true**
          if (status.notify) {
            const breakEnd = moment.tz(status.end, "HH:mm:ss", timezone);
            const timeDiff = breakEnd.diff(now, "seconds");

            if (timeDiff <= 900 && timeDiff > 0 && !this.isCountingDown) {
              this.startCountdown(timeDiff, `${status.title} Ending in`);
            }

            if (timeDiff === 0) {
              this.isNotified = {
                title: `${status.title} Finished`,
                text: `Let's Go Get Some Sales`
              };

              this.playNotification();

              this.isShaking = true;
              setTimeout(() => (this.isShaking = false), 400);
            }
          }

          // Update the current status
          if (currentMinute > status.start && currentMinute < status.end) {
            this.currentStatus = {
              title: status.title,
              icon: status.icon,
              time: `${this.formatTime(status.start)} - ${this.formatTime(status.end)}`
            };
          }
        }
      }

      if (timeToEnd <= 900 && timeToEnd > 0 && !this.isCountingDown) {
        this.startCountdown(timeToEnd, "Day Ends in");
      }

      this.updateTimeout = setTimeout(this.updateStatus, timeToEnd < 1 ? 5000 : 10);
    },
    startCountdown(seconds, messagePrefix) {
      this.isCountingDown = true;

      if (this.countdownInterval) cancelAnimationFrame(this.countdownInterval);

      const startTime = performance.now();
      const endTime = startTime + seconds * 1000;

      const updateCountdown = () => {
        const remainingTime = Math.max(0, Math.round((endTime - performance.now()) / 1000));

        if (remainingTime <= 0) {
          this.countdown = null;
          this.isCountingDown = false;
          cancelAnimationFrame(this.countdownInterval);
          return;
        }

        this.countdown = `${messagePrefix} ${this.formatCountdown(remainingTime)}`;
        this.countdownInterval = requestAnimationFrame(updateCountdown);
      };

      updateCountdown();
    },
    formatCountdown(seconds) {
      const minutes = Math.floor(seconds / 60);
      const sec = seconds % 60;
      return `${minutes}:${sec.toString().padStart(2, '0')}`;
    }
  },
  mounted() {
    this.updateStatus();
  }
};
</script>

<style lang="scss" scoped>
$interactive: var(--interactive);

.bs-container {
  margin-right: 10px;

  .breaks-schedule-container {
    z-index: 11;
    position: absolute;
    top: 50%;
    right: 100%;
    transform: translateY(-50%);

    .breaks-schedule {
      position: relative;
      gap: 15px;
      white-space: nowrap;
      padding: 10px;
      padding-right: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: var(--ui-main);
      box-shadow: 0px 0px 10px rgba($color: #ffffff, $alpha: 0.35);
      border-radius: 35px;

      .status-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.15);
      }

      .status-text {
        margin-left: 7.5px;
        display: flex;
        flex-direction: column;

        h6 {
          margin: 0;
        }
      }

      &.shake {
        animation: shake 0.4s 1;
        // animation: shakeWithPause 1.5s infinite;
      }

      .confirm-btn {
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(50%, -50%);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 22.5px;
        height: 22.5px;
        border-radius: 50%;
        border: 1px solid white;
        transition: background 0.35s;
      }

      .confirm-btn:hover {
        background-color: white;

        svg {
          fill: $interactive;
        }
      }
    }
  }

  .dark-overlay {
    visibility: hidden;
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0);
    backdrop-filter: blur(3px);
    transition: visibility 0.15s, background 0.15s;

    &.show {
      visibility: visible;
      background-color: rgba($color: #000000, $alpha: 0.5);
    }
  }

  .info-hover {
    svg {
      height: 12px;

      ::v-deep(circle) {
        fill: transparent;
        stroke: white;
      }

      ::v-deep(path) {
        fill: white;
      }
    }
  }
}

.custom-popover {
  z-index: 9999;
  max-width: 320px;
  border-radius: 10px;
  padding: 10px;
}

.schedule-table {
  font-size: 14px;
  background: #f8f9fa;
  border-radius: 8px;
}

.schedule-popup h5 {
  font-size: 16px;
  font-weight: bold;
}

.schedule-popup .badge {
  font-size: 12px;
  padding: 5px 10px;
}

@keyframes shake {
  0% { transform: translate(0.25px, 0.25px) rotate(0deg); }
  10% { transform: translate(-0.25px, -0.5px) rotate(-1deg); }
  20% { transform: translate(-1px, 0px) rotate(1deg); }
  30% { transform: translate(0.25px, 0.5px) rotate(0deg); }
  40% { transform: translate(0.25px, -0.25px) rotate(1deg); }
  50% { transform: translate(-0.25px, 0.5px) rotate(-1deg); }
  60% { transform: translate(-1px, 0.25px) rotate(0deg); }
  70% { transform: translate(1px, 0.25px) rotate(-1deg); }
  80% { transform: translate(-0.25px, -0.25px) rotate(1deg); }
  90% { transform: translate(0.25px, 0.5px) rotate(0deg); }
  100% { transform: translate(0.25px, -0.5px) rotate(-1deg); }
}

// @keyframes shakeWithPause {
//   0% { transform: translate(0.25px, 0.25px) rotate(0deg); }
//   10% { transform: translate(-0.25px, -0.5px) rotate(-1deg); }
//   20% { transform: translate(-1px, 0px) rotate(1deg); }
//   30% { transform: translate(0.25px, 0.5px) rotate(0deg); }
//   40% { transform: translate(0.25px, -0.25px) rotate(1deg); }
//   50% { transform: translate(-0.25px, 0.5px) rotate(-1deg); }
//   60% { transform: translate(-1px, 0.25px) rotate(0deg); }
//   70% { transform: translate(1px, 0.25px) rotate(-1deg); }
//   75% { transform: translate(-0.25px, -0.25px) rotate(1deg); }

//   /* Pause section (object stays still) */
//   80%, 100% { transform: translate(0, 0) rotate(0deg); }
// }
</style>