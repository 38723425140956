<!-- ButtonUI.vue -->
<template>
  <button :class="classes" @click="$emit('click')">
	<slot />
  </button>
</template>

<script>
export default {
  props: {
	big: {
	  type: Boolean,
	  default: false
	},
	red: {
	  type: Boolean,
	  default: false
	},
	outline: {
	  type: Boolean,
	  default: false
	},
	minimal: {
	  type: Boolean,
	  default: false
	},
	fullwidth: {
	  type: Boolean,
	  default: false
	},
	icononly: {
	  type: Boolean,
	  default: false
	},
	disabled: {
	  type: Boolean,
	  default: false
	},
	muted: {
		type: Boolean,
		default: false
	}
  },
  computed: {
	classes() {
	  return [
		'btn',
		this.big ? 'big' : '',
		this.red ? 'red' : '',
		this.outline ? 'outline' : '',
		this.minimal ? 'minimal' : '',
		this.fullwidth ? 'fullwidth' : '',
		this.icononly ? 'icon-only' : '',
		this.disabled ? 'disabled' : '',
		this.muted ? 'muted' : ''
	  ].filter(Boolean);
	}
  }
}
</script>