<!-- BoxUI.vue -->
<template>	
	<div class="box" :class="classbox">
		<div class="header" :class="classheader">
			<div v-if="slots.accordian" v-b-toggle="'collapse-' + id" class="title" :class="classTitle">
				<AccordiancaretSVG /> <slot name="title" :classTitle="classTitle"></slot>
			</div>
			<div v-else class="title" :class="classTitle">
				<slot name="title" :classTitle="classTitle"></slot>
			</div>
			<slot name="button" :onClick="onClick"></slot>
		</div>

		<div 
			v-if="$slots.default || $slots.leftcol || $slots.rightcol" 
			:class="contentClasses">

			<!-- default content -->
			<slot></slot>
			<!-- two column content -->
			<div v-if="slots.leftcol" class="half-left">
				<slot name="leftcol"></slot>
			</div>
			<div v-if="slots.rightcol" class="half-right">
				<slot name="rightcol"></slot>
			</div>
		</div>
		<div v-if="slots.accordian" class="accordian">
			<b-collapse :id="'collapse-' + id" :accordion="'objections'" role="tabpanel" class="copy">
			  <div class="content"><slot name="accordian"></slot></div>
			</b-collapse>
		</div>
	</div>
</template>

<script>
import AccordiancaretSVG from '../SVG-icons/accordiancaretSVG.vue';

export default {
	name: "BoxUI",
	components: {
		AccordiancaretSVG
	},
	props: {
		classbox: String,
		classheader: String,
		classcontent: String,
		title: String,
		classTitle: String,
		nopad: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			id: null
		};
	},
	computed: {
		slots() {
			return this.$slots;
		},
		contentClasses() {
			return [
				'content',
				this.classcontent, // Include custom class if provided
				this.nopad ? 'nopad' : '',
				(this.$slots.leftcol || this.$slots.rightcol) ? 'columns' : ''
			].filter(Boolean);
		}
	},
	mounted() {
		this.id = Math.random().toString(36).substr(2, 9); // Generate a unique ID
	},
	methods: {
		onClick() {
			// Handle button click event if needed
		}
	}
};
</script>
