<template>
    <b-modal :visible="show" size="xl" hide-footer @close="handleClose">
      <iframe
        width="100%"
        height="500"
        :src="videoUrl"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </b-modal>
  </template>
  
  <script>
  export default {
    props: {
      videoId: {
        type: String,
        required: true
      },
      show: {
        type: Boolean,
        required: true
      }
    },
    computed: {
      videoUrl() {
        return `https://www.youtube.com/embed/${this.videoId}`;
      }
    },
    methods: {
      handleClose() {
        this.$emit('update:show', false);
      }
    }
  };
  </script>
  
  <style >
.modal .modal-content .close {
    margin: -1.5rem -1rem -1rem auto !important;
    font-size: 2rem !important;
}
</style>