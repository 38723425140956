<template>
  <div id="tags">
    <UIBox classbox="hello">
      <template v-slot:title>
        <!-- Youtube embedded video -->
        <button class="btn play-video-button custom-pl-2" @click="showYouTubePopup = true">
          <i class="fa fa-play"></i>
        </button>
      <YouTubePopup :videoId="youtubeVideoId" :show.sync="showYouTubePopup" @update:show="showYouTubePopup = $event" />
      <!--/-->
        <span class="bold">Broadcast</span>
      </template>
      <div class="filter" v-if="role === 'Admins'">
        <div class="filterBody">
          <div class="button-container">
            <button :class="['btn', 'solid', { 'btn-menu-active': showTrackings }]"
              @click="handleTrackings">Trackings</button>
            <button :class="['btn', 'solid', { 'btn-menu-active': showNegativeKeyordFilter }]"
              @click="handleNegativeKeywordFilter">Negative Keyword Filter</button>
            <button :class="['btn', 'solid', { 'btn-menu-active': showManageDIDs }]" @click="handleManageDIDs">Manage
              DIDs</button>
            <button :class="['btn', 'solid', { 'btn-menu-active': showManagePremaidMessages }]"
              @click="handleManagePremadeMessages">Manage Premade Messages</button>
            <div>
              <span style="font-weight: bold;">Max Daily Broadcasts Per User</span>
              <div class="d-flex" style="margin-top:10px;">
                <input type="text" class="form-control" v-model="maxDaily" @input="handleInput"
                  @blur="handleBlur"
                  @focus="handleFocus"
                  @keypress.enter="handleEnter"
                />
                <button class="btn solid flex-shrink-0 ml-2"
                  @click="handleEnter">Save</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="filter">
        <div class="flex items-center gap-2" style="font-weight: bold">
          Starting Credit Balance : {{ remainingCredits }} credits
        </div>
      </div>
      <template>
        <Trackings v-if="showTrackings" />
        <NegativeKeywordFilter v-if="showNegativeKeyordFilter" />
        <ManageDIDs v-if="showManageDIDs" />
        <PreMadeMessages v-if="showManagePremaidMessages" />
        <div class="app">
          <Stepper :steps="steps" :initial-step="0" @step-change="handleStepChange" @broadcast-sent="handleBroadcastSent">
          </Stepper>
        </div>
      </template>
    </UIBox>
  </div>
</template>

<script>
import axios from 'axios';
import AuthService from '../_services/AuthService.js';

import UIBox from '../components/uibox.vue';
import Stepper from './broadcast/Stepper.vue';
import Trackings from './broadcast/Trackings.vue';
import NegativeKeywordFilter from './broadcast/NegativeKeywordFilter.vue';
import ManageDIDs from './broadcast/ManageDIDs.vue';
import PreMadeMessages from './broadcast/PreMadeMessages.vue';
import YouTubePopup from './YouTubePopup.vue' 

export default {
  components: {
    UIBox,
    Stepper,
    Trackings,
    NegativeKeywordFilter,
    ManageDIDs,
    PreMadeMessages,
    YouTubePopup
  },
  data() {
    return {
      showTrackings: false,
      showNegativeKeyordFilter: false,
      showManageDIDs: false,
      showManagePremaidMessages: false,
      steps: [
        {
          title: 'Select Leads By Industry',
          icon: '',
          description: 'Define the main objectives and goals for the design project.'
        },
        {
          title: 'Set How Many Leads To Send',
          icon: '',
          description: 'Develop initial layouts and structural designs.'
        },
        {
          title: 'Choose Premade Message',
          icon: '',
          description: 'Create the visual elements and styling of the design.'
        },
        {
          title: 'Send Broadcast',
          icon: '',
          description: '.'
        }
      ],
      role: '',
      userId: '',
      maxDaily: 0,
      remainingDaily: 0,
      remainingCredits: 0,
      targetCredits: 0,
      showYouTubePopup: false,
      youtubeVideoId: 'Q1lwOKla0cQ' 
    }
  },
  methods: {
    async fetchRemainingCredits() {
      let target = `https://123avatars.com/v2/sales-reps/${this.userId}`;
      let response = await axios.get(target);
      this.remainingCredits = response.data[0].credits;
    },
    handleBroadcastSent() {
      this.$emit('broadcast-sent');
    },
    async handleEnter() {
      try {
        this.$toasted.show("Updating max daily broadcast limit...", {
          theme: "outline",
          position: "top-right",
          type: "info",
          icon: "refresh",
          duration: 2000,
        });

        await axios.post('https://123avatars.com/v2/sms-broadcast/max-daily', {
          max: this.maxDaily,
        });

        this.$toasted.show("Max daily broadcast limit updated successfully", {
          theme: "outline",
          position: "top-right",
          type: "success",
          icon: "check",
          duration: 2000,
        });
      } catch (error) {
        console.log(error);
      }
    },
    handleInput(event) {
      // Remove any non-numeric characters
      const numericValue = event.target.value.replace(/[^0-9]/g, '');
      
      // Convert to number
      const numberValue = numericValue ? parseInt(numericValue) : 0;
      
      this.maxDaily = numberValue;
    },
    handleBlur() {
      if (this.maxDaily) {
        this.maxDaily = this.maxDaily.toLocaleString();
      } else {
        this.maxDaily = '0';
      }
    },
    handleFocus() {
      this.maxDaily = this.maxDaily.toString();
    },
    handleTrackings() {
      this.showTrackings = !this.showTrackings;
      this.showNegativeKeyordFilter = false;
      this.showManageDIDs = false;
      this.showManagePremaidMessages = false;
    },
    handleNegativeKeywordFilter() {
      this.showNegativeKeyordFilter = !this.showNegativeKeyordFilter;
      this.showTrackings = false;
      this.showManageDIDs = false;
      this.showManagePremaidMessages = false;
    },
    handleManageDIDs() {
      this.showManageDIDs = !this.showManageDIDs;
      this.showManagePremaidMessages = false;
      this.showNegativeKeyordFilter = false;
      this.showTrackings = false;
    },
    handleManagePremadeMessages() {
      this.showManagePremaidMessages = !this.showManagePremaidMessages;
      this.showManageDIDs = false;
      this.showNegativeKeyordFilter = false;
      this.showTrackings = false;
    },
    handleStepChange(stepIndex) {
      console.log('Current step:', stepIndex);
      // Add any additional logic for step changes
    },
    async initUser() {
      this.role = await AuthService.getUserRole();
      this.userId = await AuthService.getCognitoSub();
      console.log('this.role', this.role);
      console.log('this.userId', this.userId);
    },
    async getMaxDailyBroadcastsPerUser() {
      try {
        const response = await axios.get('https://123avatars.com/v2/sms-broadcast/max-daily');
        this.maxDaily = response.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async getRemainingDailyBroadcastsPerUser() {
      try {
        const remainingDailyResult = await axios.get('https://123avatars.com/v2/sms-broadcast/remaining-daily', {
          params: {
            sub: this.userId
          }
        });

        this.remainingDaily = this.maxDaily - remainingDailyResult.data.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
  async mounted() {
    await this.initUser();
    await this.getMaxDailyBroadcastsPerUser();
    await this.getRemainingDailyBroadcastsPerUser();
    await this.fetchRemainingCredits();
  }
}
</script>
<style scoped>
.button-container {
  display: flex;
  justify-content: space-between;
  /* or use space-evenly for equal space between and on edges */
  width: 100%;
  gap: 10px;
  /* optional: adds consistent spacing between buttons */
}

.app {
  padding: 20px;
  width: 100% !important;
}

.custom-content {
  margin-top: 20px;
  padding: 15px;
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #e5e7eb;
}

.btn-menu-active {
  background-color: white !important;
  /* Example active color - adjust as needed */
  color: #000 !important;
}
</style>