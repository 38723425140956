<script>
export default {
  props: {
    userId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      lastClick: false,
    };
  },
  created() {
    document.addEventListener("click", this.trackClick);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.trackClick);
  },
  methods: {
    async trackClick() {
      if (!this.userId || this.lastClick) return;

      this.lastClick = true;
      try {
        await fetch("https://123avatars.com/v2/sales-rep-refresh", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ userId: this.userId }),
        });
      } catch (error) {} finally { setTimeout(() => { this.lastClick = false; }, 1000); }
    },
  },
};
</script>