<template>
  <div id="tags">
    <UIBox classbox="hello">
      <template v-slot:title>
        <!-- Youtube embedded video -->
        <button class="btn play-video-button custom-pl-2" @click="showYouTubePopup = true">
            <i class="fa fa-play"></i>
          </button>
        <YouTubePopup :videoId="youtubeVideoId" :show.sync="showYouTubePopup" @update:show="showYouTubePopup = $event" />
        <!--/-->
        <span class="bold">Tags</span>
      </template>
      <template>
        <div class="filter">
          <div class="filterHead">Filter by</div>
          <div class="filterBody">
            <div class="dropdowns-container">
              <!-- Dropdowns -->
              <div v-for="(dropdown, index) in dropdowns" :key="index">
                <button @click="toggleDropdown(index)" class="btn solid dropdown-menu-style">
                  <template v-if="dropdown.selected">
                    <span class="tag-text">{{ dropdown.selected.tag }}</span>
                    <span class="tag-count">{{ dropdown.selected.count }}</span>
                  </template>
                  <template v-else>
                    <span class="tag-text">Leads</span>
                    <span class="tag-count">{{ totalRows.toLocaleString() }}</span>
                  </template>
                </button>
                <div class="dropdown-wrapper">
                  <ul class="dropdown-menu-style" v-show="dropdown.isOpen">
                    <li v-for="tag in tagsFilter" :key="tag.tag" :value="tag.tag"
                      :class="`tag-item${role ? role.toLowerCase() : ''}`" @click="selectTag(tag, index)">
                      <span class="tag-text">{{ tag.tag }}</span>
                      <span class="tag-count">{{ tag.count }}</span>
                    </li>
                  </ul>
                </div>
              </div>

              <!-- Single close button -->
              <div v-if="dropdowns.length > 1 || dropdowns[0].selected != null">
                <button @click="removeLastDropdown" class="btn outline icon remove-selection">
                  <CloseSVG color="red" />
                </button>
              </div>
            </div>

            <!-- Search section -->
            <div class="search-section">
              <input type="text" v-model="searchQuery" placeholder="Search by Company Name, Phone Number..."
                class="search-input" />
              <button @click="handleSearch" class="btn solid search-button">
                Search
              </button>
            </div>
          </div>
        </div>

        <div class="container-body" id="reportContainer">

          <div class="container-body" id="reportContainer">
            <div class="table-fullwidth">
              <table class="table call-backs-table" aria-controls="tagstable">
                <thead>
                  <tr>
                    <th style="width: 15%;">Name</th>
                    <th style="width: 15%;">Owner Name</th>
                    <th style="width: 20%;">Company</th>
                    <th style="width: 20%;">Phone Number</th>
                    <th style="width: 10%;">Talk Time</th>
                    <th style="width: 10%;">Email</th>
                    <th style="width: 15%;">Tags</th>
                    <th style="width: 5%;"></th>
                    <th style="width: 5%;"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="row in tags" :data-sales-rep-id="row.id">
                    <td>{{ row.sales_rep }}</td>
                    <td>{{ row.owner_name }}</td>
                    <td>{{ row.company_name }}</td>
                    <td>
                      <div style="display: flex; align-items: center;">
                        <button class="btn outline icon" @click="callProspect(row.user_id, row.id, row.phone)">
                          <PhoneSVG />
                        </button>{{ formatPhoneNumber(row.phone) }}
                      </div>
                    </td>
                    <td>{{ formatSeconds(row.total_talk_time) }}</td>
                    <td>{{ row.email }}</td>
                    <td>
                      <div class="tags-container">
                        <!-- Existing tags -->
                        <div class="tags-list">
                          <span v-for="(tag, index) in parseTags(row.tags)" :key="index" :class="{
                            badge: true,
                            'badge-primary': true,
                            [`tag-badge${role ? role.toLowerCase() : ''}`]: true
                          }">
                            {{ tag }}
                            <button v-if="editingRowId === row.id" class="badge-close-btn"
                              @click="removeTag(row.id, tag)" aria-label="Remove tag">
                              ×
                            </button>
                          </span>
                        </div>

                        <!-- Tag input field - separate from badges -->
                        <div v-if="editingRowId === row.id" class="tag-input-wrapper">
                          <input v-model="newTag" @keyup.enter="addTag(row.id)" type="text" class="tag-input"
                            placeholder="Add tag..." />
                        </div>
                      </div>
                    </td>
                    <td>
                      <button class="btn outline icon"
                        @click.stop="editingRowId === row.id ? handleSave(row.id) : toggleEditRow(row, row.id)">
                        <template v-if="editingRowId === row.id">
                          <SaveSVG />
                        </template>
                        <template v-else>
                          <EditSVG />
                        </template>
                      </button>
                    </td>
                    <td>
                      <b-button type v-b-modal.callbackModal class="btn solid" @click="openEditCallback(row)" style="text-wrap: nowrap">
                        Set Callback
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </template>
      <!-- Bottom Input -->
      <div class="bottom-input">

        <div class="per-page">
          <div class="select">
            Per Page
            <select class="per-page-drop" id="rowsPerPage" v-model="rowsPerPage" @change="fetchTags">
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="500">500</option>
            </select>
          </div>
        </div>

        <template>
          <div class="pagin">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="rowsPerPage"
              aria-controls="tagstable" @change="handleTagsClosed">
              <template #first-text>
                <PaginleftdoubleSVG />
              </template>
              <template #prev-text>
                <PaginleftSVG />
              </template>
              <template #next-text>
                <PaginrightSVG />
              </template>
              <template #last-text>
                <PaginrightdoubleSVG />
              </template>
            </b-pagination>
          </div>
        </template>

      </div>
    </UIBox>
    <Callbacks :type="'NEW'" :prospect="company" :userId="userId" v-if="company" @callbacks-closed="handleCallbacksClosed" />
  </div>
</template>

<script>
import Axios from 'axios';
import { DateTime } from 'luxon';
import UIBox from '../components/uibox.vue';
import PaginleftdoubleSVG from '../components/SVG-icons/paginleftdoubleSVG.vue';
import PaginleftSVG from '../components/SVG-icons/paginleftSVG.vue';
import PaginrightdoubleSVG from '../components/SVG-icons/paginrightdoubleSVG.vue';
import PaginrightSVG from '../components/SVG-icons/paginrightSVG.vue';
import CloseSVG from '../components/SVG-icons/closeSVG.vue';
import CheckSVG from '../components/SVG-icons/checkSVG.vue';
import AuthService from '../_services/AuthService.js';
import PhoneSVG from '../components/SVG-icons/phoneSVG.vue';

import Callbacks from "../pages/sales/Callbacks.vue";

import axios from 'axios';
import EditSVG from './SVG-icons/editSVG.vue';
import SaveSVG from '../components/SVG-icons/saveSVG.vue';
import YouTubePopup from './YouTubePopup.vue'

export default {

  components: {
    UIBox,
    PaginleftdoubleSVG,
    PaginleftSVG,
    PaginrightdoubleSVG,
    PaginrightSVG,
    CloseSVG,
    CheckSVG,
    PhoneSVG,
    EditSVG,
    SaveSVG,
    YouTubePopup,
    'Callbacks': Callbacks,
  },
  
  props: {
    userTimezone: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      role: '',
      userId: '',
      salesReps: ['Rep A', 'Rep B', 'Rep C', 'Rep D', 'Rep E'],
      selectedRep: 'all',
      callBackData: [],
      rowsPerPage: 50,
      currentPage: 1,
      totalRows: 0,
      tags: [],
      tagsFilter: [],
      dropdowns: [
        {
          isOpen: false,
          selected: null
        }
      ],
      tagsFilter: [],
      searchQuery: '',
      selectedTagsList: [],
      editingRowId: null,
      newTag: '',
      showModal: false,
      company: null,
      showYouTubePopup: false,
      youtubeVideoId: 'H7evAdysI8s' 
    };
  },
  computed: {
    selectedTags() {
      return this.dropdowns
        .filter(dropdown => dropdown.selected)
        .map(dropdown => dropdown.selected);
    },
  },
  methods: {
    
    mapCallbackData(input) {
      const formattedDate = this.getFormattedDate(this.getTimezoneFromAbbreviation(input.timezone));

      return {
        id: input.id,
        owner: input.owner_name || '-', // Static value as per output example
        company_name: input.company_name || '-', // Default to 'TEST' if company is null
        timezone: input.timezone, // Static value as per output example
        localTime: input.timeUser || '123', // Default to '123' if timeUser is null
        userTimezone: this.userTimezone,
        leadId: input.leadId ? input.leadId.toString() : '123', // Convert leadId to string, default to '123' if null
        callbackTime: formattedDate
      };
    },
    getFormattedDate(timezone) {
      const date = new Date();
      const options = { 
        timeZone: timezone, 
        year: 'numeric', 
        month: '2-digit', 
        day: '2-digit', 
        hour: '2-digit', 
        minute: '2-digit', 
        second: '2-digit', 
        hour12: false 
      };

      const formatter = new Intl.DateTimeFormat('en-US', options);
      const parts = formatter.formatToParts(date);

      const formattedDate = `${parts.find(p => p.type === 'year').value}-${parts.find(p => p.type === 'month').value}-${parts.find(p => p.type === 'day').value}T${parts.find(p => p.type === 'hour').value}:${parts.find(p => p.type === 'minute').value}:${parts.find(p => p.type === 'second').value}`;

      const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
      const targetDate = new Date(date.toLocaleString('en-US', { timeZone: timezone }));
      const offset = (targetDate - utcDate) / 60000;

      const offsetHours = String(Math.floor(Math.abs(offset) / 60)).padStart(2, '0');
      const offsetMinutes = String(Math.abs(offset) % 60).padStart(2, '0');
      const offsetSign = offset >= 0 ? '+' : '-';

      const offsetString = offset === 0 ? 'Z' : `${offsetSign}${offsetHours}:${offsetMinutes}`;

      return `${formattedDate}${offsetString}`;
    },
    getTimezoneFromAbbreviation(abbreviation) {
      const timezones = {
        'EST': 'America/New_York', // Eastern Standard Time
        'EDT': 'America/New_York', // Eastern Daylight Time
        'CST': 'America/Chicago', // Central Standard Time
        'CDT': 'America/Chicago', // Central Daylight Time
        'MST': 'America/Denver', // Mountain Standard Time
        'MDT': 'America/Denver', // Mountain Daylight Time
        'PST': 'America/Los_Angeles', // Pacific Standard Time
        'PDT': 'America/Los_Angeles', // Pacific Daylight Time
        'AKST': 'America/Anchorage', // Alaska Standard Time
        'AKDT': 'America/Anchorage', // Alaska Daylight Time
        'HST': 'Pacific/Honolulu', // Hawaii Standard Time
        'HDT': 'Pacific/Honolulu' // Hawaii Daylight Time (though Hawaii does not observe DST)
      };

      return timezones[abbreviation] || 'America/Los_Angeles';
    },
    toggleEditRow(mainRow, rowId) {
      this.editingRowId = this.editingRowId === rowId ? null : rowId;
      this.newTag = ''; // Clear the input when toggling edit mode
      
      this.openEditCallback(mainRow.tags.find(row => row?.id === rowId));
    },
    openEditCallback(callback) {
      this.company = null;
      this.company = this.mapCallbackData(callback);
    },
    handleCallbacksClosed() {
      this.fetchTags();
    },
    handleSearch() {
      this.fetchTags();
    },
    toggleDropdown(index) {
      this.dropdowns[index].isOpen = !this.dropdowns[index].isOpen;
      // Close other dropdowns
      this.dropdowns.forEach((dropdown, i) => {
        if (i !== index) {
          dropdown.isOpen = false;
        }
      });
    },
    selectTag(tag, index) {
      this.dropdowns[index].selected = {
        tag: tag.tag,
        count: tag.count
      };
      this.dropdowns[index].isOpen = false;

      this.selectedTagsList = this.dropdowns
        .filter(dropdown => dropdown.selected)
        .map(dropdown => dropdown.selected);

      if (this.dropdowns.length < 3) {
        this.dropdowns.push({
          isOpen: false,
          selected: null
        });
      }
      this.fetchTags();
    },
    removeLastDropdown() {
      if (this.dropdowns.length > 1) {
        this.dropdowns.pop();
        this.selectedTagsList = this.dropdowns
          .filter(dropdown => dropdown.selected)
          .map(dropdown => dropdown.selected);
      } else {
        this.selectedTagsList = [];
        this.dropdowns[0].selected = null;
        this.dropdowns[0].isOpen = false;
      }
      this.fetchTags();
    },
    async handleSave(rowId) {
      try {
        const row = this.tags.find(row => row.id === rowId);
        if (!row) return;

        const currentTags = this.parseTags(row.tags);
        const updatedTags = [...currentTags, this.newTag.trim()];

        let target = 'https://123avatars.com/v2/update-tags';
        await axios.post(target, {
          id: rowId,
          tags: updatedTags
        });
        this.editingRowId = null;
        this.newTag = '';
      } catch (error) {
        console.error('Error saving:', error);
      }
    },
    handleTagsClosed() {
      this.fetchTags();
    },
    addTag(rowId) {
      if (!this.newTag.trim()) return; // Don't add empty tags

      const row = this.tags.find(row => row.id === rowId);
      if (row) {
        const currentTags = this.parseTags(row.tags);
        if (currentTags.includes(this.newTag.trim())) {
          return;
        }

        const updatedTags = [...currentTags, this.newTag.trim()];
        row.tags = JSON.stringify(updatedTags);
        this.newTag = '';
      }
    },
    formatSeconds(seconds) {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const secs = seconds % 60;

      const hoursDisplay = hours > 0 ? `${hours}h ` : "";
      const minutesDisplay = minutes > 0 ? `${minutes}m ` : "";
      const secondsDisplay = `${secs}s`;

      return `${hoursDisplay}${minutesDisplay}${secondsDisplay}`.trim();
    },
    async initUser() {
      this.role = await AuthService.getUserRole();
      this.userId = await AuthService.getCognitoSub();
      console.log('this.role', this.role);
      console.log('this.userId', this.userId);
    },
    callProspect(salesRepId, leadId, phone) {
      console.log('emit', JSON.stringify({
        salesRepId: salesRepId,
        leadId: leadId,
        phone: phone
      }));
      this.$emit('open-call-prospect', {
        salesRepId: salesRepId,
        leadId: leadId,
        phone: phone,
        makeCall: true
      });
    },
    formatPhoneNumber(phoneNumber) {
      phoneNumber = phoneNumber.toString();
      if (phoneNumber.length > 10) {
        phoneNumber = phoneNumber.substring(phoneNumber.length - 10, phoneNumber.length);
      }
      const areaCode = phoneNumber.slice(0, 3);
      const centralOfficeCode = phoneNumber.slice(3, 6);
      const stationCode = phoneNumber.slice(6, 10);
      const formattedPhoneNumber = `(${areaCode}) ${centralOfficeCode}-${stationCode}`;
      return formattedPhoneNumber;
    },
    getCognitoSub() {
      // Get the JWT from local storage
      const token = localStorage.getItem("user");

      if (!token) {
        return null; // Return null if no token is found
      }

      // Decode the JWT (JWTs are Base64URL encoded)
      const payload = JSON.parse(atob(token.split('.')[1]));

      // Return the 'sub' value from the payload
      return payload.sub || null;
    },
    async fetchDistinctTags() {
      let target = `https://123avatars.com/v2/unique-tags?role=${this.role != null ? this.role : ''}&userId=${this.userId}`;
      console.log(target);
      const response = await axios.get(target);
      this.tagsFilter = JSON.parse(response.data.data[0].result);
    },
    async fetchTags() {
      const selectedTags = this.selectedTagsList.map(tag => tag.tag).join(',');
      let target = 'https://123avatars.com/v2/tags';
      target += `?rowsPerPage=${this.rowsPerPage}&currentPage=${this.currentPage}&selectedTags=${encodeURIComponent(selectedTags)}&searchQuery=${encodeURIComponent(this.searchQuery)}&role=${encodeURIComponent(this.role != null ? this.role : '')}&userId=${encodeURIComponent(this.userId)}`;
      console.log(target);
      const response = await axios.get(target);
      this.tags = response.data.data;
      this.totalRows = response.data.totalRows;
    },
    parseTags(tags) {
      // Handle string or array input
      if (typeof tags === 'string') {
        try {
          return JSON.parse(tags);
        } catch {
          return [];
        }
      }
      return Array.isArray(tags) ? tags : [];
    },

    removeTag(rowId, tagToRemove) {
      const row = this.tags.find(row => row.id === rowId);
      if (row) {
        const currentTags = this.parseTags(row.tags);
        const updatedTags = currentTags.filter(tag => tag !== tagToRemove);
        row.tags = JSON.stringify(updatedTags);
      }
    },

  },
  async mounted() {
    await this.initUser();
    this.fetchDistinctTags();
    this.fetchTags();
  }
};
</script>
<style scoped>
#reportContainer .table {
  width: 100%;
  table-layout: fixed;
}

#reportContainer th,
#reportContainer td {
  text-align: left;
}

#reportContainer h3 {
  background-color: #B6E7C3;
}

.filterBody {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  gap: 1rem;
}

.dropdowns-container {
  display: flex;
  gap: 1rem;
  align-items: flex-start;
}



.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  background-color: #46A57C;
  border: 1px solid #46A57C;
  padding: 0.5rem 0;
}

.dropdown-menu li {
  list-style: none;
  padding: 0.5rem 1rem;
}

.dropdown-menu-style {
  list-style: none;
  padding: 0;
  margin: 0;
  min-width: 200px;
  /* Adjust this value based on your needs */
}

.tag-item,
.tag-itemoperators,
.tag-itemadmins,
.tag-itemmanagers,
.tag-itemroaming_closer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  cursor: pointer;
  color: #FFF;
}

.tag-itemoperators {
  background-color: #46A57C;
}

.tag-itemadmins {
  background-color: #A73A18;
}

.tag-itemmanagers {
  background-color: #2653C7;
}

.tag-itemroaming_closer {
  background-color: #0B7D8A;
}

.tag-text {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 16px;
  text-align: left;
  /* Space between tag and count */
}

.tag-count {
  flex-shrink: 0;
  /* Prevents the count from shrinking */
}

/* Optional: Add hover effect */
.tag-item:hover,
.tag-itemadmins:hover,
.tag-itemmanagers:hover,
.tag-itemoperators:hover,
.tag-itemroaming_closer:hover {
  font-weight: bold;
}

.remove-selection {
  border-color: red !important;
}

.remove-selection svg {

  fill: red !important;
}

.search-section {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.search-input {
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  outline: none;
  width: 300px;
}

.search-button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.tags-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.tags-list {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.tag-input-container {
  margin-top: 0.5rem;
}

.tag-input {
  padding: 0.25rem 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 12px;
  width: 100%;
  height: 24px;
}

.tag-input:focus {
  outline: none;
  border-color: #46A57C;
  box-shadow: 0 0 0 2px rgba(70, 165, 124, 0.2);
}

.tag-badge {
  display: inline-flex;
  align-items: center;
  padding: 0.35em 0.65em;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  background-color: #46A57C !important;
  color: white;
}

.tag-badgeadmins {
  background-color: #A73A18 !important;
}

.tag-badgeroaming_closer {
  background-color: #0B7D8A !important;
}

.tag-badgemanagers {
  background-color: #2653C7 !important;
}

.tag-badgeoperators {
  background-color: #46A57C !important;
}

.badge-close-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.5rem;
  padding: 0;
  font-size: 1.25em;
  font-weight: 700;
  line-height: 1;
  color: white;
  background: none;
  border: none;
  opacity: 0.75;
  cursor: pointer;
}

.badge-close-btn:hover {
  opacity: 1;
}
</style>