<template>
<svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M16.4617 10.8333C16.6175 10.8333 16.745 10.8911 16.8442 10.9922C16.9433 11.0933 17 11.2233 17 11.3822V12.4656C17 12.6244 16.9433 12.7544 16.8442 12.8556C16.745 12.9567 16.6175 13.0144 16.4617 13.0144H1.0625C0.779167 13.0144 0.524167 12.9133 0.311667 12.6967C0.0991667 12.48 0 12.2344 0 11.9311V0.548889C0 0.39 0.0566667 0.26 0.155833 0.158889C0.255 0.0577778 0.3825 0 0.538333 0H1.60083C1.75667 0 1.88417 0.0577778 1.98333 0.158889C2.0825 0.26 2.13917 0.39 2.13917 0.548889V10.8333H16.4758H16.4617ZM15.4133 1.08333C15.5692 1.08333 15.6967 1.14111 15.7958 1.24222C15.895 1.34333 15.9517 1.47333 15.9517 1.63222V5.63333C15.9517 5.99444 15.7817 6.24 15.4558 6.38444C15.13 6.52889 14.8325 6.45667 14.5917 6.21111L13.5292 5.12778L10.3417 8.37778C10.115 8.58 9.86 8.68111 9.57667 8.68111C9.29333 8.68111 9.03833 8.58 8.81167 8.37778L6.38917 5.87889L4.85917 7.43889C4.61833 7.71333 4.36333 7.71333 4.09417 7.43889L3.3575 6.68778C3.08833 6.41333 3.08833 6.15333 3.3575 5.90778L5.61 3.56778C5.83667 3.36556 6.09167 3.26444 6.375 3.26444C6.65833 3.26444 6.91333 3.36556 7.14 3.56778L9.5625 6.06667L11.985 3.56778L10.9225 2.48444C10.6817 2.23889 10.625 1.93556 10.7525 1.60333C10.88 1.27111 11.135 1.09778 11.4892 1.09778H15.4133V1.08333Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'MarketplaceIcon',
  props: {
    size: {
      type: [Number, String],
      default: 24
    },
    fill: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>

  