<template>
  <div id="marketplace">
    <UIBox classbox="">
      <template v-slot:title class="w-100">
        <div class="header-container">
          <div class="title-section">
            <span class="bold mr-2">Total Leads</span>
            <span>Showing {{ displayedRows }} out of {{ totalRows || 0 }}</span>
          </div>
        </div>
      </template>
      <div v-if="role === 'Admins'" class="button-wrapper">
        <button class="btn solid" @click="openPriceModel">Set Prices</button>
      </div>
      <!-- setPrices Modal for Admin -->
      <b-modal v-model="showPriceModel" title="Prospect Credit Pricing">
        <div>
          <table>
            <thead>
              <tr>
                <th></th>
                <th>Cost of Credits Per</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in creditItems" :key="index">
                <td>
                  <span style="font-weight: bold">{{
                    formatPriceLabel(item.label1)
                  }}</span
                  >{{ item.label2 }}
                </td>
                <td class="d-flex align-items-center gap-1">
                  <b-form-input
                    v-model="item.value"
                    class="credit-input"
                  ></b-form-input>
                  <strong class="ml-1">{{ item.selected }}</strong>
                </td>
                <td
                  v-if="item.selected"
                  class="d-flex align-items-center gap-1"
                >
                  <b-form-input
                    v-model="item.value"
                    class="credit-input"
                  ></b-form-input>
                  <strong class="ml-1">{{ item.selected2 }}</strong>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <template #modal-footer>
          <b-button
            @click="showPriceModel = false"
            variant="secondary"
            class="ml-2"
            >Cancel</b-button
          >
          <b-button
            type="submit"
            variant="secondary"
            :disabled="updatePricingLoading"
            @click="updateLeadScoring"
            >{{ updatePricingLoading ? "Loading..." : "Save" }}</b-button
          >
        </template>
      </b-modal>
      <template>
        <div class="filter">
          <div class="filterHead">Filter by</div>
          <div class="filterBody">
            <div class="filterSelect">
              <label for="inboundTexts">Inbound Texts</label>
              <!-- Manager Selector Filter -->
              <div class="select">
                <select
                  id="inboundTexts"
                  v-model="filters.inboundTexts"
                  @change="fetchMarketLeads"
                  class="form-control"
                >
                  <option value="">All</option>
                  <option value="0">0+</option>
                  <option value="1">1+</option>
                  <option value="5">5+</option>
                  <option value="10">10+</option>
                  <option value="20">20+</option>
                </select>
              </div>
            </div>
            <div class="filterSelect">
              <label for="inboundEmails">Inbound Emails</label>
              <!-- Manager Selector Filter -->
              <div class="select">
                <select
                  id="inboundEmails"
                  v-model="filters.inboundEmails"
                  @change="fetchMarketLeads"
                  class="form-control"
                >
                  <option value="">All</option>
                  <option value="0">0+</option>
                  <option value="1">1+</option>
                  <option value="5">5+</option>
                  <option value="10">10+</option>
                  <option value="20">20+</option>
                </select>
              </div>
            </div>
            <div class="filterSelect">
              <label for="minCall">Min Call Time</label>
              <!-- Manager Selector Filter -->
              <div class="select">
                <select
                  id="minCall"
                  v-model="filters.minCall"
                  @change="fetchMarketLeads"
                  class="form-control"
                >
                  <option value="">All</option>
                  <option value="0">0min+</option>
                  <option value="1">1min+</option>
                  <option value="3">3min+</option>
                  <option value="5">5min+</option>
                  <option value="10">10min+</option>
                  <option value="20">20min+</option>
                  <option value="30">30min+</option>
                </select>
              </div>
            </div>
            <div class="filterSelect">
              <label for="lastInbound">Days Since Last Inbound</label>
              <!-- Manager Selector Filter -->
              <div class="select">
                <select
                  id="lastInbound"
                  v-model="filters.lastInbound"
                  @change="fetchMarketLeads"
                  class="form-control"
                >
                  <option value="">All</option>
                  <option value="5">5+</option>
                  <option value="10">10+</option>
                  <option value="30">30+</option>
                  <option value="60">60+</option>
                  <option value="90">90+</option>
                </select>
              </div>
            </div>
            <div class="filterSelect">
              <label for="maxAgeLead">Max Age of Lead</label>
              <!-- Manager Selector Filter -->
              <div class="select">
                <select
                  id="maxAgeLead"
                  v-model="filters.maxAgeLead"
                  @change="fetchMarketLeads"
                  class="form-control"
                >
                  <option value="">All</option>
                  <option value="0">0+ Days</option>
                  <option value="1">1+ Days</option>
                  <option value="5">5+ Days</option>
                  <option value="15">15+ Days</option>
                  <option value="30">30+ Days</option>
                  <option value="60">60+ Days</option>
                  <option value="90">90+ Days</option>
                </select>
              </div>
            </div>
            <div class="filterSelect">
              <label for="hasName">Has Owner's Name</label>
              <!-- Manager Selector Filter -->
              <div class="select">
                <select
                  id="hasName"
                  v-model="filters.hasName"
                  @change="fetchMarketLeads"
                  class="form-control"
                >
                  <option value="">All</option>
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </div>
            </div>
            <div class="filterSelect">
              <label for="hasWebsite">Website</label>
              <!-- Manager Selector Filter -->
              <div class="select">
                <select
                  id="hasWebsite"
                  v-model="filters.hasWebsite"
                  @change="fetchMarketLeads"
                  class="form-control"
                >
                  <option value="">All</option>
                  <option value="1">Yes</option>
                  <option value="0">No</option>
                </select>
              </div>
            </div>
          </div>
          <div class="search-container mb-4">
            <label for="search">Search</label>
            <div :class="`search-box${role ? role.toLowerCase() : ''}`">
              <input
                id="search"
                v-model="searchQuery"
                type="text"
                placeholder="Search by Industry or Tag"
              />
              <button
                @click="handleSearch"
                :class="`search-icon${role ? role.toLowerCase() : ''}`"
              >
                <i class="fa fa-search" style="color: #fff !important" />
              </button>
            </div>
          </div>
          <div class="filterToggles">
            <button class="btn solid bg-main market-btn">
              Selected Leads {{ selectedLeadsCount || 0 }}
            </button>
            <button class="btn bg-main market-btn">
              Total Credits {{ totalCredits.toFixed(2) || 0 }}
            </button>
            <button
              class="btn solid"
              v-if="role !== 'Admins' && role !== 'Managers'"
              @click="handleBuySelectedLeads"
              :disabled="buyProcessingLoading"
            >
              {{
                buyProcessingLoading ? "Processing..." : "Buy Selected Leads"
              }}
            </button>
          </div>
        </div>
        <div class="leads-table">
          <table aria-controls="tagstable">
            <thead>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    class="mr-1 cursor-pointer"
                    @change="toggleAllLeads"
                  />All
                </th>
                <th>Inbound Texts</th>
                <th>Inbound Emails</th>
                <th>Total Talk Time</th>
                <th width="15%">Tags</th>
                <th>Industry</th>
                <th>Price (Credits)</th>
                <th>Has Owner's Name</th>
                <th>Website</th>
                <th>Days Since Last Inbound</th>
                <th>Age of Lead</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="loading">
                <td colspan="11" class="text-center p-4">
                  Leads are loading...
                </td>
              </tr>
              <tr v-else-if="marketLeads.length === 0">
                <td colspan="11" class="text-center p-4">
                  No leads found in marketplace.
                </td>
              </tr>
              <tr v-else v-for="lead in marketLeads" :key="lead.id">
                <td>
                  <input
                    type="checkbox"
                    class="cursor-pointer"
                    v-model="lead.selected"
                    @change="updateSelectedLeads"
                  />
                </td>
                <td>{{ lead.texts || 0 }}</td>
                <td>{{ lead.emails || 0 }}</td>
                <td>{{ convertSeconds(lead.total_talk_time) }}</td>
                <td class="tags-container">
                  <div class="tags-list">
                    <span
                      v-for="(tag, index) in limitedTags(lead.tags, lead)"
                      :key="index"
                      class="tag tag-item"
                    >
                      {{ tag }}
                    </span>
                    <span
                      v-if="parseTags(lead.tags).length > 2"
                      class="tag tag-item more-tags"
                      @click="lead.showAllTags = !lead.showAllTags"
                    >
                      {{ lead.showAllTags ? "Show Less" : "..." }}
                    </span>
                  </div>
                </td>
                <td>{{ lead.Industry || "" }}</td>
                <td>{{ lead.price }}</td>
                <td>{{ lead.Owners_Name?.length > 1 ? "Yes" : "No" }}</td>
                <td>{{ lead.Website ? "Yes" : "No" }}</td>
                <td>{{ formatDaysDate(lead.last_call_date) || 0 }}</td>
                <td>{{ lead.shelf_life }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
      <div class="bottom-input">
        <div class="per-page">
          <div class="select">
            Per Page
            <select
              class="per-page-drop"
              id="rowsPerPage"
              v-model="rowsPerPage"
              @change="fetchMarketLeads"
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="500">500</option>
            </select>
          </div>
        </div>

        <template>
          <div class="pagin">
            <b-pagination
              aria-controls="tagstable"
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="rowsPerPage"
            >
              <template #first-text>
                <PaginleftdoubleSVG />
              </template>
              <template #prev-text>
                <PaginleftSVG />
              </template>
              <template #next-text>
                <PaginrightSVG />
              </template>
              <template #last-text>
                <PaginrightdoubleSVG />
              </template>
            </b-pagination>
          </div>
        </template>
      </div>
    </UIBox>
  </div>
</template>

<script>
import axios from "axios";
import { BModal } from "bootstrap-vue";
import UIBox from "../components/uibox.vue";
import AuthService from "../_services/AuthService";
import checkSVG from "../components/SVG-icons/checkSVG.vue";
import unCheckedSVG from "../components/SVG-icons/unCheckedSVG.vue";
import PaginleftSVG from "../components/SVG-icons/paginleftSVG.vue";
import PaginrightSVG from "../components/SVG-icons/paginrightSVG.vue";
import PaginleftdoubleSVG from "../components/SVG-icons/paginleftdoubleSVG.vue";
import PaginrightdoubleSVG from "../components/SVG-icons/paginrightdoubleSVG.vue";

export default {
  name: "MarketPlace",
  components: {
    UIBox,
    BModal,
    checkSVG,
    unCheckedSVG,
    PaginleftSVG,
    PaginrightSVG,
    PaginleftdoubleSVG,
    PaginrightdoubleSVG,
  },
  watch: {
    currentPage() {
      this.fetchMarketLeads();
    },
  },
  computed: {
    displayedRows() {
      return this.rowsPerPage > this.totalRows
        ? this.totalRows
        : this.rowsPerPage || 0;
    },
  },
  data() {
    return {
      loading: true,
      marketLeads: [],
      searchQuery: "",
      rowsPerPage: 10,
      currentPage: 1,
      totalRows: 0,
      filters: {
        inboundTexts: "",
        inboundEmails: "",
        minCall: "",
        lastInbound: "",
        maxAgeLead: "",
        hasName: "",
        hasWebsite: "",
      },
      filterOptions: ["All", "Option 1", "Option 2"],
      role: "",
      showPriceModel: false,
      selectedLeadsCount: 0,
      totalCredits: 0,
      creditItems: [
        { label1: "", label2: "", value: "" },
        { label1: "", label2: "", value: "" },
        { label1: "", label2: "", value: "" },
        { label1: "", label2: "", value: "" },
        {
          label1: "",
          value: "",
          selected: "",
          selected2: "",
        },
        {
          label1: "",
          value: "",
          selected: "",
          selected2: "",
        },
        {
          label1: "",
          value: "",
          selected: "",
          selected2: "",
        },
      ],
      updatePricingLoading: false,
      buyProcessingLoading: false,
      userCredits: 0,
    };
  },
  methods: {
    async fetchMarketLeads() {
      try {
        this.loading = true;
        this.totalCredits = 0.00;
        this.selectedLeadsCount = 0;
        const response = await axios.get(
          `https://123avatars.com/v2/market-leads`,
          {
            params: {
              search: this.searchQuery || undefined,
              last_call_days: this.filters.lastInbound || undefined,
              texts: this.filters.inboundTexts || undefined,
              emails: this.filters.inboundEmails || undefined,
              total_talk_time: this.filters.minCall
                ? this.filters.minCall * 60
                : undefined,
              shelf_life: this.filters.maxAgeLead || undefined,
              has_owners_name: this.filters.hasName || undefined,
              website: this.filters.hasWebsite || undefined,
              limit: this.rowsPerPage,
              page: this.currentPage,
            },
          }
        );
        this.marketLeads = response.data.leads;
        this.totalRows = response.data.total;

        this.marketLeads = response.data.leads.map((lead) => ({
          ...lead,
          selected: false,
          showAllTags: false,
          price: this.calculateLeadPrice(lead).toFixed(2),
        }));
      } catch (error) {
      } finally {
        this.loading = false;
      }
    },

    parseTags(tags) {
      if (typeof tags === "string") {
        try {
          return JSON.parse(tags);
        } catch {
          return [];
        }
      }
      return Array.isArray(tags) ? tags : [];
    },
    limitedTags(tags, lead) {
      const parsedTags = this.parseTags(tags);
      return lead.showAllTags ? parsedTags : parsedTags.slice(0, 2);
    },
    convertSeconds(seconds) {
      if (seconds >= 3600) {
        const hours = Math.floor(seconds / 3600);
        const remainingMinutes = Math.floor((seconds % 3600) / 60);
        return `${hours}h ${remainingMinutes}m`;
      } else if (seconds >= 60) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}m ${remainingSeconds}s`;
      } else {
        return `${seconds}s`;
      }
    },
    formatDaysDate(last_call_date) {
      if (!last_call_date) return 0;
      const givenDate = new Date(last_call_date);
      const currentDate = new Date();

      const diffTime = currentDate - givenDate; // Difference in milliseconds
      return Math.floor(diffTime / (1000 * 60 * 60 * 24)); // Convert to days
    },
    handleSearch() {
      this.fetchMarketLeads();
    },
    roleColor() {
      const colors = {
        admins: "#E7B6B6",
        managers: "#B6C3E7",
        operators: "#B6E7C3",
        roaming_closer: "B3E1E8",
      };
      return colors[this.role?.toLowerCase()] || "#46A57C";
    },
    async initUser() {
      this.role = await AuthService.getUserRole();
      this.userId = await AuthService.getCognitoSub();
      this.origRole = this.role;
      this.origUserId = this.userId;
    },
    updateSelectedLeads() {
      this.selectedLeadsCount = this.marketLeads.filter(
        (lead) => lead.selected
      ).length;
      this.totalCredits = this.marketLeads
        .filter((lead) => lead.selected)
        .reduce((sum, lead) => sum + parseFloat(lead.price || 0), 0);
    },
    toggleAllLeads(event) {
      const isChecked = event.target.checked;
      this.marketLeads.forEach((lead) => {
        lead.selected = isChecked;
      });
      this.updateSelectedLeads();
    },
    async fetchLeadScoring() {
      try {
        const response = await axios.get(
          `https://123avatars.com/v2/market-lead-scoring`
        );
        this.creditItems = response.data.scoring.map((item) => ({
          label1: item.label,
          value: item.score,
          selected: item.selected || "",
          selected2: item.selected2 || "",
        }));
      } catch (error) {
        console.error("Error fetching lead scoring data:", error);
      }
    },
    async updateLeadScoring() {
      this.updatePricingLoading = true;
      try {
        const scoringData = this.creditItems.map((item) => ({
          label: item.label1.replace(/\s+/g, "_").toLowerCase(), // Convert label for API
          score: parseFloat(item.value) || 0, // Ensure valid number
        }));

        const response = await axios.put(
          `https://123avatars.com/v2/market-lead-scoring`,
          {
            scoringData,
          }
        );
        if (response.status === 200) {
          await this.fetchLeadScoring();
          await this.fetchMarketLeads();
          this.showPriceModel = false; // Close modal on success
          this.$toasted.show(`Prices updated successfully!`, {
            theme: "outline",
            position: "top-right",
            type: "success",
            icon: "check",
            duration: 5000,
          });
        }
        this.updatePricingLoading = false;
      } catch (error) {
        console.error("Error updating lead scoring:", error);
        this.updatePricingLoading = false;
        alert(`An error occurred while updating lead prices. Error ${error}`);
      }
    },
    calculateLeadPrice(lead) {
      if (!this.creditItems) return 0;
      let price = 0;

      this.creditItems.forEach((item) => {
        const key = item.label1;

        // Handle numeric attributes
        if (key === "inbound_emails" && lead.emails > 0) {
          price += lead.emails * item.value;
        }
        if (key === "inbound_texts" && lead.texts > 0) {
          price += lead.texts * item.value;
        }
        if (key === "talk_time_per_minute" && lead.total_talk_time > 60) {
          price += (parseInt(lead.total_talk_time) / 60) * item.value;
        }
        if (key === "age_per_day" && lead.shelf_life > 0) {
          price += lead.shelf_life * item.value;
        }

        // Handle categorical attributes (Website, Owner's Name)
        if (key === "has_website_yes" && lead.Website) {
          price += item.value;
        }
        if (key === "has_website_no" && !lead.Website) {
          price += item.value;
        }
        if (key === "has_owners_name_yes" && lead.Owners_Name) {
          price += item.value;
        }
        if (key === "has_owners_name_no" && !lead.Owners_Name) {
          price += item.value;
        }

        // Handle last inbound days
        if (
          key === "last_inbounds_days_over_5" &&
          this.formatDaysDate(lead.last_call_date) > 5
        ) {
          price += item.value;
        }
        if (
          key === "last_inbounds_days_under_5" &&
          this.formatDaysDate(lead.last_call_date) <= 5
        ) {
          price += item.value;
        }
      });

      return price;
    },
    openPriceModel() {
      this.showPriceModel = true;
    },
    formatPriceLabel(label) {
      return label
        .replace(/_/g, " ")
        .replace(/\b\w/g, (char) => char.toUpperCase());
    },
    async handleBuySelectedLeads() {
      this.buyProcessingLoading = true;

      // Get selected leads
      const selectedLeads = this.marketLeads
        .filter((lead) => lead.selected)
        .map((lead) => ({
          id: lead.id,
          price: parseFloat(lead.price),
        }));

      // Check if there are selected leads
      if (selectedLeads.length === 0) {
        this.$toasted.show("Please select at least one lead to buy.", {
          theme: "outline",
          position: "top-right",
          type: "error",
          duration: 5000,
        });
        this.buyProcessingLoading = false;
        return;
      }

      // Calculate total cost
      const totalPrice = selectedLeads.reduce(
        (sum, lead) => sum + lead.price,
        0
      );

      try {
        // Fetch user's credits
        let response = await axios.get(
          `https://123avatars.com/v2/sales-reps/${this.userId}`
        );
        this.userCredits = response.data[0].credits;

        // Check if the user has enough credits
        if (totalPrice > this.userCredits) {
          this.$toasted.show(
            `You don't have enough credits. Required: ${totalPrice}, Available: ${this.userCredits}`,
            {
              theme: "outline",
              position: "top-right",
              type: "error",
              duration: 5000,
            }
          );
          this.buyProcessingLoading = false;
          return;
        }

        // Send API request to purchase leads
        const purchaseResponse = await axios.post(
          `https://123avatars.com/v2/market-purchase-leads`,
          {
            userId: this.userId,
            leadIds: selectedLeads,
            totalPrice: totalPrice,
          }
        );
        console.log("leads to buy", selectedLeads);
        this.$toasted.show(purchaseResponse?.data?.message, {
          theme: "outline",
          position: "top-right",
          type: "success",
          icon: "check",
          duration: 20000,
        });
        this.totalCredits = 0.00;
        this.selectedLeadsCount = 0;
        // Refresh leads after purchase
        await this.fetchMarketLeads();
      } catch (error) {
        console.error("Error buying leads:", error);
        this.$toasted.show(
          "An error occurred while processing your purchase.",
          {
            theme: "outline",
            position: "top-right",
            type: "error",
            duration: 5000,
          }
        );
      } finally {
        this.buyProcessingLoading = false;
      }
    },
  },
  async mounted() {
    await this.initUser();
    await this.fetchLeadScoring();
    await this.fetchMarketLeads();
  },
};
</script>

<style scoped lang="scss">
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100;
  position: relative;
  box-sizing: border-box;
}

.title-section {
  flex: 1;
}

.button-wrapper {
  margin-left: auto;
  margin-top: -77px;
}

.credit-input {
  width: 60px;
  text-align: center;
}

.marketplace {
  padding: 20px;
}

.filter-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.filters {
  display: flex;
  gap: 10px;
}

.search {
  display: flex;
  gap: 10px;
}

.leads-table {
  width: 100%;
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 10px;
  text-align: center;
  border-bottom: 1px solid #ddd;
}

.tag {
  background-color: #e0e0e0;
  padding: 5px;
  border-radius: 5px;
  margin-right: 5px;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.search-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 24px;
}

.search-box,
.search-boxadmins,
.search-boxmanagers,
.search-boxroaming_closer,
.search-boxoperators {
  position: relative;
  width: 475px;
}

.search-box input,
.search-boxadmins input,
.search-boxmanagers input,
.search-boxroaming_closer input,
.search-boxoperators input {
  width: 100%;
  padding: 8px 40px 8px 16px;
  border-radius: 8px;
}

.search-box input {
  border: 2px solid #46a57c !important;
}

.search-boxadmins input {
  border: 2px solid #a73a18 !important;
}

.search-boxmanagers input {
  border: 2px solid #2653c7 !important;
}

.search-boxroaming_closer input {
  border: 2px solid #0b7d8a !important;
}

.search-boxoperators input {
  border: 2px solid #46a57c !important;
}

.search-box {
  border-color: #46a57c !important;
}

.search-boxadmins {
  border-color: #a73a18 !important;
}

.search-boxmanagers {
  border-color: #2653c7 !important;
}

.search-boxroaming_closer {
  border-color: #0b7d8a !important;
}

.search-boxoperators {
  border-color: #46a57c !important;
}

.search-box input:focus {
  outline: none;
  border: 2px solid #46a57c !important;
}

.search-boxmanagers input:focus {
  outline: none;
  border: 2px solid #2653c7 !important;
}

.search-boxroaming_closer input:focus {
  outline: none;
  border: 2px solid #0b7d8a !important;
}

.search-boxoperators input:focus {
  outline: none;
  border: 2px solid #46a57c !important;
}

.search-boxadmins input:focus {
  outline: none;
  border: 2px solid #a73a18 !important;
}

.search-icon,
.search-iconadmins,
.search-iconmanagers,
.search-iconroaming_closer,
.search-iconoperators {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  font-size: 16px;
  padding: 0 12px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  align-items: center;
  border-style: none;
}

.search-icon {
  background-color: #46a57c !important;
  border-color: #46a57c !important;
}

.search-iconadmins {
  background-color: #a73a18 !important;
  border-color: #a73a18 !important;
}

.search-iconmanagers {
  background-color: #2653c7 !important;
  border-color: #2653c7 !important;
}

.search-iconroaming_closer {
  background-color: #0b7d8a !important;
  border-color: #0b7d8a !important;
}

.search-iconoperators {
  background-color: #46a57c !important;
  border-color: #46a57c !important;
}

.market-btn {
  border: none;
  color: #2b2b2b;
  font-weight: 600;
}

.market-btn:hover {
  border: none;
  color: #2b2b2b;
  font-weight: 600;
}

.filterSelect select option {
  cursor: pointer !important;
}

.tags-list {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: center;
}
.more-tags {
  cursor: pointer;
}
</style>
