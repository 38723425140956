<template>
    <svg viewBox="0 0 13 10" class="caretDownSVG" :style="{ width: size + 'px', height: 'auto' }" fill="none">
        <path d="M0 -4.97549e-07L13 0L6.5 9.63142L0 -4.97549e-07Z" :fill="fill"/>
    </svg>
</template>

<script>
export default {
   name: 'caretDownSVG',
   props: {
    size: {
      type: [Number, String],
      default: 12
    },
    fill: {
      type: String,
      default: 'white'
    }
  }
}
</script>