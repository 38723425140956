<template>
  <div>
  <UIBox classbox="hello">
    <template v-slot:title>
    <!-- Youtube embedded video -->
    <button class="btn play-video-button custom-pl-2" @click="showYouTubePopup = true">
        <i class="fa fa-play"></i>
      </button>
    <YouTubePopup :videoId="youtubeVideoId" :show.sync="showYouTubePopup" @update:show="showYouTubePopup = $event" />
    <!--/-->
      <span class="bold">Text Messages | {{ inboxUnreadCount }} Unread Messages</span>
    </template>
    <template>
      <div class="filter">
        <div class="filterBody flex-column" v-if="origRole === 'Admins'">
          <label class="form-label" style="font-weight: bold; font-size: 14px; width: 80px;">Sales Rep : </label>
          <div class="form-group select flex items-center gap-2">
            <select v-model="selectedSalesRep" class="form-control" style="width:200px;">
              <option key="admin" value="admin" selected>All Sales Reps</option>
              <option v-for="userInInbox in usersInInbox" :key="userInInbox.sub" :value="userInInbox.sub">{{
                userInInbox.salesRep }}</option>
            </select>
          </div>
        </div>
        <div class="search-container m-0">
          <div :class="`search-box${role ? role.toLowerCase() : ''}`">
            <input type="text" placeholder="Search by company name, phone number, email or owners name" v-model="searchQuery" @keyup.enter="fetchMessages" />
            <button :class="`search-icon${role ? role.toLowerCase() : ''}`" @click="fetchMessages">
              <i class="fa fa-search" style="color: #FFF !important" />
            </button>
          </div>
        </div>
      </div>
    </template>
    <template>
      <div class="table-fullwidth">
        <table class="table call-backs-table inbox-table" :class="`inbox-table${role ? role.toLowerCase() : ''}`" aria-controls="tagstable">
          <thead>
            <tr>
              <th v-if="role === 'Admins' || role === 'Managers'" style="width: 19%;">LeadFarm User</th>
              <th v-if="role === 'Roaming_Closer'" style="width: 39%;">Company</th>
              <th v-else style="width: 20%;">Company</th>
              <th style="width: 10%;">Date</th>
              <th style="width: 30%;">Preview</th>
              <th style="width: 0%;">Pipeline Stage</th>
              <th style="width: 20%;"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in messages" :data-sales-rep-id="row.id" :class="`${row.opened != 1 ? 'unread' : ''}`">
              <td v-if="role === 'Admins' || role === 'Managers'" @click="openMessage($event, row, row.opened != 1)">{{ row.salesRep }}</td>
              <td @click="openMessage($event, row, row.opened != 1)">{{ row.company }}</td>
              <td @click="openMessage($event, row, row.opened != 1)">{{ formatDate(row.created_date) }}</td>
              <td @click="openMessage($event, row, row.opened != 1)">
                <div class="flex items-center gap-2">
                  <chatSVG v-if="row.message_type == '2'" :size="16" :fill="roleColor()" />
                  <emailSVG v-else-if="row.message_type == '4'" :size="16" :fill="roleColor()" />
                  <div>{{ truncateText(stripHtml(row.text), 50) }}</div>
                </div>
              </td>
              <td style="width: 0%;">
                <div class="select">
                  <select
                    class="form-control"
                    style="width:150px;"
                    @change="updatePipelineStage(row.leadId, $event.target.value)"
                  >
                    <option
                      v-for="(tab, index) in tabs"
                      :key="index"
                      :value="tab.stage"
                      :selected="tab.stage === row.stage"
                    >
                      {{ tab.label }}
                    </option>
                  </select>
                </div>
              </td>
              <td>
                <div class="flex items-center gap-2" style="display: flex !important; align-items: center; gap: 0.5rem">
                  <div style="cursor: pointer" @click="starUnstar($event, row)">
                    <starSVG v-if="row.starred" :size="24" :fill="roleColor()" />
                    <starOutlineSVG v-if="!row.starred" :size="24" :fill="roleColor()" />
                  </div>
                  <button class="btn outline" @click="makeDirectCall($event, row)">
                    <phoneSVG :size="16" :fill="roleColor()" />
                  </button>
                  <b-dropdown id="dropdown-1" text="Actions" class="m-md-2">
                    <b-dropdown-item @click="sendDirectMessage($event, row)" class="action-button">
                      <chatSVG :size="16" :fill="roleColor()"  class="action-svg"/>
                      <span>Send Message</span>
                    </b-dropdown-item>
                  <b-dropdown-item type v-b-modal.callbackModal  @click="setCallBack($event, row)" class="action-button">
                    <callincomingSVG :size="16" :fill="roleColor()" class="action-svg" />
                    <span>Set Callback</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="setTags($event, row)" class="action-button">
                    <tagsSVG :size="16" :fill="roleColor()"  class="action-svg"/>
                    <span>Set Tags</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="callProspect(row)" class="action-button">
                    <launchindialerSVG :size="16" :fill="roleColor()"  class="action-svg"/>
                    <span>Open Profile</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="openSignUpPage(row)" class="action-button">
                  <extlinkSVG :size="16"  :fill="roleColor()"  class="action-svg" />
                  <span>Signup Prospect</span>
                 </b-dropdown-item>
                 <b-dropdown-item @click="blacklist" class="action-button">
                  <BlacklistSVG :size="16"  :fill="roleColor()"  class="action-svg fill-red" />
                  <span style="color: red;">Add to DNC</span>
                  </b-dropdown-item>
                </b-dropdown>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="bottom-input">

        <div class="per-page">
          <div class="select">
            Per Page
            <select class="per-page-drop" id="rowsPerPage" v-model="rowsPerPage" @change="fetchMessages">
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="500">500</option>
            </select>
          </div>
        </div>

        <template>
          <div class="pagin">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="rowsPerPage"
              aria-controls="tagstable">
              <template #first-text>
                <PaginleftdoubleSVG />
              </template>
              <template #prev-text>
                <PaginleftSVG />
              </template>
              <template #next-text>
                <PaginrightSVG />
              </template>
              <template #last-text>
                <PaginrightdoubleSVG />
              </template>
            </b-pagination>
          </div>
        </template>
      </div>

    </template>
    <template>
      <b-modal v-model="notificationModal" title="Inbox Call" hide-footer>
        <div class="form-container">
          <div class="row notification-text" style="width: 440px; padding-bottom: 20px; padding-left: 20px;">Performing
            Call..</div>
          <div class="button-group call-butt on-right-aligned">
            <button class="btn solid" type="button" @click="notificationModal = false">OK</button>
          </div>
        </div>
      </b-modal>
    </template>
    <template>
      <b-modal v-model="sendMessageModal" title="Reply To Message" hide-footer>
        <div class="form-container">
          <div style="margin: 10px; width: 100%;">
            <textarea v-model="directMessageText" class="prompt-input" placeholder="Enter your message here..." rows="3"
              style="
                width: 440px;
                resize: none;
                max-height: 90px;
                overflow-y: auto;
                border-style: solid;
                border-width: 1px;
                border-color: #ccc;
              "></textarea>
          </div>
          <div class="button-group call-button-right-aligned gap-2">
            <button class="btn solid danger" type="button" @click="performSendOfDirectMessage"
              style="margin-right: 10px;">Send</button>
            <button class="btn solid danger" type="button" @click="sendMessageModal = false">Cancel</button>
          </div>
        </div>
      </b-modal>
    </template>
    <template>
      <b-modal v-model="messageTags" title="Add Tags To Message" hide-footer>
        <div class="form-container">
          <div style="margin: 10px; width: 100%;">
            <div class="tags-container">
              <div class="tags-list">
                <span v-for="(tag, index) in parseTags(rowTags)" :key="index" :class="{
                  badge: true,
                  'badge-primary': true,
                  [`tag-badge${role ? role.toLowerCase() : ''}`]: true
                }">
                  {{ tag }}
                  <button class="badge-close-btn" @click="removeTag(tag)" aria-label="Remove tag">
                    ×
                  </button>
                </span>
              </div>
              <div class="tag-input-wrapper">
                <input v-model="newTag" @keyup.enter="addTag" type="text" class="tag-input" placeholder="Add tag..." style="width: 435px;"/>
              </div>
            </div>
          </div>
          <div class="button-group call-button-right-aligned gap-2">
            <button class="btn solid danger" type="button" @click="performSetTags"
              style="margin-right: 10px;">Save</button>
            <button class="btn solid danger" type="button" @click="messageTags = false">Cancel</button>
          </div>
        </div>
      </b-modal>
    </template>
    <Callbacks :type="'NEW'" :prospect="company" :userId="userId" v-if="company" />
    <b-modal v-if="true" id="tagsModal" title="Add/Edit Tags" size="lg">
      <template #modal-header="{ close }" class="header">
        <div>Set Tags For <span class="bold">{{ company.company }}</span></div>
        <button class="btn" @click="close()">
          <closeSVG />
        </button>
      </template>
    </b-modal>
  </UIBox>
  </div>
</template>

<script>

import axios from 'axios';
import UIBox from '../../components/uibox.vue';
import AuthService from '../../_services/AuthService';
import PaginleftdoubleSVG from '../../components/SVG-icons/paginleftdoubleSVG.vue';
import PaginleftSVG from '../../components/SVG-icons/paginleftSVG.vue';
import PaginrightdoubleSVG from '../../components/SVG-icons/paginrightdoubleSVG.vue';
import PaginrightSVG from '../../components/SVG-icons/paginrightSVG.vue';
import starSVG from '../../components/SVG-icons/starSVG.vue';
import phoneSVG from '../../components/SVG-icons/phoneSVG.vue';
import ExtlinkSVG from '../../components/SVG-icons/extlinkSVG.vue';
import BlacklistSVG from '../../components/SVG-icons/blacklistSVG.vue';
import emailSVG from '../../components/SVG-icons/emailSVG.vue';
import chatSVG from '../../components/SVG-icons/chatSVG.vue';
import tagsSVG from '../../components/SVG-icons/tagsSVG.vue';
import launchindialerSVG from '../../components/SVG-icons/launchindialerSVG.vue';
import starOutlineSVG from '../SVG-icons/starOutlineSVG.vue';
import callincomingSVG from '../SVG-icons/callincomingSVG.vue';
import Callbacks from '../../pages/sales/Callbacks.vue';
import closeSVG from '../SVG-icons/closeSVG.vue';
import YouTubePopup from '../../components/YouTubePopup.vue';

export default {
  name: "Inbox",
  components: {
    UIBox,
    PaginleftdoubleSVG,
    PaginleftSVG,
    PaginrightdoubleSVG,
    PaginrightSVG,
    starSVG,
    phoneSVG,
    emailSVG,
    chatSVG,
    tagsSVG,
    launchindialerSVG,
    starOutlineSVG,
    callincomingSVG,
    Callbacks,
    closeSVG,
    YouTubePopup,
    ExtlinkSVG,
    BlacklistSVG
    },
  props: {
    userTimezone: {
      type: String,
      required: true
    },
    inboxUnreadCount: {
      type: Number,
      required: true
    }
  },
  watch: {
    selectedSalesRep(newValue) {
      if (newValue !== 'admin') {
        this.userId = newValue;
        this.role = 'Roaming_Closer';
        this.fetchMessages();
      } else {
        this.userId = this.origUserId;
        this.role = 'Admins';
        this.fetchMessages();
      }
    },
    currentPage() {
      this.fetchMessages();
    },
  },
  data() {
    return {
      selectedSalesRep: 'admin',
      userId: '',
      role: '',
      messages: [],
      totalRows: 0,
      rowsPerPage: 10,
      currentPage: 1,
      notificationModal: false,
      sendMessageModal: false,
      messageTags: false,
      directMessageText: '',
      selectedRow: {},
      usersInInbox: [],
      origUserId: '',
      origRole: '',
      company: '',
      rowTags: [],
      selectedRowIdForTag: 0,
      newTag: '',
      tabs: [
        { label: "All Leads", stage: 0 },
        { label: "Made Contact", stage: 1 },
        { label: "Service Offered", stage: 2 },
        { label: "Ready to Sign Up", stage: 3 },
      ],
      searchQuery: "",
      showYouTubePopup: false,
      youtubeVideoId: 'Q1lwOKla0cQ' 
    };
  },
  methods: {
    removeTag(tagToRemove) {
      const currentTags = this.parseTags(this.rowTags);
      const updatedTags = currentTags.filter(tag => tag !== tagToRemove);
      this.rowTags = JSON.stringify(updatedTags);
    },
    addTag() {
      if (!this.newTag.trim()) return; // Don't add empty tags

      const currentTags = this.parseTags(this.rowTags);
      if (currentTags.includes(this.newTag.trim())) {
        return;
      }

      const updatedTags = [...currentTags, this.newTag.trim()];
      this.rowTags = JSON.stringify(updatedTags);
      this.newTag = '';
    },
    parseTags(tags) {
      // Handle string or array input
      if (typeof tags === 'string') {
        try {
          return JSON.parse(tags);
        } catch {
          return [];
        }
      }
      return Array.isArray(tags) ? tags : [];
    },
    async performSetTags() {
      try {
        const result = await axios.post(`https://123avatars.com/v2/set-tags`, {
          id: this.selectedRowIdForTag,
          tags: this.rowTags
        });
        console.log('result: ', result);
        this.messageTags = false;
      } catch (error) {
      }
    },
    async setTags(e, row) {
      e.stopPropagation();
      this.rowTags = row.tags;
      this.selectedRowIdForTag = row.leadId;
      this.messageTags = true;
    },
    mapCallbackData(input) {
      const formattedDate = this.getFormattedDate(this.getTimezoneFromAbbreviation(input.timezone));

      return {
        id: input.id,
        owner: '-',
        company_name: input.company || '-',
        timezone: input.timezone,
        localTime: input.timeUser || '123',
        userTimezone: this.userTimezone,
        leadId: input.leadId ? input.leadId.toString() : '123',
        callbackTime: formattedDate
      };
    },
    getFormattedDate(timezone) {
      const date = new Date();
      const options = { 
        timeZone: timezone, 
        year: 'numeric', 
        month: '2-digit', 
        day: '2-digit', 
        hour: '2-digit', 
        minute: '2-digit', 
        second: '2-digit', 
        hour12: false 
      };

      const formatter = new Intl.DateTimeFormat('en-US', options);
      const parts = formatter.formatToParts(date);

      const formattedDate = `${parts.find(p => p.type === 'year').value}-${parts.find(p => p.type === 'month').value}-${parts.find(p => p.type === 'day').value}T${parts.find(p => p.type === 'hour').value}:${parts.find(p => p.type === 'minute').value}:${parts.find(p => p.type === 'second').value}`;

      const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
      const targetDate = new Date(date.toLocaleString('en-US', { timeZone: timezone }));
      const offset = (targetDate - utcDate) / 60000;

      const offsetHours = String(Math.floor(Math.abs(offset) / 60)).padStart(2, '0');
      const offsetMinutes = String(Math.abs(offset) % 60).padStart(2, '0');
      const offsetSign = offset >= 0 ? '+' : '-';

      const offsetString = offset === 0 ? 'Z' : `${offsetSign}${offsetHours}:${offsetMinutes}`;

      return `${formattedDate}${offsetString}`;
    },
    getTimezoneFromAbbreviation(abbreviation) {
      const timezones = {
        'EST': 'America/New_York', // Eastern Standard Time
        'EDT': 'America/New_York', // Eastern Daylight Time
        'CST': 'America/Chicago', // Central Standard Time
        'CDT': 'America/Chicago', // Central Daylight Time
        'MST': 'America/Denver', // Mountain Standard Time
        'MDT': 'America/Denver', // Mountain Daylight Time
        'PST': 'America/Los_Angeles', // Pacific Standard Time
        'PDT': 'America/Los_Angeles', // Pacific Daylight Time
        'AKST': 'America/Anchorage', // Alaska Standard Time
        'AKDT': 'America/Anchorage', // Alaska Daylight Time
        'HST': 'Pacific/Honolulu', // Hawaii Standard Time
        'HDT': 'Pacific/Honolulu' // Hawaii Daylight Time (though Hawaii does not observe DST)
      };

      return timezones[abbreviation] || 'America/Los_Angeles';
    },
    setCallBack(e, row) {
      e.stopPropagation();
      this.company = null;
      this.company = this.mapCallbackData(row);
    },
    async performSendOfDirectMessage() {
      try {
        const result = await axios.post(`https://123avatars.com/v2/inbox/reply-to-message`, {
          leadId: this.selectedRow.leadId,
          azureContactId: this.selectedRow.azureLeadId,
          to: `${this.selectedRow.from_no.trim()}`,
          from: `${this.selectedRow.to_no.trim()}`,
          company: this.selectedRow.company,
          text: this.directMessageText,
          isReply: true
        });
        console.log('result: ', result);
      } catch (error) {
      }

      this.sendMessageModal = false;
      this.directMessageText = '';
    },
    sendDirectMessage(e, row) {
      e.stopPropagation();
      this.selectedRow = row;
      this.sendMessageModal = true;
    },
    makeDirectCall(e, row) {
      e.stopPropagation();
      this.$emit('make-call', row.from_no,  row.to_no);
      this.notificationModal = true;
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleString('en-US', {
        month: 'numeric',
        day: 'numeric',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
      });
    },
    callProspect(row) {
      console.log(`emit ${row.from_no.replace('+', '')} ${row.leadId} ${this.userId}`);
      this.$emit('open-call-prospect', {
        salesRepId: row.sub,
        azureContactId: row.azure_contact_id,
        leadId: row.leadId,
        phone: row.from_no,
        fromInbox: true,
        makeCall: false
      });
    },
  async openSignUpPage(row) {
    let signupLink = this.signupLink || "http://pricing.123websites.net";
    signupLink += `?rep_id=${this.userId}&campaign_id=${this.company.campaignId}&lead_id=${row.leadId}&originalSalesRep=${this.userName.replaceAll(" ", "++")}`;
    window.open(signupLink, '_blank');
     },async blacklist(row) {
      try {
      this.$toasted.show("Adding to blacklist...", {
        theme: "outline",
        position: "top-right",
        type: "info",
        icon: "refresh",
        duration: 2000,
      });

      await axios.post(
        "https://123avatars.com/v2/add-to-blacklist",
        {
          lead_id: row.leadId
        }
      );

      this.$toasted.show("Added to blacklist successfully ", {
        theme: "outline",
        position: "top-right",
        type: "success",
        icon: "check",
        duration: 2000,
      });
    } catch (error) {
      this.$toasted.show("Failed to add to blacklist.", {
        theme: "outline",
        position: "top-right",
        type: "danger",
        icon: "cross",
        duration: 2000,
      });
    }    },
    async openMessage(e, row, open = true) {     
      e.stopPropagation();
      try {
        row.opened = open;
        const target = 'https://123avatars.com/v2/inbox/open';
        await axios.post(target, {
          id: row.leadId,
          open
        });

        this.$set(row, 'opened', open);
        this.fetchMessages();
      } catch (error) {
        console.error('Error opening message:', error);
      }
    },
    async starUnstar(e, row) {
      e.stopPropagation();
      try {
        row.starred = !row.starred;
        const target = 'https://123avatars.com/v2/inbox/star';
        const starred = row.starred ? 1 : 0;
        await axios.post(target, {
          id: row.leadId,
          starred
        });

        if (starred === 1) {
          this.$toast.info("We have also moved the lead to the 'Ready to Sign Up' folder in the pipeline.", {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnHover: true,
            style: {
              width: "440px",
            },
          });
        }

        this.$set(row, 'starred', row.starred);

      } catch (error) {
        console.error('Error opening message:', error);
      }
    },
    async updatePipelineStage(lead_id, newStage) {
      try {
        const tab = this.tabs.find((tab) => tab.stage == newStage);

        this.$toasted.show(`Moving the lead to the '${tab.label}' folder in the pipeline...`, {
          theme: "outline",
          position: "top-right",
          type: "info",
          icon: "refresh",
          duration: 2000,
        });

        const response = await axios.put(`https://123avatars.com/v2/pipeline-leads-stage`, {
          lead_id,
          newStage: parseInt(newStage, 10),
        });

        if (response.status === 200) {
          this.$toasted.show(`Lead has been moved successfully to the '${tab.label}' folder`, {
            theme: "outline",
            position: "top-right",
            type: "success",
            icon: "check",
            duration: 2000,
          });
        } else {
          this.$toasted.show(`${response.data.message || "Something went wrong"}`, {
            theme: "outline",
            position: "top-right",
            type: "error",
            icon: "close",
            duration: 2000,
          });
        }
      } catch (error) {
        console.error("Error updating pipeline stage:", error);
      }
    },
    roleColor() {
      const colors = {
        admins: '#A73A18',
        managers: '#2653C7',
        operators: '#46A57C',
        roaming_closer: '#0B7D8A',
      };
      return colors[this.origRole.toLowerCase()] || '#46A57C';
    },
    async fetchMessages() {
      localStorage.setItem('rowsPerPage', this.rowsPerPage);

      let target = 'https://123avatars.com/v2/inbox';
      target += `?limit=${this.rowsPerPage}&offset=${this.currentPage - 1}&role=${this.role}&id=${this.userId}&search=${this.searchQuery}`;
      const response = await axios.get(target);
      this.messages = response.data.data;
      this.totalRows = response.data.totalRows;
    },
    async initUser() {
      this.role = await AuthService.getUserRole();
      this.userId = await AuthService.getCognitoSub();
      this.origRole = this.role;
      this.origUserId = this.userId;
    },
    async fetUsersInInbox() {
      const result = await axios.get(`https://123avatars.com/v2/inbox/users`);
      this.usersInInbox = result.data;
    },
    stripHtml(text) {
      return text.replace(/<\/?[^>]+(>|$)/g, "");
    },
    truncateText(text, maxLength) {
      return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
    },
    async fetchRepData() {
      // Implement the logic to fetch representative data
      try {
      const params = {
        rowsPerPage: 1,
        currentPage: 1,
        id: this.userId
      };
      
      let response = await axios.get('https://123avatars.com/v2/rep-login', { params });
      if(response.status == 200) {
        this.username = response.data.username;
        this.currentSalesRepName = response.data.sales_rep;
        this.userName = response.data.sales_rep;
        this.managerId = response.data.manager_sub;
        this.managerName = response.data.manager_name;
        this.managerDid = response.data.manager_did;
        this.credits = response.data.credits;
        this.companyId = response.data.user_id;
        this.signupLink = response.data.signupLink;
      } else {
        console.error("Error fetching user: ", response.status, response.data);
      }

      } catch (err) {
      console.error("Error fetching user: ", err);
      }
      // Example: const response = await axios.get('/api/rep-data');
      // this.repData = response.data;
    }
  },
  async mounted() {
    const savedRowsPerPage = localStorage.getItem('rowsPerPage');
    if (savedRowsPerPage) {
      this.rowsPerPage = parseInt(savedRowsPerPage, 10);
    }

    await this.initUser();
    await this.fetchRepData(); 
    await this.fetchMessages();
    // await this.fetUsersInInbox();
  }
};
</script>

<style scoped lang="scss">
.prompt-input {
  width: 100%;
  resize: none;
  border-radius: 4px;
  padding: 8px;
  box-sizing: border-box;
  line-height: 1.5;
}

.circle {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.call-button-right-aligned {
  display: flex;
  justify-content: flex-end;
}

.tag-item,
.tag-itemoperators,
.tag-itemadmins,
.tag-itemmanagers {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  cursor: pointer;
  color: #FFF;
}

.tag-itemoperators {
  background-color: #46A57C;
}

.tag-itemadmins {
  background-color: #A73A18;
}

.tag-itemmanagers {
  background-color: #2653C7;
}

.tag-text {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 16px;
  text-align: left;
  /* Space between tag and count */
}

.tag-count {
  flex-shrink: 0;
  /* Prevents the count from shrinking */
}

/* Optional: Add hover effect */
.tag-item:hover,
.tag-itemadmins:hover,
.tag-itemmanagers:hover,
.tag-itemoperators:hover {
  font-weight: bold;
}

.remove-selection {
  border-color: red !important;
}

.remove-selection svg {

  fill: red !important;
}

.search-section {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.search-input {
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  outline: none;
  width: 300px;
}

.search-button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.tags-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.tags-list {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.tag-input-container {
  margin-top: 0.5rem;
}

.tag-input {
  padding: 0.25rem 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 12px;
  width: 100%;
  height: 24px;
}

.tag-input:focus {
  outline: none;
  border-color: #46A57C;
  box-shadow: 0 0 0 2px rgba(70, 165, 124, 0.2);
}

.tag-badge {
  display: inline-flex;
  align-items: center;
  padding: 0.35em 0.65em;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  background-color: #46A57C !important;
  color: white;
}

.tag-badgeadmins {
  background-color: #A73A18 !important;
}

.tag-badgeroaming_closer {
  background-color: #0B7D8A !important;
}

.tag-badgemanagers {
  background-color: #2653C7 !important;
}

.tag-badgeoperators {
  background-color: #46A57C !important;
}

.badge-close-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.5rem;
  padding: 0;
  font-size: 1.25em;
  font-weight: 700;
  line-height: 1;
  color: white;
  background: none;
  border: none;
  opacity: 0.75;
  cursor: pointer;
}

.badge-close-btn:hover {
  opacity: 1;
}

.inbox-table {
  &.inbox-table tr {
    td,
    th {
      &:first-child {
        padding-left: 25px;
      }

      &:last-child {
        padding-right: 25px;
      }
    }
  }

  &.inbox-table tbody tr {
    transition: background 0.35s;

    &:hover {
      cursor: pointer;
    }
  }

  &.inbox-table {
    tbody tr.unread {
      font-weight: 700;
    }

    &.inbox-tableadmins tbody tr.unread {
      background-color: rgba(#A73A18, 0.15);
    }

    &.inbox-tableroaming_closer tbody tr.unread {
      background-color: rgba(#0B7D8A, 0.15);
    }

    &.inbox-tablemanagers tbody tr.unread {
      background-color: rgba(#2653C7, 0.15);
    }

    &.inbox-tableoperators tbody tr.unread {
      background-color: rgba(#46A57C, 0.15);
    }
  }

  &.inbox-tableadmins tbody tr {
    &:hover {
      background-color: rgba(#A73A18, 0.3) !important;
    }
  }

  &.inbox-tableroaming_closer tbody tr {
    &:hover {
      background-color: rgba(#0B7D8A, 0.3) !important;
    }
  }

  &.inbox-tablemanagers tbody tr {
    &:hover {
      background-color: rgba(#2653C7, 0.3) !important;
    }
  }

  &.inbox-tableoperators tbody tr {
    &:hover {
      background-color: rgba(#46A57C, 0.3) !important;
    }
  }
}

.search-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.search-box, .search-boxadmins, .search-boxmanagers, .search-boxroaming_closer, .search-boxoperators {
  position: relative;
  width: 475px;
}

.search-box input,  .search-boxadmins input, .search-boxmanagers input, .search-boxroaming_closer input, .search-boxoperators input {
  width: 100%;
  padding: 8px 40px 8px 16px;
  border-radius: 8px;
}

.search-box input {
  border: 2px solid #46A57C !important;
}

.search-boxadmins input {
  border: 2px solid #A73A18 !important;
}

.search-boxmanagers input {
  border: 2px solid #2653C7 !important;
}

.search-boxroaming_closer input {
  border: 2px solid #0B7D8A !important;
}

.search-boxoperators input {
  border: 2px solid #46A57C !important;
}

.search-box {
  border-color: #46A57C !important;
}

.search-boxadmins {
  border-color: #A73A18 !important;
}

.search-boxmanagers {
  border-color: #2653C7 !important;
}

.search-boxroaming_closer {
  border-color: #0B7D8A !important;
}

.search-boxoperators {
  border-color: #46A57C !important;
}

.search-box input:focus {
  outline: none;
  border: 2px solid #46A57C !important;
}

.search-boxmanagers input:focus {
  outline: none;
  border: 2px solid #2653C7 !important;
}

.search-boxroaming_closer input:focus {
  outline: none;
  border: 2px solid #0B7D8A !important;
}

.search-boxoperators input:focus {
  outline: none;
  border: 2px solid #46A57C !important;
}

.search-boxadmins input:focus {
  outline: none;
  border: 2px solid #A73A18 !important;
}

.search-icon, .search-iconadmins, .search-iconmanagers, .search-iconroaming_closer, .search-iconoperators {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  font-size: 16px;
  padding: 0 12px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  align-items: center;
  border-style: none;
}

.search-icon {
  background-color: #46A57C !important;
  border-color: #46A57C !important;
}

.search-iconadmins {
  background-color: #A73A18 !important;
  border-color: #A73A18 !important;
}

.search-iconmanagers {
  background-color: #2653C7 !important;
  border-color: #2653C7 !important;
}

.search-iconroaming_closer {
  background-color: #0B7D8A !important;
  border-color: #0B7D8A !important;
}

.search-iconoperators {
  background-color: #46A57C !important;
  border-color: #46A57C !important;
}
</style>