<template>
  <div id="reports">
    <UIBox>
      <template v-slot:title >
        <!-- Youtube embedded video -->
        <button class="btn play-video-button custom-pl-2" @click="showYouTubePopup = true">
            <i class="fa fa-play"></i>
          </button>
        <YouTubePopup :videoId="youtubeVideoId" :show.sync="showYouTubePopup" @update:show="showYouTubePopup = $event" />
        <!--/-->
        <span class="bold b-center">
          <span class="font-bold">
            Calls Report
          </span>
          Total Leads: {{ totalRows }}
        </span>
      </template>
      <div class="d-flex justify-content-between align-items-center mb-3">
        <div>
          <div class="d-flex flex-column">
            <div id="customGPT"></div>
          </div>
        </div>
      </div>
      <div v-if="!dialerActive" class="w-full">
        <div class="default-according style-1">
          <div no-body class="mb-1 accordion-toggler">
            <div header-tag="div" class="shadow-none">
              <h5 class="mb-0">
                <div v-b-toggle.primary_icon_open_close_1 :style="{ borderColor: roleColor() }"
                  class="border-b-2 mb-3 pb-3">
                  <span class="text-black font-bold text-1xl">Filter By</span>
                </div>
              </h5>
            </div>
            <div id="primary_icon_open_close_1" accordion="my-accordion-icon-open-close" role="tabpanel">
              <div>
                <b-card-text>
                  <div class="row mb-2">
                    <div class="col-auto mb-2">
                      <label for="dateFilter" class="font-bold">Date</label>
                      <div class="d-flex align-items-end gap-2">
                        <select 
                          id="dateFilter" 
                          v-model="dateFilter" 
                          class="form-control rounded-[10px] border-2 select"
                          :style="{ borderColor: roleColor(), width: '200px' }"
                        >
                          <option value="All">All</option>
                          <option value="Today">Today</option>
                          <option value="Yesterday">Yesterday</option>
                          <option value="This Week">This Week</option>
                          <option value="Last Week">Last Week</option>
                          <option value="This Month">This Month</option>
                          <option value="Last Month">Last Month</option>
                          <option value="Last 7 Days">Last 7 Days</option>
                          <option value="Last 30 Days">Last 30 Days</option>
                          <option value="Last 90 Days">Last 90 Days</option>
                          <option value="YTD">YTD</option>
                          <option value="Custom">Custom</option>
                        </select>
                        
                        <div v-if="dateFilter === 'Custom'" class="d-inline-flex gap-2">                        
                          <DatePicker 
                            v-model="customStartDate" 
                            placeholder="Start Date"
                            style="width: 130px;" 
                          />
                          <DatePicker 
                            v-model="customEndDate" 
                            placeholder="End Date"
                            style="width: 130px;"
                          />
                          <button 
                            @click="fetchAllLeads()" 
                            class="btn solid"
                            style="margin: 0px;"
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </div>

                    <div class="col-auto mb-2">
                      <label for="timezoneFilter " class="font-bold">Timezone</label>
                      <select id="timezoneFilter" v-model="timezoneFilter" class="form-control rounded-[10px] border-2"
                        :style="{ borderColor: roleColor() }">
                        <option value="All" selected>All</option>
                        <option value="PST">PST</option>
                        <option value="MST">MST</option>
                        <option value="CST">CST</option>
                        <option value="EST">EST</option>
                        <option value="AKST">AKST</option>
                        <option value="HST">HST</option>
                      </select>
                    </div>
                    <div class="col-auto mb-2">
                      <label for="typeFilter " class="font-bold">Call Length</label>
                      <select id="typeFilter" v-model="durationFilter" class="form-control rounded-[10px] border-2"
                        :style="{ borderColor: roleColor() }" @change="fetchAllLeads">
                        <option value="All" selected>All</option>
                        <option :value="1">1+ Min</option>
                        <option :value="2">2+ Min</option>
                        <option :value="5">5+ Min</option>
                        <option :value="10">10+ Min</option>
                        <option :value="15">15+ Min</option>
                        <option :value="20">20+ Min</option>
                        <option :value="30">30+ Min</option>
                      </select>
                    </div>
                  </div>
                  <!-- <div class="row mb-2">
                    <div class="col-md-2 mb-2">
                      <label for="dateFilter" class="font-bold">Date</label>
                      <select id="dateFilter" v-model="dateFilter" class="form-control rounded-[10px] border-2 select"
                        :style="{ borderColor: roleColor() }">
                        <option value="All">All</option>
                        <option value="Today">Today</option>
                        <option value="Yesterday">Yesterday</option>
                        <option value="This Week">This Week</option>
                        <option value="Last Week">Last Week</option>
                        <option value="This Month">This Month</option>
                        <option value="Last Month">Last Month</option>
                        <option value="Last 7 Days">Last 7 Days</option>
                        <option value="Last 30 Days">Last 30 Days</option>
                        <option value="Last 90 Days">Last 90 Days</option>
                        <option value="YTD">YTD</option>
                        <option value="Custom">Custom</option>
                        <option value="Custom">Custom</option>
                      </select>
                      <div class="d-flex mt-2" v-if="dateFilter === 'Custom'">
                        <DatePicker v-model="customStartDate" placeholder="Start Date"
                          class="form-control form-control-sm mr-2" />
                        <DatePicker v-model="customEndDate" placeholder="End Date"
                          class="form-control form-control-sm mr-2" />
                        <button @click="fetchLeads" class="btn btn-success btn-sm">
                          Apply
                        </button>
                      </div>
                    </div>
                    <div class="col-md-1 mb-2">
                      <label for="timezoneFilter " class="font-bold">Timezone</label>
                      <select id="timezoneFilter" v-model="timezoneFilter" class="form-control rounded-[10px] border-2"
                        :style="{ borderColor: roleColor() }">
                        <option value="All" selected>All</option>
                        <option value="PST">PST</option>
                        <option value="MST">MST</option>
                        <option value="CST">CST</option>
                        <option value="EST">EST</option>
                        <option value="AKST">AKST</option>
                        <option value="HST">HST</option>
                      </select>
                    </div>
                    <div class="col-md-2 mb-2">
                      <label for="typeFilter " class="font-bold">Call Length</label>
                      <select id="typeFilter" v-model="durationFilter" class="form-control rounded-[10px] border-2"
                        :style="{ borderColor: roleColor() }" @change="fetchAllLeads">
                        <option value="All" selected>All</option>
                        <option :value="2">2+ Min</option>
                        <option :value="5">5+ Min</option>
                        <option :value="10">10+ Min</option>
                        <option :value="15">15+ Min</option>
                        <option :value="20">20+ Min</option>
                        <option :value="30">30+ Min</option>
                      </select>
                    </div>
                  </div> -->
                  <div class="row mb-2">
                    <div class="col-md-3 mb-2" v-if="role === 'Admins'">
                      <label for="managerFilter" class="font-bold">Managers</label>
                      <div class="relative">
                        <input type="text" id="managerFilter" v-model="managerSearch" @input="searchManagers"
                          class="form-control rounded-[10px] border-2" :style="{ borderColor: roleColor() }"
                          placeholder="Search managers..." autocomplete="off" />
                        <div v-if="showDropdown && filteredManagers.length"
                          class="absolute z-10 w-full mt-1 bg-white border rounded-lg shadow-lg max-h-60 overflow-y-auto">
                          <div class="p-2 hover:bg-gray-100 cursor-pointer" @click="selectManager('all')">
                            All
                          </div>
                          <div v-for="manager in filteredManagers" :key="manager.id"
                            class="p-2 hover:bg-gray-100 cursor-pointer" @click="selectManager(manager)">
                            {{ manager.firstName }} {{ manager.lastName }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3 mb-2" v-if="role === 'Admins' || role === 'Managers'">
                      <label for="operatorFilter" class="font-bold">Sales Reps / Operators</label>
                      <div class="relative">
                        <input type="text" id="operatorFilter" v-model="operatorSearch" @input="searchOperators"
                          class="form-control rounded-[10px] border-2" :style="{ borderColor: roleColor() }"
                          placeholder="Search operators..." />
                        <div v-if="showOperatorDropdown && filteredOperators.length"
                          class="absolute z-10 w-full mt-1 bg-white border rounded-lg shadow-lg max-h-60 overflow-y-auto">
                          <div class="p-2 hover:bg-gray-100 cursor-pointer" @click="selectOperator('')">
                            All
                          </div>
                          <div v-for="operator in filteredOperators" :key="operator.id"
                            class="p-2 hover:bg-gray-100 cursor-pointer" @click="selectOperator(operator)">
                            {{ operator.firstName }} {{ operator.lastName }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4 mb-2">
                      <label class="font-bold">Search</label>
                      <input type="text" v-model="searchQuery" class="form-control rounded-[10px] border-2"
                        placeholder="Company Name, Phone Number, tags..." :style="{ borderColor: roleColor() }"
                        @change="fetchAllLeads()" />
                    </div>
                    <div class="col-md-4 mb-2">
                      <label for="toFilter" class="font-bold">Call Status</label>
                      <select id="toFilter" v-model="toFilter" class="form-control rounded-[10px] border-2"
                        :style="{ borderColor: roleColor() }">
                        <option value="All">All</option>
                        <option value="Missed">Missed TOs</option>
                        <option value="Paid">Paid TOs</option>
                        <option value="Finished Scripts">Finished Scripts</option>
                        <option value="Finished Scripts Asked All Questions">Finished Scripts Asked All Questions</option>
                        <option value="Barges">Barges</option>
                      </select>
                    </div>
                    <!-- <div class="col-m-4 mb-2" style="padding-top: 35px;">
                      <td @click="toggleFilterIsAi()" style="float: left; position: relative;">
                        <CheckedSVG v-if="filterIsAi" :size="24" class="checkbox-checked" :fill="roleColor()" :stroke="roleColor()" />
                        <UncheckedSVG v-else :size="24" class="checkbox-unchecked" fill="none" :stroke="roleColor()" />
                      </td>
                      <label for="toFilter" class="font-bold" style="float: left; position: relative; margin-left: 5px; margin-top: 2px;">Exclude VoiceMail</label>
                    </div> -->
                    <div class="col-m-4 mb-2" style="padding-top: 35px;">
                      <td @click="toggleFilterIsVoicemail()" style="float: left; position: relative;">
                        <CheckedSVG v-if="isVoicemail" :size="24" class="checkbox-checked" :fill="roleColor()" :stroke="roleColor()" />
                        <UncheckedSVG v-else :size="24" class="checkbox-unchecked" fill="none" :stroke="roleColor()" />
                      </td>
                      <label for="toFilter" class="font-bold" style="float: left; position: relative; margin-left: 5px; margin-top: 2px;">Is Voicemail</label>
                    </div>
                    <div class="col-m-4 mb-2" style="padding-top: 35px;">
                      <td @click="toggleFilterIsTransfer()" style="float: left; position: relative;">
                        <CheckedSVG v-if="isTransfer" :size="24" class="checkbox-checked" :fill="roleColor()" :stroke="roleColor()" />
                        <UncheckedSVG v-else :size="24" class="checkbox-unchecked" fill="none" :stroke="roleColor()" />
                      </td>
                      <label for="toFilter" class="font-bold" style="float: left; position: relative; margin-left: 5px; margin-top: 2px;">Is Transfer</label>
                    </div>
                  </div>
                </b-card-text>
              </div>
            </div>

            <!-- <div class="actions flex mb-3 flex-wrap" role="group">
                    <button
                      v-for="milestone in [...new Set(milestones)]"
                      @click="setActiveMilestone(milestone)"
                      :class="[
                        milestone === activeMilestone ? 'bg-primeGreen text-white' : '',
                        `mt-0 transition-all hover:bg-primeGreen hover:text-white text-primeGreen px-4 py-1 border-2 rounded-[5px] border-[${roleColor()}] w-fit flex items-center justify-between mr-2`
                      ]"
                    >
                      {{ milestone }} {{ milestone === 'All' ? allLeads?.leads?.length : countItems(milestone) }}
                    </button>
                    <button v-if="activeMilestone !== 'All' && activeMilestone" class="mr-2" @click="removeMilestone(activeMilestone)">
                      <img src="@/assets/icons/delete-btn.svg" />
                    </button> 
                    <div class="flex items-center">
                      <button
                        @click="addingNew = true"
                        v-if="!addingNew"
                        :class="`transition-all hover:bg-primeGreen hover:text-white text-primeGreen px-4 py-1 border-2 rounded-[5px] border-[${roleColor()}] w-fit flex items-center justify-between mr-2`"
                      >
                        + More
                      </button>
                      <div v-else class="flex items-center">
                        <div class="w-[100px] mr-2">
                          <input
                            type="text"
                            v-model="newMilestone"
                            class="form-control form-control-sm"
                            placeholder="title"
                          />
                        </div>
                        <div class="flex items-center gap-1">
                          <button
                            :class="`mt-1 transition-all hover:bg-primeGreen hover:text-white text-primeGreen px-3 py-1 border-2 rounded-[10px] border-[${roleColor()}] w-fit flex items-center justify-between`"
                            @click="addNewMilestone()"
                          >
                            +
                          </button>
                          <button
                            class="mt-1 transition-all hover:bg-red-600 hover:text-white text-red-600 px-3 py-1 border-2 rounded-[10px] border-red-600 w-fit flex items-center justify-between"
                            @click="addingNew = false"
                          >
                            -
                          </button>
                        </div>
                      </div>
                    </div>
                  </div> -->
          </div>
        </div>
      </div>
      <div class="w-full d-flex justify-content-end" v-if="!loading">
        <button 
          class="btn solid" 
          :class="{ 'solid': !isAutoPlaying, 'btn-danger': isAutoPlaying }"
          @click="toggleAutoPlay"
        >
          {{ isAutoPlaying ? 'Stop Auto Play' : 'Auto Play' }}
        </button>
      </div>
      <div class="table-responsive">
        <div v-if="loading" class="w-full h-[250px] flex items-center justify-center font-bold text-xl text-center">
          Fetching data...
        </div>
        <table v-else class="table table-bordered table-hover text-center" :loading="true">
          <thead>
            <!-- Last Call , Time/TZ , Call Length, Play, Owner, Company Name, State -->
            <tr>
              <!-- <th class="bg-none text-black">Pipeline</th> 
              <td></td> -->
              <th class="text-black no-top-padding" @click="sortTable('last_call_date')">Manager</th>
              <th class="text-black no-top-padding" @click="sortTable('last_call_date')">Sales Operator</th>
              <th class="text-black no-top-padding" @click="sortTable('last_call_date')">Date</th>
              <th class="text-black no-top-padding" @click="sortTable('last_call_time')">Time</th>
              <th class="text-black no-top-padding" @click="sortTable('last_call_time')">Timezone</th>
              <th class="text-black no-top-padding" @click="sortTable('last_call_length')">Call Length</th>
              <th class="text-black no-top-padding">Play</th>
              <th class="text-black no-top-padding" @click="sortTable('company_name')">Company Name</th>
              <th class="text-black no-top-padding" @click="sortTable('state')">State</th>
              <th class="text-black no-top-padding" @click="sortTable('shelf_life')">Age of Lead</th>
              <th class="text-black no-top-padding" @click="sortTable('total_talk_time')">Total Talk Time</th>
              <th class="text-black no-top-padding" @click="sortTable('stacallste')">Calls</th>
              <th class="text-black no-top-padding" @click="sortTable('texts')">Text</th>
              <th class="text-black no-top-padding" @click="sortTable('email')">Emails</th>
              <th class="text-black no-top-padding" @click="sortTable('tags')">Tags</th>
              <th class="text-black no-top-padding" @click="sortTable('phone')">Phone</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in allLeads?.leads" :key="index"
              :class="index == 0 && dialerActive ? 'current-call' : ''">
              <!--
                last_call_date,
                datetime,
                duration,
                play - empty
                company_name,
                state, -->
              <!-- <td>
                  <select
                    id="toFilter"
                    v-model="row.selectedMilestone"
                    class="form-control rounded-[10px] border-2 border-primeGreen"
                    @change="milestones.push(row.selectedMilestone)"
                  >
                    <option :value="row.milestone">{{ row.milestone }}</option>
                  </select>
                </td>
              <td>
                <button>
                  <img src="@/assets/icons/check_menu.svg" alt="">
                </button>
              </td> -->

              <td class="whitespace-nowrap">
                {{ row.managerName }}
              </td>
              <td class="whitespace-nowrap">
                {{ row.sales_rep }}
              </td>
              <td class="whitespace-nowrap">
                {{
                  convertToLocalTime(row.start_time)
                  | dateFormat("MM.DD.YYYY")
                }}
              </td>
              <td class="whitespace-nowrap">
                {{ convertToLocalTimeFormatted(row.start_time) }}
              </td>
              <td class="whitespace-nowrap">
                {{ row.timezone }}
              </td>
              <td class="whitespace-nowrap">
                {{ convertSecondsToMinutes(row.duration) }}</td>
              <td>
                <!-- <button class="play-button" @click="toggleAudio(row)">
                  <img v-if="playingCallId !== row.id" src="@/assets/icons/play_button.svg"/>
                  <span v-else>Pause</span>
                </button> -->
                <VueAudioPlayer ref="audioPlayer" 
                  :ref="`player${index}`"
                  :audio-list="[{ src: row.recordingUrl }]"
                  :before-play="handleBeforePlay" :show-play-loading="false" :theme-color="`${roleColor()}`"
                  :isLoop="false"
                  @ended="onPlayerEnded(index)"
                >
                </VueAudioPlayer>
              </td>
              <td class="whitespace-nowrap">{{ row.company_name }}</td>
              <td class="whitespace-nowrap">{{ row.state }}</td>
              <td>{{ row.shelf_life }}</td>
              <td class="whitespace-nowrap">{{ convertSecondsToMinutes(row.total_talk_time) }}</td>
              <td class="whitespace-nowrap">{{ row.calls }}</td>
              <td class="whitespace-nowrap">{{ row.texts }}</td>
              <td class="whitespace-nowrap">{{ row.email }}</td>
              <td style="min-width: 200px; width: 200px;">
                <div class="tags-container">
                  <!-- Existing tags -->
                  <div class="tags-list">
                    <span v-for="(tag, index) in parseTags(row.tags)" :key="index" :class="{
                      badge: true,
                      [`tag-badge${role ? role?.toLowerCase() : ''}`]: true
                    }">
                      {{ tag }}
                      <button v-if="editingRowId === row.id" class="badge-close-btn" @click="removeTag(row.id, tag)"
                        aria-label="Remove tag">
                        ×
                      </button>
                    </span>
                  </div>

                  <!-- Tag input field - separate from badges -->
                  <div v-if="editingRowId === row.id" class="tag-input-wrapper">
                    <input v-model="newTag" @keyup.enter="addTag(row.id)" type="text" class="tag-input"
                      :placeholder="`Add tag...`" />
                  </div>
                </div>
              </td>
              <td class="whitespace-nowrap">
                <button class="btn outline icon" style="display: inline-block;"
                  @click="callProspect(row.lead_id, row.phone)">
                  <PhoneSVG />
                </button><span style="display: inline-block;">{{ formatPhoneNumber(row.phone) }}</span>
              </td>
              <td>
                <button class="btn outline icon"
                  @click="editingRowId === row.id ? handleSave(row.id, row.sales_lead_id) : toggleEditRow(row, row.id)">
                  <template v-if="editingRowId === row.id">
                    <SaveSVG />
                  </template>
                  <template v-else>
                    <EditSVG />
                  </template>
                </button>
                <b-button type v-b-modal.callbackModal class="btn solid" @click="openEditCallback(row)" style="text-wrap: nowrap">
                  Set Callback
                </b-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Bottom Input -->
      <div class="bottom-input">

        <div class="per-page">
          <div class="select">
            Per Page
            <select class="per-page-drop" id="rowsPerPage" v-model="rowsPerPage" @change="fetchAllLeads">
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="500">500</option>
            </select>
          </div>
        </div>

        <template>
          <div class="pagin">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="rowsPerPage"
              aria-controls="callbackstable">
              <template #first-text>
                <PaginleftdoubleSVG />
              </template>
              <template #prev-text>
                <PaginleftSVG />
              </template>
              <template #next-text>
                <PaginrightSVG />
              </template>
              <template #last-text>
                <PaginrightdoubleSVG />
              </template>
            </b-pagination>
          </div>
        </template>

      </div>

      <div v-if="showOverlay" class="overlay" @click="closePopup"></div>
      <div v-if="showNotesPopup" class="modal fade show d-block" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">{{ notesCompanyName }}</h5>
              <button type="button" class="close" @click="closePopup">
                <span>&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <table class="table table-bordered text-center table-sm">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Time</th>
                    <th>Note</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(note, index) in currentNotes" :key="index">
                    <td>{{ note.date }}</td>
                    <td>{{ note.time }}</td>
                    <td>{{ note.text }}</td>
                    <td>
                      <button @click="deleteNote(index, note.id)" class="btn btn-danger btn-sm">
                        Delete
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <textarea v-model="newNoteText" placeholder="Add a new note"
                class="form-control mb-3 form-control-sm"></textarea>
            </div>
            <div class="modal-footer">
              <button @click="saveNote" class="btn btn-success btn-sm">
                Save
              </button>
              <button @click="closePopup" class="btn btn-secondary btn-sm">
                Close
              </button>
            </div>
          </div>
        </div>
      </div>

      <div v-if="showMessagesPopup" class="modal show d-block" tabindex="-1" style="width: 70%; height: 50%">
        <div v-if="showMessagesPopup" class="modal show d-block" tabindex="-1"
          style="display: flex; justify-content: center; align-items: center">
          <div class="modal-dialog" style="width: 70%; height: 50%">
            <div class="modal-content rounded-container" v-if="messages">
              <div class="modal-header">
                <h2 class="modal-title">Messages</h2>
                <button type="button" class="btn-close" aria-label="Close" @click="closeMessagesPopup"></button>
              </div>
              <div class="modal-body container-body">
                <div id="convo" class="conversation">
                  <div v-for="message in messages" :key="message.id" :class="{
                    'bubble-row right': isFromSalesRep(message),
                    'bubble-row left': !isFromSalesRep(message),
                  }">
                    <div class="bubble-container">
                      <div class="bubble">
                        <span>{{ message.text }}</span>
                        <svg class="tail" width="27" height="25" viewBox="0 0 27 25" fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path v-if="isFromSalesRep(message)" d="M26.5 24.5L12 0.5L0.5 15.5L26.5 24.5Z"
                            fill="#F0F0F0" />
                          <path v-else d="M0 24.5L14.5 0.5L26 15.5L0 24.5Z" fill="#579AFF" />
                        </svg>
                      </div>
                      <div class="subtitle bold">
                        {{ getSenderName(message) }}
                      </div>
                      <div class="subtitle">{{ formatDate(message.time) }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <!-- Optional footer content -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </UIBox>
    <Callbacks :type="'EDIT'" :prospect="company" :userId="userId" v-if="company" @callbacks-closed="handleCallbacksClosed" />
  </div>
</template>

<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { TelnyxDevice } from "@telnyx/rtc-sipjs";
import AuthService from "../../_services/AuthService.js";
import UIBox from "@/components/uibox.vue";
import PaginleftdoubleSVG from "../../components/SVG-icons/paginleftdoubleSVG.vue";
import PaginleftSVG from "../../components/SVG-icons/paginleftSVG.vue";
import PaginrightSVG from "../../components/SVG-icons/paginrightSVG.vue";
import PaginrightdoubleSVG from "../../components/SVG-icons/paginrightdoubleSVG.vue";
import CloseSVG from "../../components/SVG-icons/closeSVG.vue";
import PhoneSVG from "../../components/SVG-icons/phoneSVG.vue";
import EditSVG from '../../components/SVG-icons/editSVG.vue';
import SaveSVG from '../../components/SVG-icons/saveSVG.vue';
import VueAudioPlayer from '@liripeng/vue-audio-player';
import CheckedSVG from '../../components/SVG-icons/checkedSVG.vue';
import UncheckedSVG from '../../components/SVG-icons/unCheckedSVG.vue';
import Callbacks from "./Callbacks.vue";
import YouTubePopup from "../../components/YouTubePopup.vue";

export default {
  components: {
    DatePicker,
    UIBox,
    UIBox,
    PaginleftdoubleSVG,
    PaginleftSVG,
    PaginrightdoubleSVG,
    PaginrightSVG,
    CloseSVG,
    PhoneSVG,
    EditSVG,
    SaveSVG,
    CheckedSVG,
    UncheckedSVG,
    YouTubePopup,
    'Callbacks': Callbacks,
  },
  props: {
    salesRepId: String,
    toCallsFilter: String,
  },
  data() {
    return {
      timezone: '',
      currentPlayingIndex: -1,
      isAutoPlaying: false,
      managerSearch: '',
      showDropdown: false,
      filteredManagers: [],
      selectedManager: null,
      operatorSearch: '',
      showOperatorDropdown: false,
      filteredOperators: [],
      selectedOperator: null,
      role: '',
      pipeline: '',
      callStatusFilter: '',
      managersFilter: null,
      managers: [],
      operatorsFilter: null,
      operators: [],
      loading: false,
      activeMilestone: "All",
      allLeads: null,
      addingNew: false,
      newMilestone: "",
      milestones: ["All", "Interested", "Starred"],
      stats: {
        today: {
          talkTime: "",
          totalCalls: 0,
          inboundCalls: 0,
          outboundCalls: 0,
        },
        thisWeek: {
          talkTime: "",
          totalCalls: 0,
          inboundCalls: 0,
          outboundCalls: 0,
        },
        lastWeek: {
          talkTime: "",
          totalCalls: 0,
          inboundCalls: 0,
          outboundCalls: 0,
        },
        sales: {
          talkTime: 0,
          totalCalls: 0,
          inboundCalls: 0,
          outboundCalls: 0,
        },
      },
      searchQuery: "",
      dateFilter: "All",
      typeFilter: "All",
      milestoneFilter: "All",
      timezoneFilter: "All",
      salesRepFilter: "All",
      shelfLifeFilter: "All",
      hoursFilter: "Show Only During Hours",
      durationFilter: "All",
      toFilter: "All",
      customStartDate: "",
      customEndDate: "",
      rowsPerPage: 50,
      currentPage: 1,
      leads: [],
      totalRows: 0,
      sortDirection: "asc",
      showOverlay: false,
      showNotesPopup: false,
      showMessagesPopup: false,
      notesCompanyName: "",
      messagesCompanyName: "",
      currentNotes: [],
      newNoteText: "",
      notesData: {},
      filteredRows: [],
      currentLeadId: null,
      totalCount: 0,
      scriptLink: "",
      signupLink: "",
      qaLink: "",
      trainingLockerLink: "",
      dialerActive: false,
      dialerText: "Start Dialer",
      playingCallId: null,
      audio: null,
      outboundHuman: false,
      messages: [],
      tags: [],
      editingRowId: null,
      newTag: '',
      filterIsAi: false,
      company: null,
      isVoicemail: false,
      isTransfer: false,
      showYouTubePopup: false,
      youtubeVideoId: '8zpLpMfAO38' 
    };
  },
  computed: {
    visiblePages() {
      const pages = [];
      let startPage = Math.max(this.currentPage - 2, 1);
      let endPage = Math.min(startPage + 4, this.totalPages);

      if (endPage - startPage < 4) {
        startPage = Math.max(endPage - 4, 1);
      }

      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }

      return pages;
    },
    _filteredRows() {
      let filtered = this.leads.filter((lead) => {
        if (this.searchQuery && !this.matchSearchQuery(lead)) {
          return false;
        }
        if (this.typeFilter !== "All") {
          if (this.typeFilter == "Inbound All") {
            if (
              lead.type != "Inbound Caller ID Callbacks" &&
              lead.type != "Inbound Voicemail Callbacks"
            ) {
              return false;
            }
          } else if (lead.type !== this.typeFilter) {
            return false;
          }
        }
        if (
          this.milestoneFilter !== "All" &&
          lead.milestone !== this.milestoneFilter
        ) {
          return false;
        }
        if (
          this.timezoneFilter !== "All" &&
          lead.timezone !== this.timezoneFilter
        ) {
          return false;
        }
        if (
          this.salesRepFilter !== "All" &&
          lead.sales_rep !== this.salesRepFilter
        ) {
          return false;
        }
        console.log("uuid: ", lead.uuid);
        console.log("salesRepId: ", this.salesRepId);
        if (this.salesRepId && lead.uuid !== this.salesRepId) {
          return false;
        }
        if (
          this.shelfLifeFilter !== "All" &&
          !this.matchShelfLifeFilter(lead.shelf_life)
        ) {
          return false;
        }
        if (
          this.durationFilter !== "All" &&
          !this.matchDurationFilter(lead.duration)
        ) {
          return false;
        }
        // if (this.hoursFilter === 'Show Only During Hours' && !this.isDuringBusinessHours(lead.timezone)) {
        //   return false;
        // }
        if (
          this.hoursFilter === "Show After Hours" &&
          this.isDuringBusinessHours(lead.timezone)
        ) {
          return false;
        }

        if (this.toFilter !== "All" && !this.matchToFilter(lead)) {
          return false;
        }

        if (this.dateFilter == "All") {
          return true;
        } else {
          if (!this.matchDateFilter(lead.last_call_date)) {
            return false;
          }
        }
        return true;
      });
      this.totalRows = filtered.length;
      let sliced = filtered.slice(
        (this.currentPage - 1) * this.rowsPerPage,
        this.currentPage * this.rowsPerPage
      );
      this.filteredRows = filtered;
      return sliced;
    },
    totalPages() {
      return Math.ceil(this.totalCount / this.rowsPerPage);
    },
  },
  methods: {
    getTimezone() {
      // Get the local timezone
      const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      // List of US timezones
      const usTimezones = [
      'America/New_York',    // EST/EDT
      'America/Chicago',     // CST/CDT
      'America/Denver',      // MST/MDT
      'America/Phoenix',     // MST (no DST)
      'America/Los_Angeles', // PST/PDT
      'America/Anchorage',   // AKST/AKDT
      'America/Adak',        // HST/HDT
      'Pacific/Honolulu',    // HST (no DST)
      ];

      // Check if the local timezone is a US-based timezone
      if (usTimezones.includes(localTimezone)) {
      return localTimezone;
      }

      // If not a US timezone, return PST/PDT
      return 'America/Los_Angeles';
    },
    toggleFilterIsAi() {
      this.stopAutoPlay();
      this.filterIsAi = !this.filterIsAi;
    },
    toggleFilterIsVoicemail() {
      this.isVoicemail = !this.isVoicemail;
    },  
    toggleFilterIsTransfer() {
      this.isTransfer = !this.isTransfer;
    },  
    toggleAutoPlay() {
      if (this.isAutoPlaying) {
        this.stopAutoPlay();
      } else {
        this.startAutoPlay();
      }
    },

    startAutoPlay() {
      this.isAutoPlaying = true;
      this.currentPlayingIndex = -1;
      this.playNext();
    },

    stopAutoPlay() {
      this.isAutoPlaying = false;
      this.currentPlayingIndex = -1;
      // Stop current playing audio/video
      if (this.$refs[`player${this.currentPlayingIndex}`]) {
        this.$refs[`player${this.currentPlayingIndex}`][0].pause();
      }
    },

    playNext() {
      if (!this.isAutoPlaying) return;

      try {
        // Stop current player if exists
        if (this.currentPlayingIndex >= 0 && this.$refs[`player${this.currentPlayingIndex}`]) {
          try {
            this.$refs[`player${this.currentPlayingIndex}`][0].pause();
          } catch (error) {
          }
        }

        // Move to next item
        this.currentPlayingIndex++;

        // Check if we've reached the end
        if (this.currentPlayingIndex >= this.allLeads.leads.length) {
          this.stopAutoPlay();
          return;
        }

        // Get the player for this index
        const player = this.$refs[`player${this.currentPlayingIndex}`][0];
        if (player) {
          // Add ended event listener
          //player.addEventListener('ended', this.playNext);
          // Start playing
          
          if (player.duration > 0) {
            player.play();
          } else {
            this.playNext();    
          }
        }
      } catch (error) {
        this.playNext();
      }
    },

    // Add this to your existing player component/element
    onPlayerEnded(index) {
      if (this.isAutoPlaying && this.currentPlayingIndex === index) {
        this.playNext();
      }
    },
    async handleSave(rowId, salesLeadId) {
      try {
        const row = this.allLeads.leads.find(row => row.id === rowId);
        if (!row) return;

        const currentTags = this.parseTags(row.tags);
        const updatedTags = JSON.stringify([...currentTags, this.newTag.trim()]);

        let target = 'https://123avatars.com/v2/update-tags';
        await axios.post(target, {
          id: salesLeadId,
          tags: updatedTags
        });
        this.editingRowId = null;
        this.newTag = '';
      } catch (error) {
        console.error('Error saving:', error);
      }
    },
    parseTags(tags) {
      // Handle string or array input
      if (typeof tags === 'string') {
        try {
          return JSON.parse(tags);
        } catch {
          return [];
        }
      }
      return Array.isArray(tags) ? tags : [];
    },
    openEditCallback(callback) {
      //this.company = this.mapCallbackData(callback);
      this.editingRowId = callback.id;
    },
    toggleEditRow(mainRow, rowId) {
      this.editingRowId = this.editingRowId === rowId ? null : rowId;
      this.newTag = ''; // Clear the input when toggling edit mode
      
      // Check if tags exists and is not empty before using find
      if (mainRow?.tags && mainRow.tags.length > 0) {
        this.openEditCallback(mainRow.tags.find(row => row?.id === rowId));
      }
    },
    removeTag(rowId, tagToRemove) {
      const row = this.allLeads.leads.find(row => row.id === rowId);
      if (row) {
        const currentTags = this.parseTags(row.tags);
        const updatedTags = currentTags.filter(tag => tag !== tagToRemove);
        row.tags = JSON.stringify(updatedTags);
      }
    },
    addTag(rowId) {
      if (!this.newTag.trim()) return; // Don't add empty tags
      
      const row = this.allLeads.leads.find(row => row.id === rowId);
      if (row) {
        const currentTags = this.parseTags(row.tags);
        if (currentTags.includes(this.newTag.trim())) {
          return;
        }

        const updatedTags = [...currentTags, this.newTag.trim()];
        row.tags = JSON.stringify(updatedTags);
        this.newTag = '';
      }
    },
    handleBeforePlay(next) {
      next();
    },
    searchOperators() {
      if (!this.operatorSearch) {
        this.filteredOperators = this.operators;
      } else {
        this.filteredOperators = this.operators.filter(operator => {
          const fullName = `${operator.firstName} ${operator.lastName}`?.toLowerCase();
          return fullName.includes(this.operatorSearch?.toLowerCase());
        });
      }
      this.showOperatorDropdown = true;
    },

    selectOperator(operator) {
      if (operator === '') {
        this.selectedOperator = null;
        this.operatorSearch = 'All';
      } else {
        this.selectedOperator = operator;
        this.operatorSearch = `${operator.firstName} ${operator.lastName}`;
      }
      this.showOperatorDropdown = false;
      this.operatorsFilter = operator === '' ? '' : operator;
    },
    searchManagers() {
      if (!this.managerSearch) {
        this.filteredManagers = this.managers;
      } else {
        this.filteredManagers = this.managers.filter(manager => {
          const fullName = `${manager.lastName} ${manager.firstName}`?.toLowerCase();
          return fullName.includes(this.managerSearch?.toLowerCase());
        });
      }
      this.showDropdown = true;
    },

    selectManager(manager) {
      if (manager === 'all') {
        this.selectedManager = null;
        this.managerSearch = 'All';
      } else {
        this.selectedManager = manager;
        this.managerSearch = `${manager.firstName} ${manager.lastName}`;
      }
      this.showDropdown = false;
      this.managersFilter = manager === 'all' ? 'all' : manager;
      this.fetchAllLeads();
    },
    roleColor() {
      const colors = {
        admins: '#A73A18',
        managers: '#2653C7',
        operators: '#46A57C',
        roaming_closer: '#0B7D8A',
      };
      return colors[this.role?.toLowerCase()] || '#46A57C';
    },

    async fetchManagers() {
      const res = await axios.get('https://123avatars.com/v2/users?filter=Managers')
      this.managers = res.data
    },
    async fetchOperators() {

      const userId = localStorage.getItem('CognitoIdentityServiceProvider.459g3alqohqvrvrln2ccla7r0v.LastAuthUser')
      console.log('user id')
      console.log({ userId })
      console.log('current role is:', this.role)
      // Define the URL with optional managerId as a query parameter
      const url = this.role === 'Managers'
        ? `https://123avatars.com/v2/sales-reps?managerId=${userId}`
        : `https://123avatars.com/v2/sales-reps`;

      // Make the GET request to the endpoint with managerId if provided
      const operators = await axios.get(url);

      // Store the data and return sorted names
      this.operators = operators.data;
      return this.operators;
      //   setTimeout(async () => {
      // if (this.role === 'Admins' || this.role === 'Managers') {

      // }
      //   }, 500);
    },
    sortedNames(data) {
      return data.sort((a, b) => a.firstName?.toLowerCase().localeCompare(b.firstName?.toLowerCase()));
    },
    formatPhoneNumber(phoneNumber) {
      // Remove any non-numeric characters
      phoneNumber = phoneNumber.replace(/\D/g, '');

      // Format the cleaned number
      return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    },
    setActiveMilestone(milestone) {
      this.activeMilestone = milestone
      this.milestoneFilter = milestone
    },
    removeMilestone(milestone) {
      const index = this.milestones.indexOf(milestone);
      if (index > -1) {
        this.milestones.splice(index, 1);
      }
      this.activeMilestone = ''
    },
    countItems(milestone) {
      if (this.allLeads.length) {
        const leadsByMilestone = this.allLeads?.leads.map(
          (lead) => lead.milestone
        );

        const filteredMilestone = leadsByMilestone.filter((ms) => {
          return ms?.toLowerCase() === milestone?.toLowerCase();
        });

        return filteredMilestone.length;
      }
    },
    addNewMilestone() {
      this.milestones.push(this.newMilestone);

      this.addingNew = false;
      this.newMilestone = "";
    },
    callProspect(leadId, phone) {
      console.log("emit");
      this.$emit("open-call-prospect", {
        leadId: leadId,
        phone: phone,
        makeCall: true
      });
    },
    filteredLeadRows() {
      let filtered = this.leads.filter((lead) => {
        if (this.searchQuery && !this.matchSearchQuery(lead)) {
          return false;
        }
        if (this.typeFilter !== "All") {
          if (this.typeFilter == "Inbound All") {
            if (
              lead.type != "Inbound Caller ID Callbacks" &&
              lead.type != "Inbound Voicemail Callbacks"
            ) {
              return false;
            }
          } else if (lead.type !== this.typeFilter) {
            return false;
          }
        }
        if (
          this.milestoneFilter !== "All" &&
          lead.milestone !== this.milestoneFilter
        ) {
          return false;
        }
        if (
          this.timezoneFilter !== "All" &&
          lead.timezone !== this.timezoneFilter
        ) {
          return false;
        }
        if (
          this.salesRepFilter !== "All" &&
          lead.sales_rep !== this.salesRepFilter
        ) {
          return false;
        }
        if (this.salesRepId && lead.uuid !== this.salesRepId) {
          return false;
        }
        if (
          this.shelfLifeFilter !== "All" &&
          !this.matchShelfLifeFilter(lead.shelf_life)
        ) {
          return false;
        }
        if (
          this.durationFilter !== "All" &&
          !this.matchDurationFilter(lead.duration)
        ) {
          return false;
        }
        // if (this.hoursFilter === 'Show Only During Hours' && !this.isDuringBusinessHours(lead.timezone)) {
        //   return false;
        // }
        if (
          this.hoursFilter === "Show After Hours" &&
          this.isDuringBusinessHours(lead.timezone)
        ) {
          return false;
        }

        if (this.toFilter !== "All" && !this.matchToFilter(lead)) {
          return false;
        }

        if (this.dateFilter == "All") {
          return true;
        } else {
          if (!this.matchDateFilter(lead.last_call_date)) {
            return false;
          }
        }
        return true;
      });
      this.totalRows = filtered.length;
      let sliced = filtered.slice(
        (this.currentPage - 1) * this.rowsPerPage,
        this.currentPage * this.rowsPerPage
      );
      this.filteredRows = filtered;
      return sliced;
    },
    closeMessagesPopup() {
      this.showMessagesPopup = false;
    },
    isFromSalesRep(message) {
      return message.inbound === "0"; // Assuming inbound "0" means from sales rep
    },
    getSenderName(message) {
      return this.isFromSalesRep(message) ? "You" : "ABC Plumbing"; // Replace 'ABC Plumbing' with appropriate logic
    },
    formatDate(dateString) {
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      };
      return new Date(dateString).toLocaleDateString("en-US", options);
    },
    async loadExternalScript() {
      // let recaptchaScript = document.createElement('script')
      // recaptchaScript.setAttribute('src', 'https://cdn.customgpt.ai/js/chat.js')
      // document.head.appendChild(recaptchaScript)
      // recaptchaScript.onload = async () => {
      //   await CustomGPT.init({p_id: "36587", p_key: "876cdea81afe212c597fe461d006f0a2"});
      //   CustomGPT.hide();
      // }
    },
    convertToLocalTime(utcDateTime) {
      var local = new Date(utcDateTime);
      return local;
    },
    convertToLocalTimeFormatted(utcDateTime) {
      const local = new Date(utcDateTime);
      let hours = local.getHours();
      const minutes = local.getMinutes();
      const ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12;
      const minutesStr = minutes < 10 ? "0" + minutes : minutes;
      return `${hours}:${minutesStr} ${ampm}`;
    },
    async nextProspect() {
      try {
        let userId = "123009";
        let dialerId = this.$route.query.id;
        const response = await axios.post(
          "https://123avatars.com/v2/next-prospect",
          {
            user_id: userId,
            id: dialerId,
          }
        );
      } catch (err) {
        console.error("Error calling next prospect.", err);
      }
    },
    toggleAudio(row) {
      if (this.playingCallId === row.id) {
        this.audio.pause();
        if (this.audio) {
          try {
            this.audio.currentTime = 0;
          } catch (error) {}
          
        }
        this.audio = null;
        this.playingCallId = null;
      } else {
        if (this.audio) {
          this.audio.pause();
          if (this.audio) {
            try {
              this.audio.currentTime = 0;
            } catch (error) {}
          }
        }
        let url = row.recordingUrl;
        if (url) {
          this.audio = new Audio(url);
          this.audio.play();
          this.playingCallId = row.id;
          this.audio.onended = () => {
            this.audio = null;
            this.playingCallId = null;
          };
        } else {
          alert("No audio URL available");
        }
      }
    },
    convertSecondsToMinutes(seconds) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `${minutes} min${minutes !== 1 ? "s" : ""
        } ${remainingSeconds} sec${remainingSeconds !== 1 ? "s" : ""}`;
    },
    async startDialer() {
      try {
        if (this.dialerActive) {
          this.dialerActive = false;
          this.dialerText = "Start Dialer";
          let userId = "123009";
          let dialerId = this.$route.query.id;
          let timezone = this.getTimezone();
          const response = await axios.post(
            "https://123avatars.com/v2/logout-dialer",
            {
              user_id: userId,
              id: dialerId,
              timezone: timezone,
            }
          );
        } else {
          this.dialerText = "Stop Dialer";
          let userId = "123009";
          let dialerId = this.$route.query.id;
          let timezone = this.getTimezone();
          if (!userId) {
            throw new Error("User ID not found in localStorage");
          }
          const response = await axios.post(
            "https://123avatars.com/v2/login-dialer",
            {
              user_id: userId,
              id: dialerId,
              timezone: timezone,
            }
          );
          this.dialerActive = true;
          console.log("Login successful:", response.data);
        }
      } catch (error) {
        console.error("Error logging in to dialer:", error);
      }
    },
    getUserRoleFromToken() {
      // Get the JWT from local storage
      const user = localStorage.getItem("user");
      const data = JSON.parse(user)
      if (!data.token) {
        return null; // Return null if no token is found
      }
      // Decode the JWT (JWTs are Base64URL encoded)
      const payload = atob(data.token.split(".")[1]);
      // Check if the 'cognito:groups' claim exists in the payload
      const groups = JSON.parse(payload);
      if (!groups) {
        return null; // Return null if no groups are found
      }

      // Assuming the role is determined by the first group in the array
      return groups; // Or you can adjust this to match your application's logic
    },
    formatPhoneNumber(phoneNumber) {
      phoneNumber = phoneNumber.toString();
      const areaCode = phoneNumber.slice(0, 3);
      const centralOfficeCode = phoneNumber.slice(3, 6);
      const stationCode = phoneNumber.slice(6, 10);
      const formattedPhoneNumber = `(${areaCode}) ${centralOfficeCode}-${stationCode}`;
      return formattedPhoneNumber;
    },
    searchProspectCompany(company_name, state, phone) {
      const url = `https://www.google.com/search?q=${encodeURIComponent(
        this.formatPhoneNumber(phone)
      )}`;
      window.open(url, "_blank");
    },
    openSignUpPage() {
      window.open(this.signupLink, "_blank");
    },
    openScriptPage() {
      window.open(this.scriptLink, "_blank");
    },
    openQAPage() {
      window.open(this.qaLink, "_blank");
    },
    openTrainingLockerPage() {
      window.open(this.trainingLockerLink, "_blank");
    },
    getCognitoSub() {
      // Get the JWT from local storage
      const token = localStorage.getItem("user");

      if (!token) {
        return null; // Return null if no token is found
      }

      // Decode the JWT (JWTs are Base64URL encoded)
      const payload = JSON.parse(atob(token.split(".")[1]));

      // Return the 'sub' value from the payload
      return payload.sub || null;
    },
    async getTargetBasedOnRole(target) {
      //let target = 'https://123avatars.com/v2/sales-reps';
      let role = this.role;
      console.log({ role })
      if (role == "Admins" && this.$route.path == "/users") {
        //target += '?id=' + this.$route.query.id;
      }

      if (role == "Admins" && this.$route.path == "/manager-dashboard") {
        target += "?managerId=" + this.$route.query.id;
      }
      if (role == "Managers") {
        target += "?managerId=" + (await AuthService.getCognitoSub());
      }
    },
    async fetchAllLeads() {     
      const role = this.role;
      this.loading = true
      const params = {
        search: this.searchQuery,
        date: this.dateFilter,
        type: this.typeFilter,
        milestone: this.milestoneFilter,
        timezone: this.timezoneFilter,
        rowsPerPage: this.rowsPerPage,
        currentPage: this.currentPage,
        toFilter: this.toFilter,
        callLength: this.durationFilter,
        filterIsAi: this.filterIsAi,
        id: this.userId,
        role: this.role,
        isVoicemail: this.isVoicemail,
        isTransfer: this.isTransfer
      }
      if (this.operatorsFilter) {
        params.salesRepId = this.operatorsFilter.sub
      }

      if (this.managerSearch === 'All') {
        params.managersId = 'All'
      } else {
        params.managersId = this.managersFilter?.sub
        
      }
      if (role === 'Managers') {
        //const userId = localStorage.getItem('CognitoIdentityServiceProvider.459g3alqohqvrvrln2ccla7r0v.LastAuthUser')
        params.managerId = this.userId
      } else {
        params.id = this.userId
      }

      const query = Object.keys(params)
        .map((key) => `${key}=${encodeURIComponent(params[key])}`)
        .join("&");

      try {
        const res = await axios.get(`https://123avatars.com/v2/leads?${query}`);
        if (!role) {
          console.log(localStorage.getItem('CognitoIdentityServiceProvider.459g3alqohqvrvrln2ccla7r0v.LastAuthUser'))
          this.allLeads = res.data?.leads.filter(lead => {
            return lead.sales_rep_id === localStorage.getItem('CognitoIdentityServiceProvider.459g3alqohqvrvrln2ccla7r0v.LastAuthUser');
          })
          return this.allLeads;
        }

        this.allLeads = res.data;
        this.totalRows = res.data.total;

        return this.allLeads
      } catch (error) {
        console.error("Error fetching leads:", error);
      } finally {
        this.loading = false
      }
    },
    async fetchLeads(id = null) {
      const params = {
        search: this.searchQuery,
        date: this.dateFilter,
        type: this.typeFilter,
        milestone: this.milestoneFilter,
        timezone: this.timezoneFilter,
        salesRep: this.salesRepFilter,
        salesRepId: this.salesRepId,
        shelfLife: this.shelfLifeFilter,
        hours: this.hoursFilter,
        startDate: this.customStartDate,
        endDate: this.customEndDate,
        rowsPerPage: this.rowsPerPage,
        currentPage: this.currentPage,
        toFilter: this.toFilter,
        id: this.userId,
        role: this.role,
        isVoicemail: this.isVoicemail
      };
      params.leadType = "two-min";
        axios
          .get("https://123avatars.com/v2/leads", { params })
          .then((response) => {
            this.isVoicemail = response.data?.isVoicemail;
            this.leads = response.data?.leads;
            this.totalRows = response.data.total;
            this.totalCount = response.data.total;
            this.scriptLink = response.data.scriptLink;
            this.signupLink = response.data.signupLink;
            this.qaLink = response.data.qaLink;
            this.trainingLockerLink = response.data.trainingLockerLink;
            this.filteredRows = this.filteredLeadRows();
            if (
              this.dialerActive &&
              this.filteredRows[0].type === "Outbound Human"
            ) {
              this.outboundHuman = true;
            } else {
              this.outboundHuman = false;
            }
            // setTimeout(() => {
            //   this.fetchLeads();
            // }, 15000);
          })
          .catch((error) => {
            console.error("Error fetching leads:", error);
            // setTimeout(() => {
            //   this.fetchLeads();
            // }, 15000);
          });
      // if (!role) {
      //   id = this.getCognitoSub();
      //   let target = "https://123avatars.com/v2/leads";
      //   let role = this.role;
      //   if (role == "Admins" && this.$route.path == "/manager-dashboard") {
      //     target += "?id=" + this.$route.query.id;
      //     params.id = this.$route.query.id;
      //   } else if (role == "Managers") {
      //     let sub = await AuthService.getCognitoSub();
      //     target += "?id=" + sub;
      //     params.id = sub;
      //   } else {
      //     let sub = await AuthService.getCognitoSub();
      //     target += "?id=" + sub;
      //     params.id = sub;
      //   }
      //   //params.id = id;
      //   params.leadType = "two-min";
      //   axios
      //     .get("https://123avatars.com/v2/leads", { params })
      //     .then((response) => {
      //       this.leads = response.data?.leads;
      //       this.totalRows = response.data.total;
      //       this.totalCount = response.data.total;
      //       this.scriptLink = response.data.scriptLink;
      //       this.signupLink = response.data.signupLink;
      //       this.qaLink = response.data.qaLink;
      //       this.trainingLockerLink = response.data.trainingLockerLink;
      //       this.filteredRows = this.filteredLeadRows();
      //       if (
      //         this.dialerActive &&
      //         this.filteredRows[0].type === "Outbound Human"
      //       ) {
      //         this.outboundHuman = true;
      //       } else {
      //         this.outboundHuman = false;
      //       }
      //       // setTimeout(() => {
      //       //   this.fetchLeads();
      //       // }, 15000);
      //     })
      //     .catch((error) => {
      //       console.error("Error fetching leads:", error);
      //       // setTimeout(() => {
      //       //   this.fetchLeads();
      //       // }, 15000);
      //     });
      // } else {
      //   params.id = "8a2e5bf6-1380-4ffb-9316-811951739a99";
      //   params.leadType = "two-min";
      //   axios
      //     .get("https://123avatars.com/v2/leads", { params })
      //     .then((response) => {
      //       this.leads = response.data?.leads;
      //       this.totalRows = response.data.total;
      //       this.totalCount = response.data.total;
      //       this.scriptLink = response.data.scriptLink;
      //       this.signupLink = response.data.signupLink;
      //       this.qaLink = response.data.qaLink;
      //       this.trainingLockerLink = response.data.trainingLockerLink;
      //       this.filteredRows = this.filteredLeadRows();
      //       if (
      //         this.dialerActive &&
      //         this.filteredRows[0].type === "Outbound Human"
      //       ) {
      //         this.outboundHuman = true;
      //       } else {
      //         this.outboundHuman = false;
      //       }
      //       // setTimeout(() => {
      //       //   this.fetchLeads();
      //       // }, 5000);
      //     })
      //     .catch((error) => {
      //       console.error("Error fetching leads:", error);
      //       // setTimeout(() => {
      //       //   this.fetchLeads();
      //       // }, 5000);
      //     });
      // }
    },
    fetchStats() {
      let id = this.$route.query.id;
      if (id) {
        const params = {
          uuid: id,
        };
        axios
          .get("https://123avatars.com/v2/stats", { params })
          .then((response) => {
            this.stats.today = response.data.today;
            this.stats.thisWeek = response.data.thisWeek;
            this.stats.lastWeek = response.data.lastWeek;
          })
          .catch((error) => {
            console.error("Error fetching stats:", error);
          });
      }
    },
    searchTable() {
      this.fetchLeads();
    },
    filterTable() {
      this.fetchLeads();
    },
    filterByMilestone(milestone) {
      this.milestoneFilter = milestone;
      this.fetchLeads();
    },
    applyCustomDateRange() {
      this.fetchLeads();
    },
    changePage(page) {
      if (page > 0 && page <= this.totalPages) {
        this.currentPage = page;
        // this.fetchLeads();
      }
    },
    updateRowsPerPage() {
      this.currentPage = 1;
      // this.fetchLeads();
    },
    sortTable(property) {
      const direction = this.sortDirection === "asc" ? "desc" : "asc";
      this.leads.sort((a, b) => {
        let x = a[property];
        let y = b[property];
        if (property === "date") {
          x = new Date(a.start_time);
          y = new Date(b.start_time);
        }
        if (direction === "asc") {
          return x > y ? 1 : x < y ? -1 : 0;
        } else {
          return x < y ? 1 : x > y ? -1 : 0;
        }
      });
      this.sortDirection = direction;
      this.filteredRows = this.filteredLeadRows();
    },
    matchSearchQuery(lead) {
      const query = this.searchQuery?.toLowerCase();
      return (
        lead.phone?.toLowerCase().includes(query) ||
        lead.company_name?.toLowerCase().includes(query) ||
        lead.state?.toLowerCase().includes(query) ||
        (lead.tags || []).some((tag) => tag?.toLowerCase().includes(query))
      );
    },
    matchDateFilter(date) {
      const d = new Date(date);
      const today = new Date();
      switch (this.dateFilter) {
        case "All":
          return false;
        case "Today":
          let isSame = this.isSameDay(today, d);
          return this.isSameDay(today, d);
        case "Yesterday":
          const yesterday = new Date();
          yesterday.setDate(today.getDate() - 1);
          return this.isSameDay(yesterday, d);
        case "This Week":
          return this.isSameWeek(today, d);
        case "Last Week":
          const lastWeek = new Date();
          lastWeek.setDate(today.getDate() - 7);
          return this.isSameWeek(lastWeek, d);
        case "This Month":
          return this.isSameMonth(today, d);
        case "Last Month":
          const lastMonth = new Date();
          lastMonth.setMonth(today.getMonth() - 1);
          return this.isSameMonth(lastMonth, d);
        case "Last 7 Days":
          const last7Days = new Date();
          last7Days.setDate(today.getDate() - 7);
          return d >= last7Days && d <= today;
        case "Last 30 Days":
          const last30Days = new Date();
          last30Days.setDate(today.getDate() - 30);
          return d >= last30Days && d <= today;
        case "Last 90 Days":
          const last90Days = new Date();
          last90Days.setDate(today.getDate() - 90);
          return d >= last90Days && d <= today;
        case "YTD":
          const startOfYear = new Date(today.getFullYear(), 0, 1);
          return d >= startOfYear && d <= today;
        case "Custom":
          const startDate = new Date(this.customStartDate);
          const endDate = new Date(this.customEndDate);
          return d >= startDate && d <= endDate;
        default:
          return true;
      }
    },
    isSameDay(date1, date2) {
      return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()
      );
    },
    isSameWeek(date1, date2) {
      const startOfWeek1 = new Date(date1);
      startOfWeek1.setDate(date1.getDate() - date1.getDay());
      startOfWeek1.setHours(0, 0, 0, 0);
      const startOfWeek2 = new Date(date2);
      startOfWeek2.setDate(date2.getDate() - date2.getDay());
      startOfWeek2.setHours(0, 0, 0, 0);
      return startOfWeek1.getTime() === startOfWeek2.getTime();
    },
    isSameMonth(date1, date2) {
      return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth()
      );
    },
    matchShelfLifeFilter(shelfLife) {
      switch (this.shelfLifeFilter) {
        case "1 Day":
          return shelfLife === 1;
        case "Under 30 Days":
          return shelfLife < 30;
        case "Over 30 Days":
          return shelfLife > 30;
        case "Over 90 Days":
          return shelfLife > 90;
        case "Over 180 Days":
          return shelfLife > 180;
        case "Over 365 Days":
          return shelfLife > 365;
        default:
          return true;
      }
    },
    matchDurationFilter(duration) {
      switch (this.durationFilter) {
        case "2-min":
          return duration > 120;
        case "5-min":
          return duration > 300;
        case "10-min":
          return duration > 600;
        case "15-min":
          return duration > 900;
        case "20-min":
          return duration > 1200;
        case "30-min":
          return duration > 1800;
        default:
          true;
      }
    },
    matchToFilter(lead) {
      switch (this.toFilter) {
        case "Missed":
          return lead.requested_to == 1 && lead.answered_to == 0;
          break;
        case "Requested":
          return lead.requested_to == 1;
          break;
        case "Answered":
          return lead.answered_to == 1;
          break;
        case "Completed":
          return lead.qualified_to == 1;
          break;
        default:
          return false;
      }
    },
    isDuringBusinessHours(timeZone) {
      const timeZoneMapping = {
        EST: "America/New_York",
        PST: "America/Los_Angeles",
        CST: "America/Chicago",
        MST: "America/Denver",
        HST: "Pacific/Honolulu",
        AKST: "America/Anchorage",
      };
      const ianaTimeZone = timeZoneMapping[timeZone];
      if (!ianaTimeZone) {
        throw new Error(`Unrecognized timezone abbreviation: ${timeZone}`);
      }
      const now = new Date();
      const options = {
        timeZone: ianaTimeZone,
        hour: "2-digit",
        hour12: false,
      };
      const formatter = new Intl.DateTimeFormat("en-US", options);
      const parts = formatter.formatToParts(now);
      const hour = parseInt(parts.find((p) => p.type === "hour").value, 10);
      return hour >= 9 && hour < 17;
    },
    getLocalTime(date, timeZone) {
      const timeZoneMapping = {
        EST: "America/New_York",
        PST: "America/Los_Angeles",
        CST: "America/Chicago",
        MST: "America/Denver",
        HST: "Pacific/Honolulu",
        AKST: "America/Anchorage",
      };
      const ianaTimeZone = timeZoneMapping[timeZone];
      if (!ianaTimeZone) {
        return new Date(date).toLocaleTimeString();
      }
      return new Date(date).toLocaleTimeString("en-US", {
        timeZone: ianaTimeZone,
      });
    },
    updateLead(row) {
      axios
        .put(`https://123avatars.com/v2/leads/${row.sales_lead_id}`, row)
        .then((response) => {
          console.log("Lead updated:", response.data);
        })
        .catch((error) => {
          console.error("Error updating lead:", error);
        });
    },
    openNotesPopup(companyName, leadId) {
      this.notesCompanyName = `Notes for ${companyName}`;
      this.currentLeadId = leadId;
      this.showOverlay = true;
      this.showNotesPopup = true;
      const userId = localStorage.getItem("userId");
      axios
        .get(
          `https://123avatars.com/v2/notes?leadId=${leadId}&userId=${userId}`
        )
        .then((response) => {
          this.currentNotes = response.data.notes;
        })
        .catch((error) => {
          console.error("Error fetching notes:", error);
          this.currentNotes = [];
        });
    },
    openMessagesPopup(row) {
      this.messagesCompanyName = `Messages with ${row.company_name}`;
      this.showOverlay = true;
      this.showMessagesPopup = true;
      this.messages = [];
      const avatarId = row.avatar_id;
      const leadId = row.lead_id;
      axios
        .get(
          `https://123avatars.com/v2/lead-conversation?avatar_id=${row.avatar_id}&lead_id=${row.lead_id}&user_id=${this.userId}`,
          {
            params: {
              avatar_id: avatarId,
              lead_id: leadId,
            },
          }
        )
        .then((response) => {
          this.messages = response.data;
        })
        .catch((error) => {
          console.error("Error fetching messages:", error);
        });
    },
    closePopup() {
      this.showOverlay = false;
      this.showNotesPopup = false;
      this.showMessagesPopup = false;
      this.currentLeadId = null;
    },
    saveNote() {
      if (!this.newNoteText.trim()) return;
      const newNote = {
        userId: localStorage.getItem("userId"),
        date: new Date().toLocaleDateString(),
        time: new Date().toLocaleTimeString(),
        text: this.newNoteText.trim(),
      };
      axios
        .post(
          `https://123avatars.com/v2/leads/${this.currentLeadId}/notes`,
          newNote
        )
        .then((response) => {
          this.currentNotes.push(newNote);
          this.newNoteText = "";
          this.fetchLeads();
        })
        .catch((error) => {
          console.error("Error saving note:", error);
        });
    },
    deleteNote(index, noteId) {
      axios
        .delete(
          `https://123avatars.com/v2/leads/${this.currentLeadId}/notes/${noteId}`
        )
        .then((response) => {
          this.currentNotes.splice(index, 1);
          this.fetchLeads();
        })
        .catch((error) => {
          console.error("Error deleting note:", error);
        });
    },
    // addTag(rowIndex) {
    //   const newTag = this.filteredRows[rowIndex].newTag.trim();
    //   if (newTag) {
    //     this.filteredRows[rowIndex].tags.push(newTag);
    //     this.filteredRows[rowIndex].newTag = "";
    //     this.updateLead(this.filteredRows[rowIndex]);
    //   }
    // },
    // removeTag(rowIndex, tagIndex) {
    //   this.filteredRows[rowIndex].tags.splice(tagIndex, 1);
    //   this.updateLead(this.filteredRows[rowIndex]);
    // },
    async initUsers() {
      this.role = await AuthService.getUserRole();
      if (this.role === null) {
        this.role = 'Operators';
      }
      this.userId = await AuthService.getCognitoSub();
      this.timezone = this.getTimezone();
    }
  },
  watch: {
    dateFilter: 'fetchAllLeads',
    timezoneFilter: 'fetchAllLeads',
    callLength: 'fetchAllLeads',
    managers: 'fetchAllLeads',
    toFilter: 'fetchAllLeads',
    milestoneFilter: 'fetchAllLeads',
    hoursFilter: 'fetchAllLeads',
    operatorsFilter: 'fetchAllLeads',
    callStatus: 'fetchAllLeads',
    rowsPerPage: 'fetchAllLeads',
    filterIsAi: 'fetchAllLeads',
    isVoicemail: 'fetchAllLeads',
    isTransfer: 'fetchAllLeads',
    currentPage() {
      this.fetchAllLeads();
    },
  },
  mounted() {
    this.initUsers();
    //AuthService.getUserRole().then(res => this.role = res)
    //console.log(this.$store.dispatch('getAll'))
    console.log(this.$store.state.users)
    this.loadExternalScript();
    this.toFilter = this.toCallsFilter ? this.toCallsFilter : "All";
    //this.fetchLeads();
    this.fetchStats();
    this.fetchAllLeads();
    this.fetchManagers();
    this.fetchOperators();
    document.addEventListener('click', (e) => {
      if (!e.target.closest('#managerFilter')) {
        this.showDropdown = false;
      }
    });
    document.addEventListener('click', (e) => {
      if (!e.target.closest('#operatorFilter')) {
        this.showOperatorDropdown = false;
      }
    });
  },
};
</script>

<style>
</style>