<template>

  <div>
    <UIBox classbox="fullheight">
      <template v-slot:title>
        <span class="bold">Panel</span>
      </template>
      <template>

    <audio id="manager-audio" autoplay></audio>

    <!-- Make the table horizontally scrollable on small screens -->

      <div class="table-fullwidth customScroll">
      <div class="filter-container mb-3 sticky">
          <label for="manager-filter">Filter by Manager: </label>
          <select id="manager-filter" class="form-control" v-model="selectedManager" @change="filterByManager">
          <option value="all">All</option>
          <option v-for="manager in managers" :value="manager">{{ manager }}</option>
          </select>
      </div>
      <table class="table no-border-collapse" id="call-panel">
        <thead class="sticky">
          <tr>
            <th>Manager</th>
            <th>Rep Name</th>
            <th>Start Time</th>
            <th>Notification</th>
            <th>Call Operator</th>
            <th>Chat Operator</th>
            <!-- <th>Call Cell</th>
            <th>Text Cell</th> -->
            <!-- <th>Consistent Barge</th> -->
            <th>Barge</th>
            <th>Mute</th>
            <th>Currently Barging</th>
            <th>2 Min / Total Calls = %</th>
            <th>Calls / Transfer</th>
            <th>Finished Scripts</th>
            <th>Finished Scripts Asked All Questions</th>
            <th>Missed TO's</th>
            <th>Barges</th>
            <th>Paid TO's</th>
            <th>Current Call Time</th>
            <th>Last Transfer Since</th>
            <th>Time Since Last Call</th>
            <th>Talk Time</th>
            <th>Owner's Name</th>
            <th>Company Name</th>
            <th>Industry</th>
            <th>Website</th>
            <th>State</th>
            <th>Online Marketing Before (Y/N)</th>
            <th>Current Marketing</th>
            <th>Monthly Marketing Budget</th> 
            <th>More Business (Y/N)</th>
            <th>Open to More Customers (Y/N)</th>
            <th>Sign Up</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="call in filteredCalls" :class="getClass(call)" >
              <td>{{ call.manager }}</td>
              <td>{{ call.repName }}</td>
              <td>{{  call.startTime }}</td>
              <td style="text-align: center; vertical-align: middle;">
                  <div v-if="call.toRequested == true">TO Requested
                      <i 
                      class="fa-solid fa-exclamation" 
                      v-if="call.toRequested == true" 
                      style="color: red; font-size: 2rem;">
                  </i>
                  </div>
              </td>
            <td>
              <button class="btn solid" @click="callExtension(call)">Call Operator</button>
            </td>
            <td>
              <span 
                  class="badge bg-danger text-white" 
                  v-if="call.numUnread > 0"
                  style="font-size: 1.2rem; padding: 0.25em 0.5em; border-radius: 50%;">
                  {{ call.numUnread }}
              </span>
              <button class="btn solid chat-button" @click="openChat(call)">Chat Operator</button>
            </td>
            <!-- <td>
              <button class="btn solid chat-button" @click="callCell(call)">Call Cell</button>
            </td>
            <td>
              <button class="btn solid chat-button" @click="textCell(call)">Text Cell</button>
            </td> -->
            <!-- <td><button class="btn solid consistent-barge-button" @click="handleBargeClick(call.manager)">Consistent Barge</button></td> -->
            <td class="nowrap">
              <button class="btn solid single-barge" @click="handleBargeClick(call)" v-if="!isListening">Barge</button>
              <button class="btn solid single-barge" @click="closeBarge(call)" v-if="isListening && currentSalesRepId === call.id">Close Barge</button>
            </td>
            <td>
              <button  @click="toggleMute(call)" v-if="isListening && currentSalesRepId === call.id">{{ isMuted ? 'Unmute' : 'Mute' }}</button>
              <div v-if="!isListening">-</div>
            </td>
            <td class="currently-barging">
              {{ call.barging ? `${call.bargingName} is Barging` : '' }}
            </td>
            <td>{{ call.twoMinCalls }}</td>
            <td>-</td>
            <td>{{ call.finishedScripts }}</td>
            <td>{{ call.operatorTOs }}</td>
            <td>{{ call.tosRequested }}</td>
            <td v-html="call.barges" class="barges-column"></td>
            <td>{{ call.tos }}</td>
            <td class="length">{{ formatTime(connectedCallTime(call)) }}</td>
            <td class="time-since-last-call">{{ call.lastTransferEndTime ? formatTime(timeSinceLastCall(call.lastTransferEndTime)) : '-' }}</td>
            <td class="time-since-last-call">{{ call.lastCallEndTime ? formatTime(timeSinceLastCall(call.lastCallEndTime)) : '-' }}</td>
            <td>{{ call.talkTime }}</td>
            <td>{{ call.ownerName }}</td>
            <td>{{ call.companyName }}</td>
            <td>{{ call.industry }}</td>
            <td><a :href="call.website" target="_blank"><i class="fa-solid fa-globe"></i></a></td>

            <!-- <td style="max-width:300px;" v-if="call.website != ''">
              <a :href="call.website" target="_blank" v-if="call?.website?.length < 100">{{ call.website }}</a>
              <a :href="call.website" target="_blank" v-if="call?.website?.length >= 100">Website</a>
            </td>
            <td style="max-width:300px;" v-else>
              <a :href="call.website" target="_blank" v-if="call?.website?.length < 250">{{ call.website }}</a>
              <a :href="call.website" target="_blank" v-if="call?.website?.length >= 250">Website</a>
            </td> -->
            <td>{{ call.state }}</td>
            <td>{{ call.onlineMarketingBefore }}</td>
            <td>{{ call.currentMarketing }}</td>
            <td>{{ call.monthlyMarketingBudget }}</td>
            <td>{{ call.moreBusiness }}</td>
            <td>{{ call.freeMarketing }}</td>
            <td><button class="btn btn-success" @click="signUpProspect(call)">Sign Up</button></td>
          </tr>
          <tr v-if="calls"  class="sticky">
              <td><strong>Totals</strong></td>
              <td><strong>{{ totalReps }} Reps</strong></td>
              <td><strong>-</strong></td>
              <td><strong>-</strong></td>
              <td><strong>-</strong></td>
              <td><strong>-</strong></td>
              <td><strong>-</strong></td>
              <td><strong>-</strong></td>
              <td><strong>-</strong></td>
              <td><strong>{{ totalTwoMinCalls }}</strong></td>
              <td><strong>{{ totalFinishedScripts }}</strong></td>
              <td><strong>{{ totalOperatorTOs }}</strong></td>
              <td><strong>{{ totalTosRequested }}</strong></td>
              <td v-html="totalBarges"></td>
              <td><strong>{{ totalTos }}</strong></td>
              <td><strong>-</strong></td>
              <td><strong>-</strong></td>
              <td><strong>{{ totalTalkTime }}</strong></td>
              <td><strong>-</strong></td>
              <td><strong>-</strong></td>
              <td><strong>-</strong></td>
              <td><strong>-</strong></td>
              <td><strong>-</strong></td>
              <td><strong>-</strong></td>
              <td><strong>-</strong></td>
              <td><strong>-</strong></td>
              <td><strong>-</strong></td>
              <td><strong>-</strong></td>
              <td><strong>-</strong></td>
              <td><strong>-</strong></td>
          </tr>
        </tbody>
      </table>
    </div>
    </template>
    </UIBox>

    <!-- <div id="manager-chat"> -->
      <!-- Collapsible chat panel -->
      <!-- <b-collapse id="managerchat" class="messanger-pop" v-model="chatVisible">
          <b-card group> -->
          <!-- Badge showing unread messages -->
          <!-- <span class="badge" v-if="numUnread > 0">{{ numUnread }}</span> -->
          <!-- Chat component -->
          <!-- <Chat :type="'MANAGER'" 
                  :salesRepId="currentSalesRepId" 
                  :managerId="managerId" 
                  :headerTitle="`Chat with ${currentSalesRepName}`" 
                  @new_message="handleMessageNotification"
                  @close-modal="closeChat" 
                  v-if="chatVisible" />
          </b-card>
      </b-collapse> -->

      <!-- Icon that toggles the chat panel -->
      <!-- <div class="messanger-icon" align-self="center">
          <span class="badge" v-if="numUnread > 0">{{ numUnread }}</span> -->
          <!-- Toggle icon that shows/hides chat -->
          <!-- <a >
          <chatSVG />
          </a>
      </div>
    </div> -->
  </div>
</template>

<script>
import axios from 'axios';
import { TelnyxRTC } from '@telnyx/webrtc';
import SocketService from './socketService.js';
import Chat from "../../components/sales/Chat.vue";
import AuthService from '../../_services/AuthService.js';
import UIBox from '../../components/uibox.vue';
import ChatSVG from '../../components/SVG-icons/chatSVG.vue';

export default {
  components: { 'Chat': Chat, ChatSVG, UIBox },
  data() {
    return {
      selectedManager: 'all',
      chatVisible: false,
      chatRep: '',
      managers: [],
      calls: [
        {
          repName: 'John Doe',
          manager: 'Manager A',
          status: 'ongoing',
          seconds: 120,
          lastCallSeconds: 0,
          barges: '12/1h 22m',
          tos: 13,
          length: '2:00',
          currentCallTime: 0,
          timeSinceLastCall: '0:00',
          ownerName: 'Jane Smith',
          companyName: 'ABC Corp',
          website: 'www.abccorp.com',
          state: 'CA',
          onlineMarketingBefore: 'Yes',
          currentMarketing: 'Word of Mouth',
          monthlyMarketingBudget: '$500',
          moreBusiness: 'Yes',
          freeMarketing: 'No',
          tosRequested: 3,
          talkTime: '4h 32m',
          barging: true,
          isDialing: false,
          currentCallTime: 0,
          toRequested: false,
          managerId: ''
        },
        {
          repName: 'Jane Roe',
          manager: 'Manager B',
          status: 'completed',
          seconds: 180,
          lastCallSeconds: 60,
          barges: '5/45m',
          tos: 13,
          length: '',
          currentCallTime: 0,
          timeSinceLastCall: '1:00',
          ownerName: 'John Smith',
          companyName: 'XYZ Ltd',
          website: 'www.xyzltd.com',
          state: 'NY',
          onlineMarketingBefore: 'No',
          currentMarketing: 'Social Media',
          monthlyMarketingBudget: '$600',
          moreBusiness: 'No',
          freeMarketing: 'Yes',
          tosRequested: 2,
          talkTime: '3h 15m',
          barging: false,
          isDialing: false,
          currentCallTime: 0,
          toRequested: false,
          managerId: ''
        }
      ],

        currentUser: 'Roaming Closer',

      // timers
      now: new Date(),
      callStartTime: null,

      // chat
      currentSalesRepId: '',
      currentSalesRepName: '',
      managerId: '',
      listenNumber: '',
      repId: '',
      callId: '',
      numUnread: 0,
      chatVisible: false,

      // call
      phoneNumber: '',


      // barge
      telnyx: null,
      currentCall: null,
      isMuted: true,
      username: 'russell32925',
      password: 'gbtKZ5ym',
      callerId: '+19492884866',
      usernameTwo: 'cmharrisiii48614',
      passwordTwo: 'T0Do3WIN',
      callerIdTwo: '+13125300209',

      usernameThree: 'cmharrisiii43213',
      passwordThree: 'nvaXm1Um',
      callerIdThree: '+19494012681',

      usernameFour: 'cmharrisiii46817',
      passwordFour: 'VCrbl4Sk',
      callerIdFour: '+19494012684',
      
      usernameFive: 'cmharrisiii11181',
      passwordFive: '0eQAOTxT',
      callerIdFive: '+19494012740',
      
      usernameSix: 'cmharrisiii78023',
      passwordSix: 'hLPlbajD',
      callerIdSix: '+19494012743',
      
      usernameSeven: 'cmharrisiii36261',
      passwordSeven: 'kXOTAE7Z',
      callerIdSeven: '+19494012744',

      usernameEight: 'cmharrisiii90950',
      passwordEight: 'CsFmdi0A',
      callerIdEight: '+19493126418',

      managerNum: '',
      
      telnyxRTC: null,
      localAudioTrack: null,
      isListening: false,
      
    };
  },
  computed: {
    filteredCalls() {
            // Filter calls based on selected manager and ensure only rows with calls are included
            let filtered = this.selectedManager === 'all'
                ? this.calls
                : this.calls.filter(call => call.manager === this.selectedManager);

            // Further filter to only include rows with valid calls
            filtered = filtered.filter(call => call.calls > 0);

            // Sort the filtered calls
            let result = filtered.sort((a, b) => {
                // First, sort by currentCallTime (longest to shortest)
                if (a.currentCallTime > 0 && b.currentCallTime > 0) {
                    return b.currentCallTime - a.currentCallTime; // Longer currentCallTime comes first
                }

                // If one has currentCallTime > 0 and the other doesn't, prioritize the one with currentCallTime > 0
                if (a.currentCallTime > 0) return -1; // a comes first
                if (b.currentCallTime > 0) return 1;  // b comes first

                // Both currentCallTime are 0, so sort by lastCallEndTime (most recent to least recent)
                return new Date(b.lastCallEndTime) - new Date(a.lastCallEndTime);
            });

            return result;
        },
      totalTwoMinCalls() {
          //console.log('filteredCalls: ', JSON.stringify(this.filteredCalls));
          const totalHumanCalls = this.filteredCalls.reduce((total, call) => {
              //console.log('call: ', call);
          const humanCalls = parseInt(call.humanCalls); //[humanCalls] = call.twoMinCalls.split('/').map(Number);
          return total + (humanCalls || 0);
          }, 0);

          const totalCalls = this.filteredCalls.reduce((total, call) => {
          const _totalCalls = parseInt(call.calls); // = call.twoMinCalls.split('/').map(Number);
          return total + (_totalCalls || 0);
          }, 0);

          console.log('totalCalls: ', totalCalls);
          console.log('totalHumanCalls: ', totalHumanCalls);

          const humanPercentage = totalCalls > 0 ? ((totalHumanCalls / totalCalls) * 100).toFixed(1) : '0';

          return `${totalHumanCalls}/${totalCalls}\n${humanPercentage}%`;
      },
      totalTosRequested() {
          return this.filteredCalls.reduce((total, call) => total + (call.tosRequested || 0), 0);
      },
      totalBarges() {
          const totalBarges = this.filteredCalls.reduce((total, call) => {
              const barges = call.bargeCount;
              return total + (barges || 0);
          }, 0);

          const totalLoggedTime = this.filteredCalls.reduce((total, call) => {
              const loggedTime = call.bargeDuration;
              return total + (loggedTime || 0);
          }, 0);

          return `<strong>${totalBarges} barges<br>${this.formatSeconds(totalLoggedTime)}</strong>`;
      },
      totalTos() {
          return this.filteredCalls.reduce((total, call) => total + (call.tos || 0), 0);
      },
      totalFinishedScripts() {
          return this.filteredCalls.reduce((total, call) => total + (parseInt(call.finishedScripts) || 0), 0);
      },
      totalOperatorTOs() {
          return this.filteredCalls.reduce((total, call) => total + (parseInt(call.operatorTOs) || 0), 0);
      },
      totalTalkTime() {
          const totalSeconds = this.filteredCalls.reduce((total, call) => {
              const talkTimeInSeconds = parseInt(call.talkTimeSeconds); // this.parseFormattedTime(call.talkTime || "0h 0m");
              return total + talkTimeInSeconds;
          }, 0);

          return this.formatSeconds(totalSeconds);
      },
      totalReps() {
          return this.filteredCalls.length;
      }
  },
  methods: {
      // Helper methods to parse and format time as needed
      parseTimeToMinutes(time) {
          const [hours, minutes] = time.split(':').map(Number);
          return hours * 60 + (minutes || 0);
      },

      formatMinutesToHours(minutes) {
          const hours = Math.floor(minutes / 60);
          const remainingMinutes = minutes % 60;
          return `${hours}:${remainingMinutes.toString().padStart(2, '0')}`;
      },
      async callExtension(call) {
          this.$emit('init-call', call.sip_did);
      },
      async callCell(call) {
          this.$emit('init-call', call.cell);
      },
      async textCell() {
      },
      async signUpProspect(rep) {
          let signupLink = 'http://pricing.123websites.net';
          signupLink += `?rep_id=${rep.id}&lead_id=${rep.leadId}`;
          window.open(signupLink, '_blank');
      },
      async closeBarge(_call) {
          if(this.currentCall) {
              await this.currentCall.hangup();
              this.currentCall = null;
              this.localAudioTrack = null;
          }
          if(!this.isMuted) {
              this.isMuted = true;
          }
          this.calls.forEach(call => {
              call.barging = false;
          });
          let data = {
              event: 'update-barge',
              salesRepId: _call.id,
              managerId: _call.managerId,
              callId: _call.callId,
              status: 'close'
          };
          SocketService.emit('update-barge', data);
          // close barge event should save
          this.currentSalesRepId = '';
          this.isListening = false;
      },
      closeChat() {
          this.chatVisible = false;
      },
      openChat(rep) {
          this.currentSalesRepId = rep.id;
          this.currentSalesRepName = rep.repName;
          this.managerId = rep.managerId;
          rep.numUnread = 0;
          //this.chatVisible = !this.chatVisible;
          this.$emit('open-chat', rep);
      },
      handleMessageNotification(event) {
          console.log('event: ', event);
          // Select the element
          const chatElement = document.getElementById('chat-collapse');
          if(!chatElement.classList.contains('show')) {
              const linkElement = document.getElementById('chat-link');
              linkElement.click();
          }
      },
      timeSinceLastCall(endLastCall) {
          const end = endLastCall || new Date();
          const timeDifference = this.now - new Date(end); // Difference in milliseconds
          let timeDiff = Math.floor(timeDifference / 1000); // Convert milliseconds to seconds
          return timeDiff;
      },
      connectedCallTime(rep) {
          if(rep.callStartTime) {
              const timeDifference = this.now - rep.callStartTime; // Corrected the subtraction
              let timeDiff = Math.floor(timeDifference / 1000); // Time difference in seconds
              rep.currentCallTime = timeDiff;
              return timeDiff;
          } else {
              return 0;
          }
      },
      formatTime(seconds) {
          if (seconds <= 0) {
              return '-';
          }

          const hours = Math.floor(seconds / 3600);
          const minutes = Math.floor((seconds % 3600) / 60);
          const secs = seconds % 60;

          let formattedTime = '';

          if (hours > 0) {
              formattedTime += `${hours}h `;
          }
          if (minutes > 0 || hours > 0) {
              formattedTime += `${minutes}m `;
          }
          formattedTime += `${secs}sec`;

          return formattedTime.trim();
          },
          mapInputToCalls(input) {
                // First, map the input into row objects along with the original rep data.
                const rowsWithRep = input.map(rep => {
                    const today = new Intl.DateTimeFormat('en-US', {
                    weekday: 'short',
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                    timeZone: 'America/Los_Angeles'
                    }).format(new Date());

                    // Use aggregated data as a fallback.
                    const todayData = rep.data[today] || { 
                    barges: 0, 
                    bargeDuration: 0, 
                    loggedTime: 0, 
                    talkTime: 0, 
                    completedTOs: 0, 
                    requestedTOs: 0 
                    };

                    // Build the row using fallback values.
                    const row = {
                    id: rep.id,
                    repName: rep.name,
                    startTime: this.getStartTime(todayData),
                    manager: rep.manager,
                    managerId: rep.managerId,
                    status: 'temp', // default status
                    seconds: rep.currentCallTime || 0,
                    lastCallSeconds: this.getLastCallSeconds(rep.lastCallEndTime),
                    barges: `${todayData.barges} barges<br>${this.formatSeconds(todayData.bargeDuration)}`,
                    tos: parseInt(todayData.completedTOs, 10) || 0,
                    length: this.formatSeconds(rep.currentCallTime),
                    currentCallLength: 0,
                    timeSinceLastCall: this.formatSeconds(rep.timeSinceLastCall),
                    ownerName: '',
                    companyName: '',
                    website: '',
                    state: '',
                    onlineMarketingBefore: '',
                    currentMarketing: '',
                    monthlyMarketingBudget: '',
                    moreBusiness: '',
                    freeMarketing: '',
                    tosRequested: parseInt(todayData.requestedTOs, 10) || 0,
                    talkTime: this.formatSeconds(todayData.talkTime),
                    finishedScripts: todayData.finished_scripts,
                    operatorTOs: todayData.operator_tos,
                    barging: rep.isListening,
                    isDialing: rep.isDialing,
                    currentCallTime: rep.currentCallTime || 0,
                    lastCallEndTime: rep.lastCallEndTime,
                    numUnread: rep.numUnread,
                    toRequested: rep.toRequested || false,
                    twoMinCalls: this.getCalls(todayData),
                    sip_did: rep.sip_did,
                    cell: rep.cell,
                    humanCalls: todayData.humanCalls,
                    calls: todayData.calls,
                    bargeCount: todayData.barges,
                    bargeDuration: todayData.bargeDuration,
                    talkTimeSeconds: todayData.talkTime
                    };

                    return { rep, row };
                });

                // Extract the mapped rows.
                const mappedRows = rowsWithRep.map(item => item.row);

                // Set the component's calls array so that callStatus can locate the rep by id.
                this.calls = mappedRows;

                // Now, for each original rep with an active call, build the status data and update via callStatus.
                rowsWithRep.forEach(({ rep, row }) => {
                    if (rep.activeCalls && rep.activeCalls.length > 0) {
                    const activeCall = rep.activeCalls[0];
                    const callStatusData = {
                        salesRepId: row.id,
                        status: activeCall.status,
                        callId: activeCall.callId,
                        leadId: activeCall.leadId,
                        azureId: activeCall.azureId,
                        callEndTime: activeCall.callEndTime ? new Date(activeCall.callEndTime) : null,
                        startTime: activeCall.startTime
                    };
                    // Now call the callStatus function to update the rep's state.
                    this.callStatus(callStatusData);
                    }
                });

                return mappedRows;
                },
        //   mapInputToCalls(input) {
        //     return input.map(rep => {
        //         const today = new Intl.DateTimeFormat('en-US', {
        //         weekday: 'short',
        //         year: 'numeric',
        //         month: 'short',
        //         day: '2-digit',
        //         timeZone: 'America/Los_Angeles' // Explicitly set PT timezone
        //         }).format(new Date());

        //         // Get today's aggregated data as a fallback
        //         const todayData = rep.data[today] || { 
        //             barges: 0, 
        //             bargeDuration: 0, 
        //             loggedTime: 0, 
        //             talkTime: 0, 
        //             completedTOs: 0, 
        //             requestedTOs: 0 
        //         };

        //         // If there is an active call from Redis, use its data for live UI updates.
        //         let activeCall = null;
        //         if (rep.activeCalls && rep.activeCalls.length > 0) {
        //         // If more than one active call exists, choose the first one (or handle accordingly)
        //         activeCall = rep.activeCalls[0];
        //         }

        //             // If an active call exists, build the callStatusData and call callStatus() to update the rep
        //         if (activeCall) {
        //             const callStatusData = {
        //                 salesRepId: rep.id,
        //                 status: activeCall.status,
        //                 callId: activeCall.callId,
        //                 leadId: activeCall.leadId,
        //                 azureId: activeCall.azureId,
        //                 callEndTime: new Date(activeCall.callEndTime) || null,
        //                 // Optionally include startTime if you want callStatus to use it.
        //                 startTime: activeCall.startTime
        //             };
        //             // Update the rep state via the callStatus handler
        //             this.callStatus(callStatusData);
        //         }

        //         // Use live call data if available, otherwise fall back to the aggregated data.
        //         const callStartTime = activeCall && activeCall.startTime 
        //         ? activeCall.startTime 
        //         : this.getStartTime(todayData);
        //         const callStatus = activeCall && activeCall.status 
        //         ? activeCall.status 
        //         : 'temp'; // You can adjust this default as needed

        //         return {
        //         id: rep.id,
        //         repName: rep.name,
        //         startTime: this.getStartTime(todayData), // now includes live call start time if present
        //         manager: rep.manager,
        //         managerId: rep.managerId,
        //         status: callStatus, // live status (e.g., DIALING, ACTIVE) if available
        //         seconds: rep.currentCallTime || 0, // assuming current call time is in seconds
        //         lastCallSeconds: this.getLastCallSeconds(rep.lastCallEndTime), // Calculate time since last call
        //         barges: `${todayData.barges} barges<br>${this.formatSeconds(todayData.bargeDuration)}`, // Barges / Total logged time for today
        //         tos: parseInt(todayData.completedTOs, 10) || 0, // Completed TOs for today
        //         length: this.formatSeconds(rep.currentCallTime), // Length of the current call
        //         currentCallLength: 0,
        //         timeSinceLastCall: this.formatSeconds(rep.timeSinceLastCall), // Time since last call
        //         ownerName: '', // Placeholder as it's not in the input
        //         companyName: '', // Placeholder as it's not in the input
        //         website: '', // Placeholder as it's not in the input
        //         state: '', // Placeholder as it's not in the input
        //         onlineMarketingBefore: '', // Placeholder as it's not in the input
        //         currentMarketing: '', // Placeholder as it's not in the input
        //         monthlyMarketingBudget: '', // Placeholder as it's not in the input
        //         moreBusiness: '', // Placeholder as it's not in the input
        //         freeMarketing: '', // Placeholder as it's not in the input
        //         tosRequested: parseInt(todayData.requestedTOs, 10) || 0, // TOs Requested for today
        //         talkTime: this.formatSeconds(todayData.talkTime), // Total talk time for today
        //         finishedScripts: todayData.finished_scripts,
        //         operatorTOs: todayData.operator_tos,
        //         barging: rep.isListening, // Assuming isListening refers to barging status
        //         isDialing: rep.isDialing,
        //         currentCallTime: rep.currentCallTime || 0,
        //         lastCallEndTime: rep.lastCallEndTime,
        //         numUnread: rep.numUnread,
        //         toRequested: rep.toRequested || false,
        //         twoMinCalls: this.getCalls(todayData),
        //         sip_did: rep.sip_did,
        //         cell: rep.cell,
        //         humanCalls: todayData.humanCalls,
        //         calls: todayData.calls,
        //         bargeCount: todayData.barges,
        //         bargeDuration: todayData.bargeDuration,
        //         talkTimeSeconds: todayData.talkTime
        //         };
        //     });
        //   }, 
          mapStatsToCalls(input) {
          return input.map(rep => {
              const today = new Intl.DateTimeFormat('en-US', {
                  weekday: 'short',
                  year: 'numeric',
                  month: 'short',
                  day: '2-digit',
                  timeZone: 'America/Los_Angeles' // Explicitly set PT timezone
              }).format(new Date());

              console.log('today: ', today);
             
              // Get the most recent data entry for the current day, if exists
              const todayData = rep.data[today] || { barges: 0, bargeDuration: 0, loggedTime: 0, talkTime: 0, completedTOs: 0, requestedTOs: 0 };

              return {
                  id: rep.id,
                  repName: rep.name,
                  startTime: this.getStartTime(todayData),
                  manager: rep.manager,
                  managerId: rep.managerId,
                  barges: `${todayData.barges}/${this.formatSeconds(todayData.bargeDuration)}`, // Barges / Total logged in time in hours and minutes for today
                  tos: parseInt(todayData.completedTOs, 10) || 0, // Completed TOs for today
                  tosRequested: parseInt(todayData.requestedTOs, 10) || 0, // TOs Requested for today
                  talkTime: this.formatSeconds(todayData.talkTime), // Total talk time for today
                  twoMinCalls: this.getCalls(todayData),
                  finishedScripts: todayData.finished_scripts,
                  operatorTOs: todayData.operator_tos,
                  humanCalls: todayData.humanCalls,
                  calls: todayData.calls,
                  bargeCount: todayData.barges,
                  bargeDuration: todayData.bargeDuration,
                  talkTimeSeconds: todayData.talkTime
              };
          });
          },

          getCalls(data) {
              let callsCount = (data?.humanCalls || '-') + '/' + (data?.calls || '-');
              let humanPercent = 0;
              if(data?.humanCalls > 0) {
                  humanPercent = parseFloat((data?.humanCalls / data?.calls) * 100).toFixed(1);
                  callsCount += '\n' + (humanPercent) + '%';
              }
              return callsCount;
          },
          getStartTime(data) {
              let result = data.startTime;
              if(!result) {
                  return '-';
              }
              // Initialize the date in UTC
              const date = new Date(`${result}Z`); // Ensure it's treated as UTC
              if (isNaN(date)) {
                  return '-';
              }

              const options = {
                  timeZone: 'America/Los_Angeles',  // Convert to Pacific Time
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit'
              };

              return new Intl.DateTimeFormat('en-US', options).format(date);
          },

          // Helper function to format seconds into hours and minutes
          formatSeconds(seconds) {
          const hours = Math.floor(seconds / 3600);
          const minutes = Math.floor((seconds % 3600) / 60);
          return `${hours}h ${minutes}m`;
          },
          parseFormattedTime(timeString) {
              const timeParts = timeString.match(/(\d+)h\s+(\d+)m/);
              if (!timeParts) return 0; // return 0 if the format is unexpected

              const hours = parseInt(timeParts[1], 10);
              const minutes = parseInt(timeParts[2], 10);
              return (hours * 3600) + (minutes * 60);
          },

          // Helper function to calculate time since the last call
          getLastCallSeconds(lastCallEndTime) {
          const now = new Date();
          const lastCall = new Date(lastCallEndTime);
          const diffInSeconds = Math.floor((now - lastCall) / 1000);
          return diffInSeconds;
          },

          // Helper function to get the most recent day's data
          getLatestData(data) {
          const sortedKeys = Object.keys(data).sort((a, b) => new Date(b) - new Date(a));
          return data[sortedKeys[0]];
          },
    closeModal() {
      this.chatVisible = false;
    },
    filterByManager() {
      // Manager filtering logic is handled via computed property
    },
    async handleBargeClick(call) {
      // Update barge indicator for clicked manager


      // this.currentSalesRepId = manager.id;
      // let _manager = manager.manager;
      // const call = this.calls.find(call => call.manager === _manager);
//        if (call) {
        this.currentSalesRepId = call.id;
        call.barging = true;
//      }
      call.toRequested = false;
      if(this.currentCall) {
          await this.currentCall.hangup();
          this.currentCall = null;
          this.localAudioTrack = null;
      }
      this.bargeCall(call);
    },
    getClass(rep) {
      let timeDiff = this.connectedCallTime(rep);
      return {
          'ringing': rep.isDialing,
          'one-min': timeDiff > 0 && timeDiff < 60 && !rep.isDialing,
          'over-one-min': timeDiff > 0 && timeDiff >= 60 && !rep.isDialing,
          // 'connected': rep.currentCallTime > 0 && !rep.isDialing,
          // 'two-min': rep.currentCallTime > 120 && !rep.isDialing,
          // 'five-min': rep.currentCallTime > 300 && !rep.isDialing,
          // 'dialing': rep.isDialing
      };
  },
    async setManagerId() {
      let role = await AuthService.getUserRole();
      this.role = role;
      if(role == 'Admins' || role == 'Recruiter_Managers' || role == 'Recruiters' || role == 'Roaming_Closer') {
          //target += '?id=' + this.$route.query.id;
          this.managerId = '123';
      }
      
      if(role == 'Admins' && this.$route.path == '/manager-dashboard') {
          this.managerId = this.$route.query.id;
      }
      if(role == 'Managers') {
          this.managerId = await AuthService.getCognitoSub();
          this.managerId = '123';
      }
      console.log('managerId: ', this.managerId);
      },
      configureSockets() {
          SocketService.connect();
          SocketService.register(this.managerId);
          SocketService.on('new_message', this.handleNewMessage);
          SocketService.on('request-to', this.notifyTORequest);
          SocketService.on('call-update', this.callUpdate);
          SocketService.on('barge-update', this.bargeUpdate);
          SocketService.on('analysis-update', this.analysisUpdate);
          SocketService.on('user-status', this.userStatus);
          SocketService.on('call-status', this.callStatus);
          SocketService.on('heartbeat', this.handleHeartbeat);
          SocketService.on('script-update', this.scriptUpdate);
          SocketService.on('update-barge', this.updateBarge);
      },
      updateBarge(data) {
          this.calls.forEach((rep) => {
              if(rep.id === data.salesRepId) {
                  if(data.status === 'open') {
                      rep.isListening = true;
                      rep.barging = true;
                      if(!rep.bargingName) {
                        rep.bargingName = data.managerName;
                      }
                  }
                  if(data.status === 'close') {
                      rep.isListening = false;
                      rep.barging = false;
                      rep.bargingName = null;
                  }
              }
          });
      },
      scriptUpdate(data) {
          console.log('data: ', JSON.stringify(data));
          this.updateCalls(data); 
      },
      updateCalls(input) {
          console.log("input: ", input);
          // Iterate through the existing calls and update the fields with input data
          this.calls.forEach((call) => {
          // Check if the current call matches the salesRepId or managerId
          //console.log('call.id: ', call.id);
          if (call.id === input.salesRepId || call.manager === input.managerId) {
              // Update call details based on input data
              //call.repName = input.data.name || call.repName;

              if(input.data.isNew) {
                  call.toRequested = false;
                  call.campaignId = null;
                  call.leadId = null;
                  call.callId = null;
                  call.barging = false;
                  call.bargingName = null;
                  call.ownerName = null;
                  call.status = null;
                  call.companyName = '';
                  call.industry = null;
                  call.website = null;
                  call.onlineMarketingBefore = null;
                  call.monthlyMarketingBudget = null;
                  call.moreBusiness = null;
                  call.freeMarketing = null;
                  call.state = null;
                  call.currentCallTime = 0;
                  call.isDialing = false;
                  call.isActive = false;
                  call.lastCallEndTime = new Date();
                  call.callStartTime = null;
                  this.$forceUpdate();
              } else {
                  call.campaignId = input.data.campaignId;
                  call.leadId = input.data.leadId;
                  //call.callId = input.data.callId;
                  call.ownerName = input.data.name;
                  call.status = input.data.confirmation ? (input.data.confirmation === "yes" ? "ongoing" : "completed") : '';
                  call.companyName = input.data.businessName || call.companyName;
                  call.industry = input.data.industry || '-';
                  call.website = input.data.hasWebsite ? input.data.website : '';
                  call.onlineMarketingBefore = input.data.hasDoneOnlineMarketing ? (input.data.hasDoneOnlineMarketing === "yes" ? "Yes" : "No") : '';
                  call.currentMarketing = input.data.clientsFrom.join(", ") || call.currentMarketing;
                  call.monthlyMarketingBudget = input.data.advertisingBudget.join(", ") || call.monthlyMarketingBudget;
                  call.moreBusiness = input.data.wantMoreBusiness ? (input.data.wantMoreBusiness === "yes" ? "Yes" : "No") : '';
                  call.freeMarketing = input.data.boostOnlinePresence ? (input.data.boostOnlinePresence === "yes" ? "Yes" : "No") : '';
                  call.state = input.data.state;
              }

              // You can add more fields here as required
          }
          });
      },
      handleNewMessage(message) {
          console.log('newMessage: ', message);
          if(message.sender == message.sales_rep_id) {
              this.calls.forEach((rep) => {
              if(rep.id == message.sales_rep_id) {
                  if(this.currentSalesRepId == message.sales_rep_id && this.chatVisible == true) {

                  } else {
                      rep.numUnread = rep.numUnread + 1;
                  }
                  
              }
              });
          }
      },
      notifyTORequest(data) {
          try {

              this.calls.forEach((rep) => {
                  if(rep.id == data.salesRepId) {
                      console.log("repTORequest: ", rep);
                      rep.toRequested = true;
                      this.callId = data.callId;
                      try {
                          // Find the latest day for the rep
                          const latestDay = Object.keys(rep.data).sort().pop();
                          const latestDayData = rep.data[latestDay];

                          // If latest day data is found, update the requestedTOs
                          if (latestDayData) {
                              // Update requestedTOs
                              latestDayData.requestedTOs = (latestDayData.requestedTOs || 0) + 1;
                              console.log(`Updated rep ${rep.id} on ${latestDay}. New talkTime: ${latestDayData.talkTime}, New humanCalls: ${latestDayData.humanCalls}`);
                          } else {
                              console.error(`No data found for the latest day for rep ${rep.id}`);
                          }
                      } catch (err) {
                          console.error('error on notifyTO: ', err);
                      }
                  }
              }); 
          } catch (err) { 
              console.error('error notifying manager of TO request: ', err);
          }
          },
          callUpdate(data) {
          // Iterate through the salesReps list to find the matching salesRepId
          this.calls.forEach((rep) => {
              if (rep.id === data.salesRepId) {
//                console.log("Updating rep: ", rep);

              // Find the latest day for the rep
              if(rep.data) {
                const latestDay = Object.keys(rep.data).sort().pop();
                const latestDayData = rep.data[latestDay];

                // If latest day data is found, update the talkTime and humanCalls
                if (latestDayData) {
                    // Update talkTime
                    //latestDayData.talkTime = data.talkTime;
                    // Update humanCalls (mapped from twoMinCalls)
                   // latestDayData.humanCalls = data.twoMinCalls;

                    console.log(`Updated rep ${rep.id} on ${latestDay}. New talkTime: ${latestDayData.talkTime}, New humanCalls: ${latestDayData.humanCalls}`);
                } else {
                    console.error(`No data found for the latest day for rep ${rep.id}`);
                }
              }

              }
          });
          },
          bargeUpdate(data) {
          // Iterate through the salesReps list to find the matching salesRepId
          console.log('bargeUpdate: ', data);
          this.calls.forEach((rep) => {
              if (rep.id === data.salesRepId) {
              // console.log("Updating barge count for rep: ", rep);

              // // Find the latest day for the rep
              // const latestDay = Object.keys(rep.data).sort().pop();
              // const latestDayData = rep.data[latestDay];

              // // If latest day data is found, increment the barges count
              // if (latestDayData) {
              //   latestDayData.barges = (latestDayData.barges || 0) + 1;
              //   console.log(`Barge count updated for ${rep.name} on ${latestDay}. New barges count: ${latestDayData.barges}`);
              // } else {
              //   console.error(`No data found for the latest day for rep: ${rep.id}`);
              // }
              }
          });
          },
          analysisUpdate(data) {
          // Iterate through the salesReps list to find the matching salesRepId
          this.calls.forEach((rep) => {
              if (rep.id === data.salesRepId) {
              console.log("Updating completedTOs for rep: ", rep);

              // Find the latest day for the rep
              const latestDay = Object.keys(rep.data).sort().pop();
              const latestDayData = rep.data[latestDay];

              // If latest day data is found, increment the completedTOs count
              if (latestDayData) {
                  latestDayData.completedTOs = (latestDayData.completedTOs || 0) + 1;
                  console.log(`Completed TOs updated for ${rep.name} on ${latestDay}. New completedTOs count: ${latestDayData.completedTOs}`);
              } else {
                  console.error(`No data found for the latest day for rep: ${rep.id}`);
              }
              }
          });
          },
          userStatus(userStatusData) {
          const { salesRepId, status, duration, connectedDuration } = userStatusData;
          // Find the sales rep in the list
          const rep = this.calls.find(rep => rep.id === salesRepId);
          if (!rep) {
              console.error(`Sales rep with id ${salesRepId} not found.`);
              return;
          }

          switch (status) {
              case 'ACTIVE':
              console.log(`Sales rep ${salesRepId} is now active.`);
              rep.isLoggedIn = true;
              rep.isPaused = false;
              rep.isActive = true;
              rep.lastLoggedInTime = rep.loggedInTime;
              //rep.loggedInTime = duration || 0;  // Reset or set logged in time
              //rep.talkTime = connectedDuration || 0;  // Set initial talk time if provided
              break;

              case 'OFFLINE':
              console.log(`Sales rep ${salesRepId} is now offline.`);
              rep.isLoggedIn = false;
              rep.isPaused = false;
              rep.isActive = false;
              rep.lastLoggedInTime = rep.loggedInTime;
              //rep.loggedInTime = duration || rep.loggedInTime;  // Finalize logged time
              rep.talkTime = connectedDuration || rep.talkTime;  // Finalize talk time
              // You can stop any timers here, if applicable
              break;

              case 'PAUSED':
              console.log(`Sales rep ${salesRepId} has paused.`);
              rep.isPaused = true;
              rep.isActive = false;
              //rep.loggedInTime = duration || rep.loggedInTime;  // Update logged time
              rep.talkTime = connectedDuration || rep.talkTime;  // Update talk time
              // Pause any ongoing call tracking or timers if applicable
              break;

              default:
              console.error(`Unknown status: ${status} for sales rep ${salesRepId}`);
          }
      },
      callStatus(callStatusData) {
          console.log('callStatusData: ', callStatusData);
          const { salesRepId, status, callEndTime, callId, leadId, azureId } = callStatusData;
          
          console.log('this.calls: ', this.calls);
          const rep = this.calls.find(rep => rep.id === salesRepId);
          if (!rep) {
              console.error(`Sales rep with id ${salesRepId} not found.`);
              return;
          }


          console.log('callStatus: ', rep);
          switch (status) {
              case 'DIALING':
              console.log(`Sales rep ${salesRepId} is dialing.`);
              rep.isDialing = true;
              rep.isActive = false;
              rep.isPaused = false;
              rep.callStartTime = null;
              rep.callId = callId;
              rep.leadId = leadId;
              rep.azureId = azureId;
              break;

              case 'ACTIVE':
              console.log(`Sales rep ${salesRepId} is on an active call.`);
              rep.isDialing = false;
              rep.isActive = true;
              rep.isPaused = false;
              rep.lastCallEndTime = null;
              rep.callStartTime = callStatusData.startTime ? new Date(callStatusData.startTime) : new Date();
              break;

              case 'PAUSED':
              console.log(`Sales rep ${salesRepId} has paused the call.`);
              rep.isDialing = false;
              rep.isActive = false;
              rep.isPaused = true;
              
              break;

              case 'INACTIVE':
              console.log(`Sales rep ${salesRepId} has ended the call.`);
              rep.isDialing = false;
              rep.isActive = false;
              rep.isPaused = false;
              rep.callStartTime = null;
              rep.callId = null;
              rep.isListening = false;
              rep.barging = false;
              rep.bargingName = null;
              this.handleCallEndEvent(callStatusData);  // Call handleCallEnd to process the call end
              break;

              default:
              console.error(`Unknown status: ${status} for sales rep ${salesRepId}`);
          }
          console.log('callStatus: ', rep);
          console.log('callStatus: ', this.calls);
          this.$forceUpdate();
      },
      async fetchManagers() {
          try {
          const params = {
              role: this.role,
              userId: this.userId
          };
          let response = null;
          if(this.role === 'Admins') {
              response = await axios.get('https://123avatars.com/v2/users?filter=Managers');
          }
          if(this.role === 'Managers') {
              response = await axios.get(`https://123avatars.com/v2/users?filter=Managers`);
          }
          this.managers = response.data
              .map(rep => 
                  rep.firstName + ' ' + rep.lastName)
              .sort((a, b) => a.localeCompare(b));
          } catch (error) {
          console.error('Error fetching sales reps:', error);
          }
          },
      async fetchSalesReps(target, isInit) {
          let role = await AuthService.getUserRole();
          console.log('role: ', role);
          console.log('path: ', this.$route.path);
          target += '?type=panel';
          if(role == 'Admins' || role == 'Recruiter_Managers' || role == 'Recruiters') {
              //target += '?id=' + this.$route.query.id;
          }
          
          if(role == 'Admins' && this.$route.path == '/manager-dashboard') {
              target += '&id=' + this.$route.query.id;
          }
          if(role == 'Managers') {
              // target += '&id=' + await AuthService.getCognitoSub();
          }
          axios.get(target)
              .then(response => {
                  if(isInit) {
                    const temp = [
                    {
        "id": "29b939ae-a031-709a-c515-68c9e4d8a307_31",
        "name": "AI Operator 41",
        "data": {
            "Wed, Jan 15, 2025": {
                "startTime": "2025-01-15 19:55:10",
                "loggedTime": 0,
                "talkTime": "0",
                "calls": null,
                "humanCalls": null,
                "barges": 0,
                "bargeDuration": 0,
                "requestedTOs": null,
                "completedTOs": null,
                "missedTOs": null,
                "finished_scripts": null,
                "operator_tos": null,
                "spiffIncome": 0,
                "voicemails": null,
                "timezone": "America/New_York"
            },
            "Thu, Jan 16, 2025": {
                "startTime": "2025-01-16 21:48:54",
                "loggedTime": 0,
                "talkTime": "0",
                "calls": null,
                "humanCalls": null,
                "barges": 0,
                "bargeDuration": 0,
                "requestedTOs": null,
                "completedTOs": null,
                "missedTOs": null,
                "finished_scripts": null,
                "operator_tos": null,
                "spiffIncome": 0,
                "voicemails": null,
                "timezone": "America/New_York"
            },
            "Fri, Jan 17, 2025": {
                "startTime": "2025-01-17 15:56:54",
                "loggedTime": 0,
                "talkTime": "0",
                "calls": null,
                "humanCalls": null,
                "barges": 0,
                "bargeDuration": 0,
                "requestedTOs": null,
                "completedTOs": null,
                "missedTOs": null,
                "finished_scripts": null,
                "operator_tos": null,
                "spiffIncome": 0,
                "voicemails": null,
                "timezone": "America/New_York"
            },
            "Sat, Jan 18, 2025": {
                "startTime": "2025-01-18 18:25:16",
                "loggedTime": 0,
                "talkTime": "0",
                "calls": null,
                "humanCalls": null,
                "barges": 0,
                "bargeDuration": 0,
                "requestedTOs": null,
                "completedTOs": null,
                "missedTOs": null,
                "finished_scripts": null,
                "operator_tos": null,
                "spiffIncome": 0,
                "voicemails": null,
                "timezone": "America/New_York"
            },
            "Mon, Jan 20, 2025": {
                "startTime": "2025-01-20 15:56:32",
                "loggedTime": 0,
                "talkTime": "0",
                "calls": null,
                "humanCalls": null,
                "barges": 0,
                "bargeDuration": 0,
                "requestedTOs": null,
                "completedTOs": null,
                "missedTOs": null,
                "finished_scripts": null,
                "operator_tos": null,
                "spiffIncome": 0,
                "voicemails": null,
                "timezone": "America/New_York"
            },
            "Fri, Jan 24, 2025": {
                "startTime": "2025-01-24 21:21:24",
                "loggedTime": 0,
                "talkTime": "0",
                "calls": null,
                "humanCalls": null,
                "barges": 0,
                "bargeDuration": 0,
                "requestedTOs": null,
                "completedTOs": null,
                "missedTOs": null,
                "finished_scripts": null,
                "operator_tos": null,
                "spiffIncome": 0,
                "voicemails": null,
                "timezone": "America/New_York"
            },
            "Mon, Jan 27, 2025": {
                "startTime": "2025-01-27 18:36:58",
                "loggedTime": 0,
                "talkTime": "0",
                "calls": null,
                "humanCalls": null,
                "barges": 0,
                "bargeDuration": 0,
                "requestedTOs": null,
                "completedTOs": null,
                "missedTOs": null,
                "finished_scripts": null,
                "operator_tos": null,
                "spiffIncome": 0,
                "voicemails": null,
                "timezone": "America/New_York"
            },
            "Tue, Jan 28, 2025": {
                "startTime": "2025-01-28 15:59:54",
                "loggedTime": 0,
                "talkTime": "0",
                "calls": null,
                "humanCalls": null,
                "barges": 0,
                "bargeDuration": 0,
                "requestedTOs": null,
                "completedTOs": null,
                "missedTOs": null,
                "finished_scripts": null,
                "operator_tos": null,
                "spiffIncome": 0,
                "voicemails": null,
                "timezone": "America/New_York"
            },
            "Wed, Jan 29, 2025": {
                "startTime": "2025-01-29 21:15:10",
                "loggedTime": 0,
                "talkTime": "0",
                "calls": null,
                "humanCalls": null,
                "barges": 0,
                "bargeDuration": 0,
                "requestedTOs": null,
                "completedTOs": null,
                "missedTOs": null,
                "finished_scripts": null,
                "operator_tos": null,
                "spiffIncome": 0,
                "voicemails": null,
                "timezone": "America/New_York"
            },
            "Thu, Jan 30, 2025": {
                "startTime": "2025-01-30 17:30:44",
                "loggedTime": 0,
                "talkTime": "0",
                "calls": null,
                "humanCalls": null,
                "barges": 0,
                "bargeDuration": 0,
                "requestedTOs": null,
                "completedTOs": null,
                "missedTOs": null,
                "finished_scripts": null,
                "operator_tos": null,
                "spiffIncome": 0,
                "voicemails": null,
                "timezone": "America/New_York"
            },
            "Fri, Jan 31, 2025": {
                "startTime": "2025-01-31 19:09:02",
                "loggedTime": 0,
                "talkTime": "0",
                "calls": null,
                "humanCalls": null,
                "barges": 0,
                "bargeDuration": 0,
                "requestedTOs": null,
                "completedTOs": null,
                "missedTOs": null,
                "finished_scripts": null,
                "operator_tos": null,
                "spiffIncome": 0,
                "voicemails": null,
                "timezone": "America/New_York"
            },
            "Mon, Feb 03, 2025": {
                "startTime": "2025-02-03 16:04:32",
                "loggedTime": 0,
                "talkTime": "0",
                "calls": null,
                "humanCalls": null,
                "barges": 0,
                "bargeDuration": 0,
                "requestedTOs": null,
                "completedTOs": null,
                "missedTOs": null,
                "finished_scripts": null,
                "operator_tos": null,
                "spiffIncome": 0,
                "voicemails": null,
                "timezone": "America/New_York"
            },
            "Tue, Feb 04, 2025": {
                "startTime": "2025-02-04 17:15:44",
                "loggedTime": 0,
                "talkTime": "0",
                "calls": null,
                "humanCalls": null,
                "barges": 0,
                "bargeDuration": 0,
                "requestedTOs": null,
                "completedTOs": null,
                "missedTOs": null,
                "finished_scripts": null,
                "operator_tos": null,
                "spiffIncome": 0,
                "voicemails": null,
                "timezone": "America/New_York"
            },
            "Wed, Feb 05, 2025": {
                "startTime": "2025-02-05 18:38:38",
                "loggedTime": 0,
                "talkTime": "0",
                "calls": null,
                "humanCalls": null,
                "barges": 0,
                "bargeDuration": 0,
                "requestedTOs": null,
                "completedTOs": null,
                "missedTOs": null,
                "finished_scripts": null,
                "operator_tos": null,
                "spiffIncome": 0,
                "voicemails": null,
                "timezone": "America/New_York"
            },
            "Fri, Feb 07, 2025": {
                "startTime": "2025-02-07 18:16:12",
                "loggedTime": 0,
                "talkTime": "0",
                "calls": null,
                "humanCalls": null,
                "barges": 0,
                "bargeDuration": 0,
                "requestedTOs": null,
                "completedTOs": null,
                "missedTOs": null,
                "finished_scripts": null,
                "operator_tos": null,
                "spiffIncome": 0,
                "voicemails": null,
                "timezone": "America/New_York"
            },
            "Mon, Feb 10, 2025": {
                "startTime": "2025-02-10 19:20:24",
                "loggedTime": 0,
                "talkTime": "0",
                "calls": null,
                "humanCalls": null,
                "barges": 0,
                "bargeDuration": 0,
                "requestedTOs": null,
                "completedTOs": null,
                "missedTOs": null,
                "finished_scripts": null,
                "operator_tos": null,
                "spiffIncome": 0,
                "voicemails": null,
                "timezone": "America/New_York"
            },
            "Tue, Feb 11, 2025": {
                "startTime": "2025-02-11 16:23:01",
                "loggedTime": 0,
                "talkTime": "0",
                "calls": null,
                "humanCalls": null,
                "barges": 0,
                "bargeDuration": 0,
                "requestedTOs": null,
                "completedTOs": null,
                "missedTOs": null,
                "finished_scripts": null,
                "operator_tos": null,
                "spiffIncome": 0,
                "voicemails": null,
                "timezone": "America/New_York"
            },
            "Wed, Feb 12, 2025": {
                "startTime": "2025-02-12 16:27:42",
                "loggedTime": 0,
                "talkTime": "0",
                "calls": null,
                "humanCalls": null,
                "barges": 0,
                "bargeDuration": 0,
                "requestedTOs": null,
                "completedTOs": null,
                "missedTOs": null,
                "finished_scripts": null,
                "operator_tos": null,
                "spiffIncome": 0,
                "voicemails": null,
                "timezone": "America/New_York"
            },
            "Thu, Feb 13, 2025": {
                "startTime": "2025-02-13 17:15:16",
                "loggedTime": 0,
                "talkTime": "0",
                "calls": null,
                "humanCalls": null,
                "barges": 0,
                "bargeDuration": 0,
                "requestedTOs": null,
                "completedTOs": null,
                "missedTOs": null,
                "finished_scripts": null,
                "operator_tos": null,
                "spiffIncome": 0,
                "voicemails": null,
                "timezone": "America/New_York"
            },
            "Sat, Feb 15, 2025": {
                "startTime": "2025-02-15 22:09:45",
                "loggedTime": 0,
                "talkTime": "167",
                "calls": 2,
                "humanCalls": "1",
                "barges": 0,
                "bargeDuration": 0,
                "requestedTOs": "0",
                "completedTOs": "0",
                "missedTOs": "0",
                "finished_scripts": "0",
                "operator_tos": "0",
                "spiffIncome": 0,
                "voicemails": "0",
                "timezone": "America/New_York"
            },
            "Mon, Feb 17, 2025": {
                "startTime": "2025-02-17 22:45:38",
                "loggedTime": 0,
                "talkTime": "0",
                "calls": null,
                "humanCalls": null,
                "barges": 0,
                "bargeDuration": 0,
                "requestedTOs": null,
                "completedTOs": null,
                "missedTOs": null,
                "finished_scripts": null,
                "operator_tos": null,
                "spiffIncome": 0,
                "voicemails": null,
                "timezone": "America/New_York"
            },
            "Tue, Feb 18, 2025": {
                "startTime": "2025-02-18 21:17:49",
                "loggedTime": 0,
                "talkTime": "0",
                "calls": null,
                "humanCalls": null,
                "barges": 0,
                "bargeDuration": 0,
                "requestedTOs": null,
                "completedTOs": null,
                "missedTOs": null,
                "finished_scripts": null,
                "operator_tos": null,
                "spiffIncome": 0,
                "voicemails": null,
                "timezone": "America/New_York"
            },
            "Sun, Feb 23, 2025": {
                "startTime": "2025-02-23 18:57:46",
                "loggedTime": 0,
                "talkTime": "0",
                "calls": null,
                "humanCalls": null,
                "barges": 0,
                "bargeDuration": 0,
                "requestedTOs": null,
                "completedTOs": null,
                "missedTOs": null,
                "finished_scripts": null,
                "operator_tos": null,
                "spiffIncome": 0,
                "voicemails": null,
                "timezone": "America/New_York"
            },
            "Tue, Feb 25, 2025": {
                "startTime": "2025-02-25 16:46:35",
                "loggedTime": 0,
                "talkTime": "0",
                "calls": null,
                "humanCalls": null,
                "barges": 0,
                "bargeDuration": 0,
                "requestedTOs": null,
                "completedTOs": null,
                "missedTOs": null,
                "finished_scripts": null,
                "operator_tos": null,
                "spiffIncome": 0,
                "voicemails": null,
                "timezone": "America/New_York"
            },
            "Wed, Feb 26, 2025": {
                "startTime": "2025-02-26 16:17:39",
                "loggedTime": 0,
                "talkTime": "4251",
                "calls": 171,
                "humanCalls": "6",
                "barges": 3,
                "bargeDuration": 174,
                "requestedTOs": "0",
                "completedTOs": "0",
                "missedTOs": "0",
                "finished_scripts": "3",
                "operator_tos": "3",
                "spiffIncome": 0,
                "voicemails": "0",
                "timezone": "America/New_York"
            },
            "Thu, Feb 27, 2025": {
                "startTime": "2025-02-27 16:02:28",
                "loggedTime": 0,
                "talkTime": "4109",
                "calls": 161,
                "humanCalls": "2",
                "barges": 0,
                "bargeDuration": 0,
                "requestedTOs": "1",
                "completedTOs": "0",
                "missedTOs": "1",
                "finished_scripts": "2",
                "operator_tos": "2",
                "spiffIncome": 0,
                "voicemails": "0",
                "timezone": "America/New_York"
            },
            "Fri, Feb 28, 2025": {
                "startTime": "2025-02-28 16:33:16",
                "loggedTime": 0,
                "talkTime": "499",
                "calls": 21,
                "humanCalls": "0",
                "barges": 0,
                "bargeDuration": 0,
                "requestedTOs": "0",
                "completedTOs": "0",
                "missedTOs": "0",
                "finished_scripts": "0",
                "operator_tos": "0",
                "spiffIncome": 0,
                "voicemails": "0",
                "timezone": "America/New_York"
            }
        },
        "hasData": true,
        "isLoggedIn": true,
        "repLink": "",
        "shelfLife": 13,
        "currentCallTime": 0,
        "loggedInTime": 0,
        "lastLoggedInTime": 0,
        "timeSinceLastCall": 26,
        "isListening": false,
        "numUnread": 0,
        "managerId": "7919399e-2061-703b-c616-efdaa98571fe",
        "manager": "Dayne Beronque",
        "sip_did": "+13122373851",
        "cell": "+2672342397",
        "lastCallEndTime": "2025-02-28T16:48:39.000Z",
        "toRequested": false,
        "sales": 0,
        "callsPerSale": 0,
        "income": 0,
        "incomePerHour": 0,
        "activeCalls": [
            {
                "salesRepId": "29b939ae-a031-709a-c515-68c9e4d8a307_31",
                "managerId": 111,
                "callControlId": "v3:BiWXFFu3xbKohfKzf_QEG4VIVfRE__98-bvegkMTYI-OquY3u30ZWQ",
                "callId": 2145082,
                "status": "ACTIVE",
                "company": {
                    "id": 22693061,
                    "firstName": null,
                    "lastName": null,
                    "companyName": "Pranic Healing - Sanación Pránica 972 - Suite 9 - SP972",
                    "phoneNumber": "8134060596",
                    "isCell": true,
                    "label": null,
                    "carrier": "T-Mobile USA Inc.",
                    "timeZone": "America/New_York",
                    "addressLine1": "201 Douglas Rd E Suite 9",
                    "addressLine2": null,
                    "city": "Oldsmar",
                    "state": "FL",
                    "zip": "34677",
                    "website": "https://www.sp972.com/",
                    "industry": "Holistic medicine practitioner",
                    "amountRemaining": 5507423,
                    "createDate": "2024-06-01T23:51:05.2+00:00",
                    "lastUpdateDate": null,
                    "lead_id": 519485452
                },
                "instanceId": "i-068feb8426b65552e",
                "repCallAnswered": true,
                "startTime": "2025-02-28T16:49:21.181Z"
            },
            {
                "salesRepId": "29b939ae-a031-709a-c515-68c9e4d8a307_31",
                "managerId": 111,
                "callControlId": "v3:D0ZTSdfPLHDi5uk8EyAY8YmAy1YzgZt0TALmgPyChd2327AUmsTPmQ",
                "callId": 2145086,
                "status": "DIALING",
                "company": {
                    "id": 22693073,
                    "firstName": null,
                    "lastName": null,
                    "companyName": "Essential Rejuvenation",
                    "phoneNumber": "8138338427",
                    "isCell": true,
                    "label": null,
                    "carrier": "Cellco Partnership (Verizon Wireless)",
                    "timeZone": "America/New_York",
                    "addressLine1": "4559 Gunn Hwy",
                    "addressLine2": null,
                    "city": "Tampa",
                    "state": "FL",
                    "zip": "33624",
                    "website": "https://erejuvenate.glossgenius.com/services?hl=en-US&gei=B6_FZcLuMLaj5NoPiI20iAY&rwg_token=AAh05qZLJe47CeqTIIsbFBiMlO_QPMSscbgBl-GvWI7kB0CPUrHBo--JMGJUv3t2heF4iDRN7TDvg6mNSbQNin-C_vn1aFzwFQ%3D%3D&sou",
                    "industry": "Health spa",
                    "amountRemaining": 5507423,
                    "createDate": "2024-06-01T23:51:05.2+00:00",
                    "lastUpdateDate": null,
                    "lead_id": 519485456
                },
                "instanceId": "i-068feb8426b65552e"
            }
        ]
    }
                    ];
                      this.calls = this.mapInputToCalls(response.data);
                  } else {
                      // this.calls = this.mapStatsToCalls(response.data);
                  }
                  console.log('salesReps: ', this.calls);
              })
              .catch(error => {
                  console.error('Error fetching sales reps data:', error);
              });
      },
      handleCallEndEvent(callEndData) {
          const rep = this.calls.find(rep => rep.id === callEndData.salesRepId);
          if (rep) {
              rep.currentCallTime = 0;
              rep.lastCallEndTime = new Date();
              rep.callStartTime = null;
              rep.toRequested = false;
              rep.isListening = false;
              rep.barging = false;
              rep.bargingName = null;
             // this.startLocalTimerForRep(rep);
              //this.startTimeSinceLastCallTimer(rep, lastCallEndTime);
          }
      },
      bargeCall(call) {
          console.log('call: ', call);
          axios.get(`https://123avatars.com/v2/barge?id=${call.id}&managerId=${call.managerId}&callId=${call.callId}&managerNum=${encodeURIComponent(this.managerNum)}`)
              .then((resp) => {
              if(resp.data.message) {
                  alert(resp.data.message);
                  this.closeBarge(call);
              } else {
                  this.makeCall(this.managerNum, this.managerNum, call);
              }
              }).catch((err) => {
              console.error("error barging call: ", err);
              });
      },
      async connect() {
          let id = this.$route.query.id;
          let managerNum = '';
          let user = '';
          let pass = '';
          let callerIdNum = '';
              // managerNum = '+13125300209';
              // user = this.usernameTwo;
              // pass = this.passwordTwo;
              // callerIdNum = this.callerIdTwo;
          switch(this.managerId) {
              case 'a91929ae-5001-70bf-fd1e-4d5204684d61':
                  managerNum = this.callerIdThree;
                  user = this.usernameThree;
                  pass = this.passwordThree;
                  callerIdNum = this.callerIdThree;
              break;
              case 'd9e9092e-60c1-7084-1342-39cb2e828161':
                  managerNum = this.callerIdFour;
                  user = this.usernameFour;
                  pass = this.passwordFour;
                  callerIdNum = this.callerIdFour;
              break;
              case '79e929de-f051-7048-e073-f77be568042f':
                  managerNum = this.callerIdFive;
                  user = this.usernameFive;
                  pass = this.passwordFive;
                  callerIdNum = this.callerIdFive;
              break;
              case '4909c94e-70e1-707f-8295-f755c175b507':
                  managerNum = this.callerIdSix;
                  user = this.usernameSix;
                  pass = this.passwordSix;
                  callerIdNum = this.callerIdSix;
              break;
              case '0919a93e-8071-701c-951b-94daa410a59c':
                  managerNum = this.callerIdSeven;
                  user = this.usernameSeven;
                  pass = this.passwordSeven;
                  callerIdNum = this.callerIdSeven;
              break;
              case '5929c9ae-30a1-70c7-2a5c-5b8bb24d4609':
                  managerNum = this.callerIdEight;
                  user = this.usernameEight;
                  pass = this.passwordEight;
                  callerIdNum = this.callerIdEight;
              break;
              default:
                  managerNum = this.callerIdTwo;
                  user = this.usernameTwo;
                  pass = this.passwordTwo;
                  callerIdNum = this.callerIdTwo;
          }
          this.managerNum = managerNum;
          try {
              this.telnyxRTC = new TelnyxRTC({
              login: user,
              password: pass,
              callerIdNumber: callerIdNum,
              debug: this.enableDebugging,
              logLeveL: 'debug',
              });
          } catch (err) {
              console.error("error init listening", err)
          }
          this.telnyxRTC.on('telnyx.socket.open', () => {
              console.log('Socket opened');
          });

          this.telnyxRTC.on('telnyx.socket.error', (error) => {
              console.error('Socket error', error);
          });

          this.telnyxRTC.on('telnyx.socket.close', () => {
              console.log('Socket closed');
          });

          this.telnyxRTC.on('telnyx.ready', () => {
              console.log('Telnyx ready');
          });

          this.telnyxRTC.on('telnyx.error', (error) => {
              console.error('Telnyx error', error);
          });

          this.telnyxRTC.on('telnyx.call.incoming', (call) => {
              // this.call = call;
              console.log('Incoming call', call);
          });

          this.telnyxRTC.on('telnyx.call.answered', (call) => {
              console.log('Call answered', call);
              //this.attachStreamToAudioElement(this.call.remoteStream);
          });

          this.telnyxRTC.on('telnyx.call.hangup', (call) => {
              console.log('Call hung up', call);
          });

          this.telnyxRTC.on('telnyx_rtc.invite', (call) => {
              console.log('call: ', call);
          });

          this.telnyxRTC.on('telnyx.notification', async (notification) => {
              console.log('telnyxNotification: ', notification);
              try {
              const call = notification.call;


              if (notification.type === 'callUpdate' && call.state === 'ringing') {
                  if(call.options.remoteCallerNumber == "9492884866" || call.options.remoteCallerNumber == "3125300209" ||
                  call.options.remoteCallerNumber == "+19492884866" || call.options.remoteCallerNumber == "+13125300209"
                  ) {
                  // call.answer();
                  // if(this.call) {
                  //   this.mergeCalls(this.call, call);
                  // }
                  }
              }
              if (notification.type === 'callUpdate') {
              if (notification.call.state === 'active') {
                  try {
                  this.listeningCall = notification.call;
                  this.localAudioTrack = this.listeningCall.localStream.getAudioTracks()[0];
                  this.localAudioTrack.enabled = false;
                  this.attachStreamToAudioElement(notification.call.remoteStream);
                  } catch (err) {
                  console.error('error attaching stream to audio element: ', err);
                  }
              }
              }
              } catch (err) {
              console.log("Error answering call: ", err);
              }
          });

           await this.telnyxRTC.connect();
          },
          async toggleMute(call) {
              console.log('toggleMute: ', this.isMuted);
              console.log('call: ', call);
              this.isMuted = !this.isMuted;
              const timestamp = new Date().toISOString();
              const callId = call.callId; // Replace with actual call ID
              console.log('calId: ', callId);
              const managerId = this.managerId; // Replace with actual manager ID
              const repId = call.id;

              if (this.localAudioTrack) {
                  this.localAudioTrack.enabled = !this.localAudioTrack.enabled;
              }

              try {
                  if (this.isMuted) {
                      // async mark mute time
                      const response = await axios.post('https://123avatars.com/v2/log-barge-event', {
                          call_id: callId,
                          manager_id: managerId,
                          rep_id: repId,
                          timestamp: timestamp,
                          event_type: 'mute'
                      });
                      console.log(response.data);
                  } else {
                      // async mark unmute time
                      const response = await axios.post('https://123avatars.com/v2/log-barge-event', {
                          call_id: callId,
                          manager_id: managerId,
                          rep_id: repId,
                          timestamp: timestamp,
                          event_type: 'unmute'
                      });
                      console.log(response.data);
                  }
              } catch (error) {
                  console.error('Error logging event:', error);
              }
          },
          makeCall(fromNum, toNum, call) {
              console.log("fromNum: ", fromNum);
              console.log("toNum: ", toNum);
              if (this.telnyxRTC) {
                  if (toNum.length === 10) {
                  toNum = '+1' + toNum;
                  }
                  fromNum = this.managerNum;
                  this.currentCall = this.telnyxRTC.newCall({
                  destinationNumber: fromNum,
                  callerNumber: fromNum,
                  });
                  this.isListening = true;
              }
              let data = {
                  event: 'update-barge',
                  salesRepId: call.id,
                  managerId: call.managerId,
                  callId: call.callId,
                  status: 'open'
              };
              SocketService.emit('update-barge', data);
            //   this.$emit('open-call-prospect', {
            //         salesRepId: call.id,
            //         azureContactId: row.azureId,
            //         leadId: call.leadId,
            //         phone: '123',
            //         fromInbox: true,
            //         makeCall: false
            //     });
          },
          attachStreamToAudioElement(stream) {
          const audioElement = document.getElementById('manager-audio');
          audioElement.srcObject = stream;
          console.log('audioElementAttached: ', audioElement);
          },
          connectAudio() {
          if (this.currentCall) {
              this.currentCall.options.audio = true;
              this.muteCall();
          }
          },
          muteCall() {
          if (this.currentCall) {
              this.currentCall.muteAudio();
              this.isMuted = true;
          }
          },
          unmuteCall() {
          if (this.currentCall) {
              this.currentCall.unmuteAudio();
              this.isMuted = false;
          }
      },
      handleHeartbeat(heartbeatData) {
          //console.log('heartbeatData: ', heartbeatData);
          const rep = this.calls.find(rep => rep.id === heartbeatData.salesRepId);
          if (rep) {
              this.updateRepFromHeartbeat(rep, heartbeatData);
          }
      },
      updateRepFromHeartbeat(rep, heartbeatData) {
      // Update the rep's data from the latest heartbeat
          if(heartbeatData.onCall) {
              if (!rep.isActive) {
                  rep.isActive = true;
                  rep.callId = heartbeatData.callId;
                  rep.toRequested = heartbeatData.toRequested;
                  rep.callStartTime = new Date(heartbeatData.callStartTime);
                  rep.lastCallEndTime = null;

                  // Calculate currentCallTime as the difference between now and callStartTime in seconds
                  const now = new Date();
                  rep.currentCallTime = Math.floor((now - rep.callStartTime) / 1000);
              }
          } else {
              if(rep.isActive) {
                  rep.callStartTime = null;
                  rep.callId = null;
                  this.updateCalls({
                      salesRepId: rep.id,
                      data: {
                          isNew: true
                      }
                  });
              }
          }
          this.startLocalTimerForRep(rep);


          // rep.isDialing = false;
          // rep.isActive = true;
      //  if(rep.isLoggedIn) {
      //     if(heartbeatData.loggedInTime != 0) {
      //     let currDiff = rep.loggedInTime - rep.lastLoggedInTime;
      //     if(currDiff != heartbeatData.loggedInTime) {
      //         rep.loggedInTime = rep.lastLoggedInTime + heartbeatData.loggedInTime;
      //     }
      //     }
      
      //rep.talkTime = heartbeatData.talkTime;
      //rep.currentCallTime = heartbeatData.currentCallTime;

      // console.log('rep: ', rep);

      // Start local incrementing until the next heartbeat arrives
      // this.startLocalTimerForRep(rep);
      },
      startLocalTimerForRep(rep) {
      //      console.log('startTimer: ', rep); 
          if (rep.localTimer) {
              clearInterval(rep.localTimer); // Clear existing timer if running
              rep.localTimer = null;
          }

          // Start a new local timer to increment time every second
          rep.localTimer = setInterval(() => {
              //if() // call status if user ACTIVE increment
          //  console.log('tick');
              if(rep.isLoggedIn) {
              rep.loggedInTime += 1;
              }
              if(rep.isActive == true) {
              rep.currentCallTime += 1;
              rep.timeSinceLastCall = 0;
              } else {
              if(rep.timeSinceLastCall != -1) {
                  rep.timeSinceLastCall += 1;
              }
              }
          //   console.log(`Local time incremented for rep ${rep.id}`);
          }, 1000);
      },
  },
  async mounted() {
      await this.setManagerId();
      console.log('managerId: ', this.managerId);
      this.configureSockets();
      this.fetchSalesReps('https://123avatars.com/v2/scoreboard', true);
      this.fetchManagers();
    //   setInterval(() => {
    //       this.fetchSalesReps('https://123avatars.com/v2/scoreboard', false);
    //   }, 10000);
      this.interval = setInterval(() => {
          this.now = new Date();
      }, 1000);
      this.connect();

  //   setInterval(() => {
  //     this.calls.forEach(call => {
  //       if (call.status === 'ongoing') {
  //         call.seconds += 1;
  //         const minutes = Math.floor(call.seconds / 60);
  //         const seconds = call.seconds % 60;
  //         call.length = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  //       } else if (call.status === 'completed') {
  //         call.lastCallSeconds += 1;
  //         const minutes = Math.floor(call.lastCallSeconds / 60);
  //         const seconds = call.lastCallSeconds % 60;
  //         call.timeSinceLastCall = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  //       }
  //     });
  //   }, 1000);
  }
};
</script>

<style scoped>
.consistent-barge-button {
  background-color: #ff9800;
}

.consistent-barge-button:hover {
  background-color: #e68900;
}

.single-barge {
  background-color: #28a745;
}

.single-barge:hover {
  background-color: #218838;
}
.ringing {
  background-color: #fbf2ec;
}
.one-min {
      background-color: #ffff00;
  }
  .over-one-min {
      background-color: #00ff00;
  }
.connected {
      background-color: yellow;
  }
  .two-min {
      background-color: lightgreen;
  }
  .five-min {
      background-color: lightsalmon;
  }
  .dialing {
      background-color: #F9B400;
      color: #fff;
  }  

.chat-box {
  position: fixed;
  bottom: 10px;
  right: 10px;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 250px;
}

.table-responsive {
  overflow-x:scroll;
}

.barges-column {
  min-width: 100px; /* Adjust this width as needed */
  text-align: center; /* Center the text */
}

#call-panel .btn {
color: white !important;

}

#call-panel .btn:hover {
color: white;
background-color: #0056b3; /* Optional: Adjust hover background color */
}

#call-panel .chat-button {
color: white !important; /* Ensure text color for chat button */
}

#call-panel .single-barge {
color: white !important; /* Ensure text color for barge buttons */
}

#call-panel .consistent-barge-button {
color: white !important; /* Ensure text color for consistent barge buttons */
}

#call-panel .btn-success {
color: white !important; /* Ensure text color for Sign Up button */
background-color: #28a745; /* Optional: Green success button background */
}

</style>