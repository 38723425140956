<template>
  <div>
    <div id="team-chat-container">
      <div class="row">
        <!-- Contacts List -->
        <div class="col-md-4">
          <div class="box">
            <div class="header px-2">
              <span class="contacts-header">
                <div class="contact-title">
                  <span class="bold">Contacts</span>
                </div>
                <div v-if="role === 'Admins'" class="select-button">
                  <select class="form-control filter-dropdown" v-model="is_active" @change="getContacts">
                    <option value="1">Active</option>
                    <option value="0">Deactivated</option>
                  </select>
                  <!-- team chat broadcast button -->
                  <div v-if="role === 'Admins' || role === 'Managers'">
                    <b-button class="broadcast-button" @click="openBroadcastModal">
                      <i class="fa-solid fa-bullhorn"></i>
                    </b-button>
                  </div>
                </div>
              </span>
            </div>
            <!-- Search Component -->
            <div class="contactlist-container ">
              <div class="search-container m-0"> <!--  -->
                <div v-if="role === 'Admins' || role === 'Managers'"
                  :class="`search-box ${role ? role.toLowerCase() : ''}`">
                  <input type="text" placeholder="Search Contacts" v-model="searchQuery"
                    @keyup.enter="fetchSearchResults" />
                  <button :class="`search-icon ${role ? role.toLowerCase() : ''}`" @click="fetchSearchResults">
                    <i class="fa fa-search" style="color: #FFF !important" />
                  </button>
                </div>
              </div>
              <!-- contact list group -->
              <div class="list-group ">
                <b-list-group-item v-for="contact in contacts" :key="contact.id"
                  class="d-flex justify-content-between gap-1 align-items-center p-1" @click="selectContact(contact)">
                  <div class="d-flex align-items-center">
                    <div class="icon-circle">
                      {{ getInitials(`${contact.FirstName} ${contact.LastName}`) }}
                    </div>
                    <div>
                      <p class="mb-0 small">
                        {{ contact.FirstName }} {{ contact.LastName }}
                      </p>
                      <p class="text-muted small-text mb-0">
                        {{ contact.role }}
                      </p>
                    </div>
                  </div>
                  <div class="d-flex flex-column align-items-end">
                    <span v-if="contact.last_message_time" class="text-muted small">
                      {{ formatContactTimestamp(contact.last_message_time) }}
                    </span>
                    <!-- <div v-else class="mt-1 d-flex align-items-center">
                      <span style="display:inline-block" class="badge badge-success p-1 mr-1"></span>
                      <span class="text-muted small">Online</span>
                    </div> -->
                    <span v-if="contact.unread_messages > 0" class="unread-badge">
                      {{ contact.unread_messages }}
                    </span>
                  </div>
                </b-list-group-item>
              </div>
            </div>
          </div>
        </div>
        <!-- Chat Window  -->
        <div class="col-md-8">

          <div class="box">
            <div class="header">
              <div class="title d-flex justify-content-between w-100">
                <span class="semi-bold">Chat with <span class="contact-name bold">
                    {{ selectedContact ? `${selectedContact.FirstName || ''} ${selectedContact.LastName || ''}`.trim() :
                      '...' }}
                  </span> — {{ selectedContact?.role || '' }}</span>
                <span>
                  <!-- @click="initiateCall" -->
                  <b-button class="broadcast-button">
                    <i class="fa-solid fa-phone"></i>
                  </b-button>
                </span>
              </div>
            </div>
            <!-- <div v-if="incomingCall">
              <p>Incoming call from Admin: {{ incomingCall.adminId }}</p>
              <button @click="acceptCall">Accept</button>
              <button @click="rejectCall">Reject</button>
            </div> -->

            <div class="conversation conversationContainer gap-10" ref="chatMessages">
              <div v-for="message in messages" :key="message.id"
                :class="message.SenderID == userId ? 'bubble-row right' : 'bubble-row left'">
                <!-- current userid  -->
                <div class="bubble-container">
                  <div class="bubble ">
                    <span>{{ message.text }}</span>
                    <svg class="tail" :viewBox="'0 0 23 24'">
                      <path v-if="message.SenderID == userId" d="M26.5 24.5L12 0.5L0.5 15.5L26.5 24.5Z" />
                      <!-- current userid  -->
                      <path v-else d="M0 24.5L14.5 0.5L26 15.5L0 24.5Z" />
                    </svg>
                  </div>
                  <div class="d-flex mt-2">
                    <div class="d-flex flex-column">
                      <div class="subtitle m-0">
                        <b class="bold">
                          {{ message.SenderID == userId ? 'You' : `${selectedContact.FirstName || ''}
                          ${selectedContact.LastName || ''}`.trim() }} <!-- current userid  -->
                        </b>
                      </div>
                      <!-- <div class=""> {{ message.SenderID }}</div> -->
                      <div class="subtitle m-0">{{ formatChatTimestamp(message.time) }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- chat input -->
            <div class="chat-input d-flex flex-column p-4">
              <textarea v-model="messageInput" rows="4" class="form-control message-input position-relative"
                @keydown.enter.prevent="sendMessage" @input="updateWordCount" maxlength="320"
                placeholder="Type a message or note..."> </textarea>
              <div class="input-footer">
                <span class="word-count">{{ messageInput.length }}/320</span>
                <b-button @click="sendMessage" class="send-button"
                  :disabled="role === 'SalesRep' && selectedContact.role === 'Admin' && !canSendMessageToAdmin">Send</b-button>
              </div>
              <div v-if="role === 'SalesRep' && selectedContact.role === 'Admin' && !canSendMessageToAdmin"
                class="text-danger mt-2">
                You can only reply to Admin messages within 8 hours of receiving them.
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
    <!-- Broadcast Modal -->
    <teamChatBroadcastModal :isOpen="showBroadcastModal" :salesReps="salesReps" :adminId="userId"
      @update:isOpen="showBroadcastModal = $event" />
  </div>
</template>

<script>
import axios from 'axios';
import AuthService from '../../_services/AuthService.js';
import UIBox from '../../components/uibox.vue';
import teamChatBroadcastModal from './teamChatBroadcastModal.vue';
import SocketService from '../../components/sales/socketService.js';
import { onMounted } from 'vue';

onMounted(() => {
  // Initialize socket connection
  socket = SocketService.connect();
  console.log('socket: ', socket);

  socket.on('test-event', (data) => {
    console.log('Test Event:', data);
  });
  // Listen for new messages
  SocketService.on('new_message', handleNewMessage);

})

export default {
  props: {
    type: String,
  },
  components: {
    UIBox,
    teamChatBroadcastModal,
  },
  data() {
    return {
      searchQuery: "",
      searchResults: [],
      loading: false,
      is_active: "1",
      contacts: [],
      selectedContact: null,
      messages: [],
      newMessage: '',
      messageInput: '',
      salesReps: [],
      showBroadcastModal: false,
      socket: null,
      userSub: "",
      Username: localStorage.getItem("username"),
      role: "",
      userId: "",
      isListening: false,
      managerId: this.$route.query.managerId, // Get manager ID from query parameters
      canSendMessageToAdmin: true,
    };
  },
  // created() {
  //   // Connect to the Socket.IO server
  //   this.socket = io("http://localhost:3000");

  //   // Register the user
  //   this.socket.emit("register", { userId: this.userId, role: this.role });

  //   // Listen for incoming calls
  //   this.socket.on("incoming-call", (data) => {
  //     this.incomingCall = data;
  //   });

  //   // Handle call acceptance
  //   this.socket.on("call-accepted", (data) => {
  //     console.log("Call accepted:", data);
  //   });

  //   // Handle call rejection
  //   this.socket.on("call-rejected", (data) => {
  //     console.log("Call rejected:", data);
  //   });
  // },
  methods: {
    async initUsers() {
      this.role = await AuthService.getUserRole();
      if (this.role === null) {
        this.role = 'Operators';
      }
      if (this.role === 'Roaming_Closer') {
        this.role = 'SalesRep'
      }
      this.userSub = await AuthService.getCognitoSub();
      this.getUserInfo();
    },
    // acceptCall() {
    //   this.socket.emit("accept-call", {
    //     salesPersonId: this.userId,
    //     adminId: this.incomingCall.adminId,
    //   });
    //   this.incomingCall = null;
    // },
    // rejectCall() {
    //   this.socket.emit("reject-call", {
    //     salesPersonId: "salesPerson123",
    //     adminId: this.incomingCall.adminId,
    //   });
    //   this.incomingCall = null;
    // },
    // async initiateCall() {
    //   if (!this.selectedContact) {
    //     alert("Please select a contact to initiate a call.");
    //     return;
    //   }
    //   try {
    //     const response = await axios.post("https://123avatars.com/v2/initiate-call", {
    //       adminId: this.userId,
    //       salesPersonId: this.selectedContact.id,
    //     });
    //     console.log("Call initiated:", response.data);
    //     alert("Call initiated successfully!");
    //   } catch (error) {
    //     console.error("Error initiating call:", error);
    //     alert("Failed to initiate call. Please try again.");
    //   }
    // },
    async getUserInfo() {

      try {
        if (this.role === 'Admins') {
          this.userSub = this.Username;
        }

        const response = await axios.get(`https://123avatars.com/v2/get-user-info/${this.userSub}/${this.role}`);
        this.userId = response.data.id;
        this.getContacts();

      } catch (error) {
        console.error("Error fetching User info:", error.response || error.message || error);

      }

    },
    getInitials(fullName) {
      const names = fullName.split(' ');
      const firstNameInitial = names[0] ? names[0][0] : '';
      const lastNameInitial = names[1] ? names[1][0] : '';
      return `${firstNameInitial}${lastNameInitial}`.toUpperCase();
    },
    async fetchSalesReps() {
      try {
        const response = await axios.get("https://123avatars.com/v2/team-broadcast-sales-reps"); // Call the backend API

        this.salesReps = response.data;
      } catch (error) {
        console.error("Error fetching sales reps:", error.response || error.message || error);
      }
    },
    async openBroadcastModal() {
      this.fetchSalesReps(); // Fetch sales reps before opening the modal
      this.showBroadcastModal = true;
    },
    async fetchSearchResults() {
      if (!this.searchQuery.trim()) {
        return;
      }
      this.loading = true;
      try {
        const response = await axios.get(
          `https://123avatars.com/v2/team-search-contacts/${this.userId}/${this.role}`,
          {
            params: { search: this.searchQuery.trim() },
          }
        );
        this.contacts = response.data;
      } catch (error) {
        console.error("Error fetching search results:", error);
        this.contacts = [];
      } finally {
        this.loading = false;
      }
    },


    async getContacts() {
      // ${is_active.toString() ? `/${is_active}` : ""}
      try {
        const response = await axios.get(`https://123avatars.com/v2/team-search-contacts/${this.userId}/${this.role}`,
          {
            params: {
              search: this.searchQuery.trim(),
              active: this.is_active,
            }
          }
        );

        this.contacts = response.data;

        if (this.contacts.length > 0) {
          this.contacts.sort((a, b) => {
            const dateA = a.last_message_time ? new Date(a.last_message_time) : new Date(0);
            const dateB = b.last_message_time ? new Date(b.last_message_time) : new Date(0);
            return dateB - dateA;
          });

          if (this.contacts.length > 0) {
            this.selectContact(this.contacts[0]);
          }
        }
      } catch (error) {
        console.error("Error fetching contacts:", error);
      }
    },
    selectContact(contact) {
      this.selectedContact = contact;

      this.searchQuery = "";

      if (this.role === 'Managers') {

        SocketService.register(this.selectedContact.managerId);
      }
      if (this.role === 'SalesRep') {
        SocketService.register(this.selectedContact.salesId);
      }
      if (this.role === 'Admins') {
        SocketService.register(this.selectedContact.adminId);
      }
      this.markMessagesAsRead(this.selectedContact.id);
      this.getMessages();

    },

    async sendMessage() {
      try {
        if (!this.messageInput.trim()) return;

        let endpoint = "";
        let payload = {};

        endpoint = `https://123avatars.com/v2/team-send-message`;
        payload = {
          room_id: this.selectedContact.id,
          sender_id: this.userId,
          text: this.messageInput
        };
        const response = await axios.post(endpoint, payload);

        if (response.status === 200) {
          console.log("Message sent:", this.messageInput);

          // Add the new message to the messages array
          this.messages.push({
            SenderID: this.userId,
            text: this.messageInput,
            time: new Date().toISOString(),
          });
          this.messageInput = "";
          this.scrollToBottom();
          this.markMessagesAsRead(this.selectedContact.id);
          this.getMessages();
          this.socket.emit('test-event')
        } else {
          console.error("Failed to send message:", response.data);
        }

        //   time: DateTime.now()
        //     .setZone(this.getTimezoneFromAbbreviation(this.timeZone))
        //     .toFormat("M/d/yyyy, h:mm a"),

      } catch (error) {
        console.error("error replying to message: ", error);
      }
    },
    scrollToBottom() {
      const chatMessages = this.$refs.chatMessages;
      if (chatMessages) {
        chatMessages.scrollTop = chatMessages.scrollHeight;
      }
    },

    async getMessages() {
      if (this.selectedContact.id === null || this.selectedContact.id === undefined) {
        try {
          // Call the create-room API to create a new room
          const response = await axios.post("https://123avatars.com/v2/team-create-room", {
            adminId: this.selectedContact.adminId || null,
            managerId: this.selectedContact.managerId || null,
            salesId: this.selectedContact.salesId || null,
            type: this.selectedContact.type || "single", // Default to "single" if type is not provided
          });

          // Update the selectedContact with the new room ID
          this.selectedContact.id = response.data.roomId;
          // Proceed to fetch messages for the newly created room
          this.fetchMessagesForRoom(this.selectedContact.id);
        } catch (error) {
          console.error("Error creating room:", error.response?.data?.error || error.message);
          return; // Exit if room creation fails
        }
      } else {
        // If the room ID exists, fetch messages for the room
        this.fetchMessagesForRoom(this.selectedContact.id);
      }

      // Fetch the last Admin message timestamp if the user is a SalesRep
      if (this.role === 'SalesRep' && this.selectedContact.role === 'Admin') {
        try {
          const response = await axios.get(`https://123avatars.com/v2/last-admin-message/${this.selectedContact.id}/${this.selectedContact.adminId}`);
          const lastMessageTime = response.data.lastMessage[0].last_message_time;

          if (lastMessageTime) {
            const lastMessageDate = new Date(lastMessageTime);
            const currentTime = new Date();
            const timeDifference = (currentTime - lastMessageDate) / (1000 * 60 * 60); // Difference in hours

            // Disable the send button if more than 8 hours have passed
            this.canSendMessageToAdmin = timeDifference <= 8;
          } else {
            // If no message from Admin, allow sending
            this.canSendMessageToAdmin = true;
          }
        } catch (error) {
          console.error('Error fetching last admin message:', error);
          this.canSendMessageToAdmin = false;
        }
      } else {
        // If the contact is not an Admin, allow sending messages
        this.canSendMessageToAdmin = true;
      }
    },
    fetchMessagesForRoom(roomId) {
      fetch(`https://123avatars.com/v2/team-get-messages/${roomId}`) // Fetch messages for the room
        .then((response) => response.json())
        .then((data) => {
          this.messages = data;
          this.$nextTick(this.scrollToBottom); // Scroll to the bottom of the chat
        })
        .catch((err) => {
          console.error("Error fetching messages:", err);
        });
    },

    async markMessagesAsRead(room_id) {
      try {
        const response = await axios.post('https://123avatars.com/v2/team-markMessagesAsRead', {
          room_id: room_id,
          userId: this.userId,
        });

        if (response.status === 200) {
          console.log('Messages marked as read successfully:', response.data.message);
        } else if (response.status === 404) {
          console.log('No unread messages found:', response.data.message);
        }
      } catch (error) {
        console.error('Error marking messages as read:', error.response?.data?.error || error.message);
      }
    },
    emitEvent() {
      this.$emit('new_team_message', 'new_team_message');
    },
    handleNewMessage(message) {
      if (message.SenderID == this.selectedContact.managerId || message.SenderID == this.selectedContact.salesId || message.SenderID == this.selectedContact.adminId) {
        console.log('message: ', message);
        this.messages.push(message);
        this.markMessagesAsRead(this.selectedContact.id);
        this.$nextTick(this.scrollToBottom);
        this.emitEvent();
      }
    },

    getTimezone() {
      const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      console.log('timezone: ', localTimezone);

      // List of US timezones
      const usTimezones = [
        'America/New_York',    // EST/EDT
        'America/Chicago',     // CST/CDT
        'America/Denver',      // MST/MDT
        'America/Phoenix',     // MST (no DST)
        'America/Los_Angeles', // PST/PDT
        'America/Anchorage',   // AKST/AKDT
        'America/Adak',        // HST/HDT
        'Pacific/Honolulu',    // HST (no DST)
      ];

      // Check if the local timezone is a US-based timezone
      if (usTimezones.includes(localTimezone)) {
        return localTimezone;
      }
      // If not a US timezone, return PST/PDT
      return 'America/Los_Angeles';
    },
    formatContactTimestamp(timestamp) {
      const date = new Date(timestamp);

      // Extract date components
      const day = date.getDate();
      const month = date.getMonth() + 1; // Months are zero-based
      const year = String(date.getFullYear()).slice(-2); // Get the last two digits of the year

      // Extract time components
      let hours = date.getHours();
      const minutes = date.getMinutes().toString().padStart(2, '0'); // Add leading zero if needed
      const ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12 || 12; // Convert to 12-hour format

      // Format the date and time
      return `${month}/${day}/${year}, ${hours}:${minutes} ${ampm}`;
    },
    formatChatTimestamp(timestamp) {
      const timezone = this.getTimezone();
      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
        timeZone: timezone
      };

      const date = new Date(timestamp);
      return date.toLocaleString('en-US', options);
    },

    updateWordCount() {
      if (this.messageInput.length > 320) {
        this.messageInput = this.messageInput.substring(0, 320);
      }
    },
  },
  mounted() {
    this.socket = SocketService.connect();
    SocketService.register(this.userId);
    this.initUsers();
    SocketService.on('new_team_message', this.handleNewMessage);
  }
}

</script>

<style scoped lang="scss">
$interactive: var(--interactive);

#team-chat-container {
  height: 100vh;

  .row {
    height: 100vh;
  }

  .col-md-4,
  .col-md-8 {
    height: 100%;
    display: flex;
  }

  // ======================================== contact list
  .contactlist-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    // max-height: 85%;
    // max-height: calc(100vh - 170px);
    // height: 100%;
    height: calc(100% - 80px);
    overflow-y: auto;
    padding: 20px;

  }

  .contacts-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .select-button {
    display: flex;
    gap: 10px;
  }

  .unread-badge {
    background-color: $interactive;
    color: white;
    font-size: 10px;
    font-weight: bold;
    width: 17px;
    height: 17px;
    line-height: 18px;
    text-align: center;
    border-radius: 50%;
    display: inline-block;
    margin-top: 4px;
  }

  .search-container {
    width: 100%;
    position: relative;
    color: $interactive;
  }

  // ======================================== chat window
  .chat-box {
    height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 10px !important;
  }

  .chat-window {
    flex-grow: 1;
    width: 100%;
    overflow-y: auto;
    border-radius: 8px;
  }

  .chat-input {
    // display: flex;
    flex-direction: column;
    position: relative;
    background: white;
    border: none;
  }

  .message-input {
    border-radius: 10px;
    resize: none;
    width: 100%;
    padding-right: 50px;
  }

  /* Container for word count & send button */
  .input-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    margin-top: 8px;
  }

  .word-count {
    font-size: 12px;
    color: #888;
  }

  .send-button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;
  }

  .list-group {
    width: 100%;
    overflow-y: auto;
    position: relative;

    .list-group-item {
      cursor: pointer;
      position: relative;
      transition: background 0.2s;
      border: none;
      border-bottom: gray 1px solid;

    }

    .list-group-item:hover {
      background: #f8f9fa;
    }
  }

  .conversation {
    //cg
    width: 100%;
    padding-right: 0;
    padding-bottom: 0 !important;
  }

  .send-button {
    border: none;
    padding: 8px 15px;
    border-radius: 5px;
    align-items: end;
    background-color: $interactive;
  }

  .broadcast-button {
    background-color: $interactive;
    border: none;
    cursor: pointer;
  }

  .bubble-container {
    position: relative;
    display: flex;
    flex-direction: column;
    // width: -webkit-fill-available;
    width: 100%;
  }

  .bubble {
    padding: 10px;
    border-radius: 10px;
    max-width: 70%;
    position: relative;
    display: inline-block;
    word-wrap: break-word;
    white-space: pre-wrap;
    overflow: hidden;
    box-sizing: border-box;
  }

  .filter-dropdown {
    width: 110px;
  }

  .contacts-list {
    max-height: 400px;
    overflow-y: auto;
  }

  .icon-circle {
    width: 30px;
    height: 30px;
    border: 2px solid #ccc;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    background-color: $interactive;
    color: white;
  }

  .search-box,
  .search-box admins,
  .search-box managers,
  .search-box roaming_closer,
  .search-box operators {
    position: relative;
  }

  .search-box input,
  .search-box admins input,
  .search-box managers input,
  .search-box roaming_closer input,
  .search-box operators input {
    width: 100%;
    padding: 8px 40px 8px 16px;
    border-radius: 8px;
  }

  .search-box input {
    border: 2px solid $interactive !important;
  }

  .search-box admins input {
    border: 2px solid $interactive !important;
  }

  .search-box managers input {
    border: 2px solid $interactive !important;
  }

  .search-box roaming_closer input {
    border: 2px solid $interactive !important;
  }

  .search-box operators input {
    border: 2px solid $interactive !important;
  }

  .search-box {
    border-color: $interactive !important;
  }

  .search-box admins {
    border-color: $interactive !important;
  }

  .search-box managers {
    border-color: #2653C7 !important;
  }

  .search-box roaming_closer {
    border-color: #0B7D8A !important;
  }

  .search-box operators {
    border-color: #46A57C !important;
  }

  .search-box input:focus {
    outline: none;
    border: 2px solid $interactive !important;
  }

  .search-box managers input:focus {
    outline: none;
    border: 2px solid #2653C7 !important;
  }

  .search-box roaming_closer input:focus {
    outline: none;
    border: 2px solid #0B7D8A !important;
  }

  .search-box operators input:focus {
    outline: none;
    border: 2px solid #46A57C !important;
  }

  .search-box admins input:focus {
    outline: none;
    border: 2px solid #A73A18 !important;
  }

  .search-icon,
  .search-icon admins,
  .search-icon managers,
  .search-icon roaming_closer,
  .search-icon operators {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    font-size: 16px;
    padding: 0 12px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    display: flex;
    align-items: center;
    border-style: none;
  }

  .search-icon {
    background-color: $interactive !important;
    border-color: $interactive !important;
  }

  .search-icon admins {
    background-color: #A73A18 !important;
    border-color: #A73A18 !important;
  }

  .search-icon managers {
    background-color: #2653C7 !important;
    border-color: #2653C7 !important;
  }

  .search-icon roaming_closer {
    background-color: #0B7D8A !important;
    border-color: #0B7D8A !important;
  }

  .search-icon operators {
    background-color: #46A57C !important;
    border-color: #46A57C !important;
  }
}
</style>
