<template>
  <div class="timezone">
    <a href="#" @click.prevent>
      <TimezoneSVG />{{ userTimezone }}
      <EditSVG v-b-modal.changeTimezoneModal class="mini" v-if="role !== 'Admins' && isValidTimezone" />
    </a>
    <a class="local-time">
      <ClockSVG class="timezoneSVG" />{{ localTime }}
    </a>

    <b-modal
      id="changeTimezoneModal"
      size="sm"
      title="Change Timezone"
      hide-footer
      :no-close-on-backdrop="saving"
      :no-close-on-esc="saving"
      @hidden="handleModalClose"
    >
      <div class="form-container w-100">
        <div class="d-flex flex-column align-items-start">
          <div class="filter">
            <div class="filterBody flex-row align-items-center flex-nowrap m-0">
              <div class="form-group select d-flex align-items-center w-100">
                <select class="form-control" v-model="selectedTimezone" :disabled="saving">
                  <option v-for="timezone in timezones" :key="timezone" :value="timezone">{{ timezone }}</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <b-button class="btn solid icon mt-2 float-right" @click="changeTimezone()" :disabled="saving">Save</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import AuthService from "../../_services/AuthService.js";
import TimezoneSVG from '../../components/SVG-icons/timezoneSVG.vue';
import EditSVG from '../../components/SVG-icons/editSVG.vue';
import ClockSVG from '../../components/SVG-icons/clockSVG.vue';

export default {
  components: {
    TimezoneSVG, EditSVG, ClockSVG
  },
  props: {
    userTimezone: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      userId: "",
      role: "",
      localTime: "",
      selectedTimezone: "",
      lastUpdateMinute: new Date().getMinutes(),
      saving: false,
      timezones: [
        "EST",
        "EDT",
        "CST",
        "CDT",
        "MST",
        "MDT",
        "PST",
        "PDT",
        "AKST",
        "AKDT",
        "HST",
        "HDT",
      ],
      isValidTimezone: false,
    };
  },
  watch: {
    userTimezone() {
      this.selectedTimezone = this.userTimezone;
      this.updateLocalTimes();
    }
  },
  methods: {
    async initUsers() {
      this.role = await AuthService.getUserRole();
      if (this.role === null) {
        this.role = 'Operators';
      }
      this.userId = await AuthService.getCognitoSub();
    },
    getFormattedTime(timezone) {
      timezone = this.getTimezoneFromAbbreviation(timezone);

      const now = new Date();
      return new Intl.DateTimeFormat('en-US', {
        timeZone: timezone,
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      }).format(now);
    },
    getTimezoneFromAbbreviation(abbreviation) {
      const timezones = {
        'EST': 'America/New_York', // Eastern Standard Time
        'EDT': 'America/New_York', // Eastern Daylight Time
        'CST': 'America/Chicago', // Central Standard Time
        'CDT': 'America/Chicago', // Central Daylight Time
        'MST': 'America/Denver', // Mountain Standard Time
        'MDT': 'America/Denver', // Mountain Daylight Time
        'PST': 'America/Los_Angeles', // Pacific Standard Time
        'PDT': 'America/Los_Angeles', // Pacific Daylight Time
        'AKST': 'America/Anchorage', // Alaska Standard Time
        'AKDT': 'America/Anchorage', // Alaska Daylight Time
        'HST': 'Pacific/Honolulu', // Hawaii Standard Time
        'HDT': 'Pacific/Honolulu' // Hawaii Daylight Time (though Hawaii does not observe DST)
      };

      return timezones[abbreviation] || 'America/Los_Angeles';
    },
    async updateLocalTimes() {
      this.localTime = this.getFormattedTime(this.userTimezone);
    },
    watchClock() {
      setInterval(() => {
        const currentMinute = new Date().getMinutes();
        if (currentMinute !== this.lastUpdateMinute) {
          this.lastUpdateMinute = currentMinute;
          this.updateLocalTimes();
        }
      }, 1000);
    },
    async changeTimezone() {
      try {
        this.saving = true;

        await axios.post('https://123avatars.com/v2/update-timezone', {
          userId: this.userId,
          timezone: this.selectedTimezone
        });

        this.userTimezone = this.selectedTimezone;
      } catch (error) {
        console.error('Error updating timezone:', error);
      } finally {
        this.saving = false;
        this.$bvModal.hide('changeTimezoneModal');
      }
    },
    handleModalClose() {
      if (this.saving) {
        this.$bvModal.show('changeTimezoneModal');
      }
    },
    checkUserTimezone() {
      const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      const timezoneAbbreviation = this.getTimezoneAbbreviation(browserTimezone);

      this.isValidTimezone = this.timezones.includes(timezoneAbbreviation);
    },
    getTimezoneAbbreviation(fullTimezone) {
      const timezoneMap = {
        "America/New_York": "EST",
        "America/Chicago": "CST",
        "America/Denver": "MST",
        "America/Los_Angeles": "PST",
        "America/Anchorage": "AKST",
        "Pacific/Honolulu": "HST",
        "America/Detroit": "EDT",
        "America/Indiana/Indianapolis": "EDT",
        "America/North_Dakota/Center": "CDT",
        "America/Phoenix": "MST",
        "America/Boise": "MDT",
        "America/Juneau": "AKDT",
        "Pacific/Honolulu": "HDT",
      };

      return timezoneMap[fullTimezone] || "";
    }
  },
  created() {
    this.checkUserTimezone();
  },
  mounted() {
    this.selectedTimezone = this.userTimezone;
    this.updateLocalTimes();
    this.initUsers();
    this.watchClock();
  }
};
</script>

<style scoped lang="scss">
.local-time {
  color:white !important;
  margin-top:4%;
}

.local-time svg {
  fill: white !important;
}

svg {
  outline: 0;
}
</style>