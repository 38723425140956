<template>
    <div id="sales-rep-panel">
        <UIBox classbox="hello" class="m-0" :class="{ loading: isLoading }" classTitle="d-flex align-items-center justify-content-between w-100">
            <template v-slot:title>
                <span class="bold">
                    Sales Rep Panel
                </span>

                <div class="filter w-auto">
                    <div class="filterBody m-0">
                        <div class="form-group select" :class="{ 'invisible': role !== 'Admins' || (role === 'Admins' && managers.length <= 0) }">
                            <select v-model="selectedManager" class="form-control">
                                <option value="all" selected>All Managers</option>
                                <option v-for="manager in managers" :key="manager.id" :value="manager.sub">{{ manager.name }}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </template>
            <template>
                <div v-if="isLoading" class="loader-wrapper">
                    <div class="loader">
                        <span></span>
                    </div>
                </div>
                <template v-else>
                    <div class="count-tabs">
                        <div class="tab" :class="{ managers: role === 'Managers' }">
                            <span>Sales</span>
                            <h4>-</h4>
                        </div>
                        <div class="tab" :class="{ managers: role === 'Managers' }">
                            <span>Live Transfers</span>
                            <h4>{{ totalTransfers.toLocaleString('en-US') }}</h4>
                        </div>
                        <div class="tab" :class="{ managers: role === 'Managers' }">
                            <span>Transfers / Sale</span>
                            <h4>-</h4>
                        </div>
                        <div class="tab" :class="{ managers: role === 'Managers' }">
                            <span>Missed Transfers</span>
                            <h4>{{ missedTransfers }}</h4>
                        </div>
                        <div class="tab" :class="{ managers: role === 'Managers' }">
                            <span>Available Reps</span>
                            <h4>{{ availableRepsCount.toLocaleString('en-US') }}</h4>
                        </div>
                        <div class="tab" :class="{ managers: role === 'Managers' }">
                            <span>Transfers / Rep</span>
                            <h4>{{ transfersPerRep }}</h4>
                        </div>
                        <div class="tab" :class="{ managers: role === 'Managers' }">
                            <span>Average Call</span>
                            <h4>{{ totalAverageCallTime }}</h4>
                        </div>
                        <div class="tab" :class="{ managers: role === 'Managers' }">
                            <span>Calls</span>
                            <h4>{{ totalCalls.toLocaleString('en-US') }}</h4>
                        </div>
                    </div>
                    <div class="table-fullwidth mt-5">
                        <table class="table call-backs-table inbox-table" :class="`inbox-table${role ? role.toLowerCase() : ''}`" aria-controls="tagstable">
                            <thead>
                                <tr>
                                    <th :class="{ managers: role === 'Managers' }">User’s Name</th>
                                    <th :class="{ managers: role === 'Managers' }">Company Name</th>
                                    <th :class="{ managers: role === 'Managers' }">Prospect’s Time</th>
                                    <th :class="{ managers: role === 'Managers' }">Current Call</th>
                                    <th :class="{ managers: role === 'Managers' }">Transfers</th>
                                    <th :class="{ managers: role === 'Managers' }">Talk Time</th>
                                    <th :class="{ managers: role === 'Managers' }">Average</th>
                                    <th :class="{ managers: role === 'Managers' }">Calls</th>
                                    <th :class="{ managers: role === 'Managers' }">Last Call</th>
                                    <th :class="{ managers: role === 'Managers' }">Last Click</th>
                                    <th :class="{ managers: role === 'Managers' }">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <!-- <tr>
                                    <td>Joseph Ureal - Avail</td>
                                    <td>ABC Plumbing - CA</td>
                                    <td>12:45 PM</td>
                                    <td><button class="barge-button green">17m:45s</button></td>
                                    <td>32</td>
                                    <td>2h:48m</td>
                                    <td>5m:36s</td>
                                    <td>4/82=86</td>
                                    <td>3m:23s</td>
                                    <td>5m:20s</td>
                                    <td>Closing Sales</td>
                                </tr> -->
                                <tr v-for="rep in sortedSalesRepsData" :key="rep.id">
                                    <td>{{ rep.user_name }} - {{ rep.available === 1 ? 'Avail' : 'NA' }}</td>
                                    <template v-if="rep.is_call_active == 1">
                                        <td>{{ rep.company_name }}</td>
                                        <td>{{ rep.timezone }}</td>
                                        <td><button class="barge-button" :class="{ green: currentCallDurations[rep.id] >= 900, orange: currentCallDurations[rep.id] < 900 && currentCallDurations[rep.id] >= 300, yellow: currentCallDurations[rep.id] < 300 }" @click="bargeCall(rep.id)">{{ convertSeconds(currentCallDurations[rep.id]) }}</button></td>
                                    </template>
                                    <template v-else>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </template>
                                    <td>{{ rep.transfers }}</td>
                                    <td>{{ convertSeconds(rep.talk_time) }}</td>
                                    <td>{{ convertSeconds(Math.round(parseFloat(rep.average))) }}</td>
                                    <td>{{ rep.inbound_calls }}/{{ rep.outbound_calls }}={{ parseInt(rep.inbound_calls) + parseInt(rep.outbound_calls) }}</td>
                                    <td>{{ lastCallDurations[rep.id] !== null ? convertSeconds(lastCallDurations[rep.id]) : '-' }}</td>
                                    <td>{{ lastClickDurations[rep.id] !== null ? convertSeconds(lastClickDurations[rep.id]) : '-' }}</td>
                                    <td>{{ rep.currentStatus }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </template>
            </template>
        </UIBox>
    </div>
</template>

<script>
import AuthService from "../../_services/AuthService.js";
import UIBox from '../../components/uibox.vue';
import axios from "axios";
import moment from "moment-timezone";

export default {
    components: {
        AuthService, UIBox
    },
    props: {
        statusSchedule: { type: Array, required: true },
        sipDid: { type: String, required: false }
    },
    data() {
        return {
            isLoading: true,
            role: "",
            userId: "",
            selectedManager: 'all',
            managers: [],
            salesRepsData: [],
            missedTransfers: 0,
            currentCallDurations: {},
            lastCallDurations: {},
            lastClickDurations: {},
            animationFrame: null,
            cancelToken: null,
            fetchTimeout: null,
            isActive: true,
        };
    },
    watch: {
        selectedManager() {
            this.fetchSalesRepsData();
        },
        salesRepsData(newData) {
            if (newData.length > 0) {
                this.startUpdatingDurations();
            }
        }
    },
    computed: {
        sortedSalesRepsData() {
            return [...this.salesRepsData]
                .filter(rep => (parseInt(rep.inbound_calls) + parseInt(rep.outbound_calls)) > 0)
                .sort((a, b) => (b.talk_time || 0) - (a.talk_time || 0))
                .sort((a, b) => {
                    const lastCallA = (this.currentCallDurations[a.id] ?? 0) * (a.is_call_active === 1 ? 1 : 0);
                    const lastCallB = (this.currentCallDurations[b.id] ?? 0) * (b.is_call_active === 1 ? 1 : 0);
                    return lastCallB - lastCallA;
                });
        },
        totalTransfers() {
            return this.salesRepsData.reduce((sum, rep) => sum + (parseInt(rep.transfers) || 0), 0);
        },
        totalCalls() {
            return this.salesRepsData.reduce((sum, rep) => sum + (parseInt(rep.inbound_calls) + parseInt(rep.outbound_calls) || 0), 0);
        },
        totalAverageCallTime() {
            const validAverages = this.salesRepsData
                .map(rep => Math.round(parseFloat(rep.average)))
                .filter(value => !isNaN(value));

            const count = validAverages.length;
            if (count === 0) return this.convertSeconds(0);

            const total = validAverages.reduce((sum, value) => sum + value, 0);
            const average = total / count;

            return this.convertSeconds(Math.round(average));
        },
        availableRepsCount() {
            return this.salesRepsData.filter(rep => rep.available === 1).length;
        },
        transfersPerRep() {
            return this.availableRepsCount > 0 ? (Math.round(this.totalTransfers / this.availableRepsCount)).toLocaleString('en-US') : "N/A";
        }
    },
    methods: {
        bargeCall(id) {
            axios.get(`https://123avatars.com/v2/barge-call?id=${id}&managerNum=${encodeURIComponent(this.sipDid)}`)
                .then((resp) => {
                if(resp.data.message) {
                    alert(resp.data.message);
                    this.closeBarge(call);
                } else {
                    this.$emit('manager-barge', {
                            
                        });
                }
                }).catch((err) => {
                console.error("error barging call: ", err);
                });
        },
        async fetchManagers() {
            try {
                const response = await fetch('https://123avatars.com/v2/managers');
                this.managers = await response.json();
            } catch (error) {
                console.error('Error fetching managers:', error);
            }
        },
        resetScroll() {
            document.documentElement.scrollTop = 0;
            document.body.scrollTop = 0;
        },
        async initUsers() {
            this.role = await AuthService.getUserRole();
            if (this.role === null) {
                this.role = 'Operators';
            }
            this.userId = await AuthService.getCognitoSub();
        },
        async fetchSalesRepsData(silentRefresh = false) {
            if (!this.isActive) return;

            try {
                if (this.cancelToken) {
                    this.cancelToken.cancel("Request aborted due to new request.");
                }

                this.cancelToken = axios.CancelToken.source();

                if (!silentRefresh) this.isLoading = true;

                const response = await axios.get('https://123avatars.com/v2/sales-rep-panel', {
                    params: {
                        selectedManager: this.selectedManager
                    },
                    cancelToken: this.cancelToken.token,
                });

                this.salesRepsData = response.data.salesRepsData;
                this.missedTransfers = response.data.missedTransfersCount;
            } catch (error) {
                if (!axios.isCancel(error)) {
                    console.error("Error fetching sales reps data:", error);
                }
            } finally {
                if (!silentRefresh) this.isLoading = false;

                clearTimeout(this.fetchTimeout);
                if (!this.isLoading) {
                    this.fetchTimeout = setTimeout(() => {
                        this.fetchSalesRepsData(true);
                    }, 1000);
                }
            }
        },
        convertSeconds(seconds) {
            if (seconds >= 3600) {
                const hours = Math.floor(seconds / 3600);
                const remainingMinutes = Math.floor((seconds % 3600) / 60);
                return `${hours}h:${remainingMinutes}m`;
            } else if (seconds >= 60) {
                const minutes = Math.floor(seconds / 60);
                const remainingSeconds = seconds % 60;
                return `${minutes}m:${remainingSeconds}s`;
            } else {
                return `${seconds}s`;
            }
        },
        startUpdatingDurations() {
            if (this.animationFrame) return;
            this.updateDurations();
        },

        updateDurations() {
            const now = Date.now();

            this.salesRepsData.forEach(rep => {
                if (rep.answered_start_time) {
                    const lastCallTimestamp = new Date(rep.answered_start_time).getTime();
                    const duration = Math.floor((now - lastCallTimestamp) / 1000);

                    this.$set(this.currentCallDurations, rep.id, duration);
                } else this.$set(this.currentCallDurations, rep.id, null);

                if (rep.last_call_date) {
                    const lastCallTimestamp = new Date(rep.last_call_date).getTime();
                    const duration = Math.floor((now - lastCallTimestamp) / 1000);

                    this.$set(this.lastCallDurations, rep.id, duration);
                } else this.$set(this.lastCallDurations, rep.id, null);

                if (rep.timezone) {
                    const currentTime = moment.tz(now, this.getTimezoneFromAbbreviation(rep.timezone)).format("HH:mm");

                    const currentStatus = this.statusSchedule.find(schedule => {
                        return currentTime >= schedule.start && currentTime < schedule.end;
                    });

                    const newStatus = currentStatus ? currentStatus.title : "Out of Shift";
                    if (rep.currentStatus !== newStatus) {
                        this.$set(rep, "currentStatus", newStatus);
                    }
                }

                if (rep.last_click_date) {
                    const lastClickTimestamp = new Date(rep.last_click_date).getTime();
                    const duration = Math.floor((now - lastClickTimestamp) / 1000);

                    this.$set(this.lastClickDurations, rep.id, duration);
                } else this.$set(this.lastClickDurations, rep.id, null);
            });

            this.animationFrame = requestAnimationFrame(this.updateDurations);
        },

        getTimezoneFromAbbreviation(abbreviation) {
            const timezones = {
                'EST': 'America/New_York', // Eastern Standard Time
                'EDT': 'America/New_York', // Eastern Daylight Time
                'CST': 'America/Chicago', // Central Standard Time
                'CDT': 'America/Chicago', // Central Daylight Time
                'MST': 'America/Denver', // Mountain Standard Time
                'MDT': 'America/Denver', // Mountain Daylight Time
                'PST': 'America/Los_Angeles', // Pacific Standard Time
                'PDT': 'America/Los_Angeles', // Pacific Daylight Time
                'AKST': 'America/Anchorage', // Alaska Standard Time
                'AKDT': 'America/Anchorage', // Alaska Daylight Time
                'HST': 'Pacific/Honolulu', // Hawaii Standard Time
                'HDT': 'Pacific/Honolulu' // Hawaii Daylight Time (though Hawaii does not observe DST)
            };

            return timezones[abbreviation] || 'America/Los_Angeles';
        },

        stopUpdatingDurations() {
            cancelAnimationFrame(this.animationFrame);
            this.animationFrame = null;
        },

        stopFetching() {
            this.isActive = false;

            if (this.cancelToken) {
                this.cancelToken.cancel("Component unmounted, request aborted.");
            }

            if (this.fetchTimeout) {
                clearTimeout(this.fetchTimeout);
            }
        },
    },
    async mounted() {
        this.isActive = true;
        this.resetScroll();

        await this.initUsers();        

        if (this.role === 'Managers') this.selectedManager = this.userId;
        else await this.fetchManagers();

        await this.fetchSalesRepsData();

        if (this.salesRepsData.length > 0) {
            this.startUpdatingDurations();
        }
    },
    beforeDestroy() {
        this.stopUpdatingDurations();
        this.stopFetching();
    }
};
</script>

<style scoped lang="scss">
$interactive: var(--interactive);

#sales-rep-panel {
    display: flex;
    flex-direction: column;

    ::v-deep(.hello){
        min-height: calc(100vh - 105px - 60px);
        display: flex;
        flex-direction: column;
    }

    ::v-deep(.hello .content) {
        gap: 0;
        overflow: hidden;
        flex-wrap: unset;
    }

    ::v-deep(.hello.loading .content) {
        position: relative;
        flex-grow: 1;
    }

    ::v-deep(.hello.loading .content .loader-wrapper) {
        position: absolute;
        background-color: transparent;
    }

    ::v-deep(.hello.loading .content .loader-wrapper .loader) {
        border-color: $interactive rgba(255, 255, 255, 0);
    }

    .count-tabs {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 20px;

        .tab {
            color: $interactive;
            text-align: center;
            padding: 25px;
            background: rgba($color: #A73A18, $alpha: 0.25);
            border: 2px solid $interactive;
            border-radius: 20px;

            span {
                font-size: 16px;
                font-weight: 400;
            }

            h4 {
                margin: 0;
                font-size: 48px;
            }

            &.managers {
                background: rgba($color: #2653C7, $alpha: 0.25);
            }
        }
    }

    .table-fullwidth {
        overflow: unset;
        text-align: center;
        font-weight: 400;

        td {
            border: none;
            border-bottom: 1px solid rgba($color: #000000, $alpha: 0.25);
        }

        th,
        td {
            padding: 15px;
        }

        thead {
            th {
                color: $interactive;
                background: rgba($color: #A73A18, $alpha: 0.25);
                font-weight: 700;
                border: 0;

                &.managers {
                    background: rgba($color: #2653C7, $alpha: 0.25);
                }
            }

            th:first-child {
                border-radius: 15px 0 0 15px;
            }

            th:last-child {
                border-radius: 0 15px 15px 0;
            }
        }

        tbody {
            tr:last-child {
                td {
                    border: none;
                }
            }
        }

        .barge-button {
            cursor: pointer;
            padding: 5px 15px;
            border-radius: 15px;
            transition: color 0.35s, background 0.35s;

            &.yellow {
                color: #c4c631;
                background: rgba($color: #fcff00, $alpha: 0.25);
                border: 1px solid #fcff00;
            }

            &.orange {
                color: #ff9900;
                background: rgba($color: #ff9900, $alpha: 0.25);
                border: 1px solid #ff9900;
            }

            &.green {
                color: #02ff00;
                background: rgba($color: #02ff00, $alpha: 0.25);
                border: 1px solid #02ff00;
            }
        }

        .barge-button:hover {
            color: white;

            &.yellow {
                color: #c4c631;
                background: rgba($color: #fcff00, $alpha: 1);
            }

            &.orange {
                background: rgba($color: #ff9900, $alpha: 1);
            }

            &.green {
                background: rgba($color: #02ff00, $alpha: 1);
            }
        }
    }
}
</style>