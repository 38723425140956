<template>
  <div id="prompt-editor">
    <UIBox classbox="hello">
      <template v-slot:title>
        <div class="header-container w-100">
          <div class="title-section d-flex align-items-center justify-content-between w-100">
            <span class="bold"><b>AI Operators</b></span>
            <div class="prompt-tabs">
              <ButtonUI class="px-3" :class="{ outline: selectedPromptTab !== 'prompting' }" @click="selectedPromptTab = 'prompting'">
                Prompting
              </ButtonUI>
              <!-- <ButtonUI class="px-3" :class="{ outline: selectedPromptTab !== 'voices' }" @click="selectedPromptTab = 'voices'">
                Voices
              </ButtonUI> -->
              <ButtonUI class="px-3" :class="{ outline: selectedPromptTab !== 'settings' }" @click="selectedPromptTab = 'settings'">
                Settings
              </ButtonUI>
              <ButtonUI class="px-3" :class="{ outline: selectedPromptTab !== 'leads' }" @click="selectedPromptTab = 'leads'">
                Leads
              </ButtonUI>
              <!-- <ButtonUI class="px-3" :class="{ outline: selectedPromptTab !== 'logs' }" @click="selectedPromptTab = 'logs'">
                Logs
              </ButtonUI> -->
              <!-- <ButtonUI class="px-3" :class="{ outline: selectedPromptTab !== 'dispositions' }" @click="selectedPromptTab = 'dispositions'">
                Dispositions
              </ButtonUI> -->
              <!-- <ButtonUI class="px-3 outline" @click="showPriorityHuntModal = true">
                Priority Hunt
              </ButtonUI> -->
            </div>
          </div>
        </div>
      </template>

      <template v-if="selectedPromptTab === 'prompting'">
        <div class="prompting-container">
          <div class="left-panel">
            <div class="left-panel-header">
              <h5 class="m-0">Prompts</h5>
              <ButtonUI variant="add" @click="addNewPrompt">
                <AddSVG />
              </ButtonUI>
            </div>
            <ul>
              <li v-for="prompt in sortedPrompts" :key="prompt.id" @click="selectPrompt(prompt)">
                <div class="d-flex">
                  <div class="d-flex" @click="setActivePrompt(prompt.id)">
                    <CaretDownSVG :class="{ 'hide': !prompt.is_active, 'loading': prompt.is_active && saving, 'force-hide': !prompt.is_active && saving }" size="19" :fill="roleColor()" />
                  </div>
                  <div class="d-flex flex-column">
                    <h6 :class="{ 'font-weight-bold': selectedPrompt.id == prompt.id }">{{ prompt.title }} <span @click="selectPromptTitle(prompt)" :class="{ 'force-hide': saving }"><PenSVG class="pt-edit" :fill="roleColor()" /></span></h6>
                    <p>{{ prompt.date }}</p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="right-panel">
            <textarea
              v-model="selectedPrompt.prompt"
              rows="15"
              class="form-control"
              :placeholder="'Type your prompt here'"
              :disabled="saving"
            ></textarea>
            <ButtonUI class="px-3 float-right" @click="savePrompt" :disabled="selectedPrompt.prompt.trim() == '' || saving">
              Save Prompt
            </ButtonUI>
            <!-- <ButtonUI class="px-3 mr-2 float-right generate-title-btn" @click="generatePromptTitle" :class="{ 'loading': selectedPrompt.prompt.trim() != '' && generateTitle }" :disabled="selectedPrompt.prompt.trim() == '' || saving || generateTitle">
              <MagicPenSVG fill="white" size="14" /> <span>Generate Title</span>
            </ButtonUI> -->
          </div>
        </div>
      </template>

      <template v-else-if="selectedPromptTab === 'settings'">
        <div class="form-container w-100 position-relative" :class="{ 'overflow-hidden': inlineLoading }">
          <div class="loader-wrapper inline-loader" v-if="inlineLoading">
            <div class="loader">
              <span></span>
            </div>
          </div>
          <div class="filter">
            <div class="filterBody flex-column">
              <label class="form-label w-100 hunt-seconds-label" style="width: 80px;">Number Per Available Sales Rep:</label>
              <div class="form-group select flex items-center gap-2">
                <select v-model.number="linesPerSalesRep" class="form-control" style="width:200px;" @change="updateSettings">
                  <option v-for="n in 21" :key="n - 1" :value="n - 1">
                    {{ n - 1 }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <!-- <div class="call-settings">
            <label for="concurrentLines">Number of Servers:</label>
            <input 
              type="number" 
              id="concurrentLines" 
              v-model.number="maxConcurrentOperators" 
              min="0" 
              max="25"
              class="input-control"
              style="margin-left: 0%;"
              @input="updateSettings"
            />
            <label for="concurrentLines">Lines per Server:</label>
            <input 
              type="number" 
              id="concurrentLines" 
              v-model.number="linesPerServer" 
              min="0" 
              max="25"
              class="input-control"
              style="margin-left: 0%;"
              @input="updateSettings"
            />
          <button 
              :class="{'btn-on': callsEnabled, 'btn-off': !callsEnabled}" 
              @click="toggleCalls"
              style="border-radius: 20px; padding: 10px 20px; font-weight: bold; border: none; "
            >
              {{ callsEnabled ? '✅ Operators On' : '❌ Operators Off' }}
            </button> 
          </div> -->
          <div class="d-flex flex-column align-items-start">
            <label for="huntSeconds" class="m-0 hunt-seconds-label">Seconds to Show Notification Before Adding Next Sales Rep:</label>
            <div class="form-group mt-2 mb-0 w-100">
              <input 
                type="number" 
                v-model.number="huntSeconds" 
                class="form-control hunt-seconds" 
                min="1" 
                step="1"
              />
            </div>
          </div>

          <div class="drag-drop-container">
            <div class="priority-list">
              <h4>({{ prioritySalesReps.length }}) Selected Sales Reps</h4>
              <draggable v-model="prioritySalesReps" group="sales" class="flex-grow-1">
                <div v-for="rep in prioritySalesReps" :key="rep.id" class="draggable-item">
                  {{ rep.name }}
                </div>
              </draggable>
            </div>

            <div class="available-salesreps">
              <h4>({{ availableSalesReps.length }}) All Active Sales Reps</h4>
              <draggable v-model="availableSalesReps" group="sales" class="flex-grow-1">
                <div v-for="rep in availableSalesReps" :key="rep.id" class="draggable-item">
                  {{ rep.name }}
                </div>
              </draggable>
            </div>
          </div>

          <b-button class="btn solid icon mt-3 float-right" @click="savePriorityHunt">Save</b-button>
        </div>
      </template>

      <template v-else-if="selectedPromptTab === 'logs'">
        <div class="table-fullwidth">
          <table class="table call-backs-table" aria-controls="tagstable">
            <thead>
              <tr>
                <th>Operator Name</th>
                <th>Sales Rep Name</th>
                <th>Direction</th>
                <th>From</th>
                <th>To</th>
                <th>Status</th>
                <th>Start Time</th>
                <th>End Time</th>
                <th>Duration</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="call in calls" :key="call.id">
                <td>{{ call.operator_name }}</td>
                <td>{{ call.sales_rep_name }}</td>
                <td><span class="call-status" :class="call.direction.toLowerCase().replace(/ /g, '-')">{{ call.direction }}</span></td>
                <td>{{ call.from }}</td>
                <td>{{ call.to }}</td>
                <td><span class="call-status" :class="call.status.toLowerCase().replace(/ /g, '-')">{{ call.status }}</span></td>
                <td>{{ formatDate(call.start_time) }}</td>
                <td>{{ formatDate(call.end_time) }}</td>
                <td>{{ call.duration || "0" }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="bottom-input">
          <div class="per-page">
            <div class="select">
              Per Page
              <select class="per-page-drop" id="rowsPerPage">
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="500">500</option>
              </select>
            </div>
          </div>

          <template>
            <div class="pagin">
              <b-pagination 
                aria-controls="tagstable">
                <template #first-text>
                  <PaginleftdoubleSVG />
                </template>
                <template #prev-text>
                  <PaginleftSVG />
                </template>
                <template #next-text>
                  <PaginrightSVG />
                </template>
                <template #last-text>
                  <PaginrightdoubleSVG />
                </template>
              </b-pagination>
            </div>
          </template>
        </div>
      </template>

      <template v-else-if="selectedPromptTab === 'leads'">
        <!-- Leads Industries Checkbox Group -->
        <div class="d-flex flex-column">
          <span class="hunt-seconds-label mb-1">Select Industrie(s):</span>
          <div class="leads-industries-filter flex-wrap">
            <div
              class="checkbox-container"
              v-for="industry in leadsIndustries"
              :key="industry.id"
              @click="toggleLeadsIndustry(industry.id)"
            >
              <td style="float: left; position: relative;">
                <CheckedSVG
                  v-if="selectedLeadsIndustries.includes(industry.id)"
                  :size="20"
                  class="checkbox-checked"
                  :fill="roleColor()"
                  :stroke="roleColor()"
                />
                <UncheckedSVG
                  v-else
                  :size="20"
                  class="checkbox-unchecked"
                  fill="none"
                  :stroke="roleColor()"
                />
              </td>
              <label
                class="font-bold"
                style="float: left; position: relative; margin: 0; margin-left: 5px;"
              >
                {{ industry.name }}
              </label>
            </div>
          </div>
        </div>

        <!-- Leads Timezones Checkbox Group -->
        <div class="d-flex flex-column">
          <span class="hunt-seconds-label mb-1">Select Timezone(s):</span>
          <div class="leads-states-filter flex-wrap">
            <div
              class="checkbox-container"
              v-for="timezone in leadsTimezones"
              :key="timezone.id"
              @click="toggleLeadsTimezone(timezone.id)"
            >
              <td style="float: left; position: relative;">
                <CheckedSVG
                  v-if="selectedLeadsTimezones.includes(timezone.id)"
                  :size="20"
                  class="checkbox-checked"
                  :fill="roleColor()"
                  :stroke="roleColor()"
                />
                <UncheckedSVG
                  v-else
                  :size="20"
                  class="checkbox-unchecked"
                  fill="none"
                  :stroke="roleColor()"
                />
              </td>
              <label
                class="font-bold"
                style="float: left; position: relative; margin: 0; margin-left: 5px;"
              >
                {{ timezone.name }}
              </label>
            </div>
          </div>
        </div>

        <!-- Leads States Checkbox Group -->
        <div class="d-flex flex-column">
          <span class="hunt-seconds-label mb-1">Select State(s):</span>
          <div class="leads-states-filter flex-wrap">
            <div
              class="checkbox-container"
              v-for="state in leadsStates"
              :key="state.id"
              @click="toggleLeadsState(state.id)"
            >
              <td style="float: left; position: relative;">
                <CheckedSVG
                  v-if="selectedLeadsStates.includes(state.id)"
                  :size="20"
                  class="checkbox-checked"
                  :fill="roleColor()"
                  :stroke="roleColor()"
                />
                <UncheckedSVG
                  v-else
                  :size="20"
                  class="checkbox-unchecked"
                  fill="none"
                  :stroke="roleColor()"
                />
              </td>
              <label
                class="font-bold"
                style="float: left; position: relative; margin: 0; margin-left: 5px;"
              >
                {{ state.name }}
              </label>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-between w-100">
          <!-- Owner's First Name Select -->
          <div class="d-flex flex-column w-25">
            <span class="hunt-seconds-label mb-1">Owner's First Name:</span>
            <div class="filter">
              <div class="filterBody flex-row align-items-center flex-nowrap m-0">
                <div class="form-group select d-flex align-items-center w-100">
                  <select class="form-control">
                    <option value="all" selected>All</option>
                    <option value="has_first_name">Has First Name</option>
                    <option value="no_first_name">No First Name</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <!-- City Select -->
          <div class="d-flex flex-column w-25">
            <span class="hunt-seconds-label mb-1">City:</span>
            <div class="filter">
              <div class="filterBody flex-row align-items-center flex-nowrap m-0">
                <div class="form-group select d-flex align-items-center w-100">
                  <select class="form-control">
                    <option value="all" selected>All</option>
                    <option value="has_city">Has City</option>
                    <option value="no_city">No City</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <!-- Website Select -->
          <div class="d-flex flex-column w-25">
            <span class="hunt-seconds-label mb-1">Website:</span>
            <div class="filter">
              <div class="filterBody flex-row align-items-center flex-nowrap m-0">
                <div class="form-group select d-flex align-items-center w-100">
                  <select class="form-control">
                    <option value="all" selected>All</option>
                    <option value="has_website">Has Website</option>
                    <option value="no_website">No Website</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <!-- New Controls for Voice and Temperature -->
      <!-- <div class="settings-container">
        <label for="voice">Select Voice:</label>
        <select v-model="voice" class="input-control">
          <option value="alloy">Alloy</option>
          <option value="ash">Ash</option>
          <option value="ballad">Ballad</option>
          <option value="coral">Coral</option>
          <option value="echo">Echo</option>
          <option value="sage">Sage</option>
          <option value="shimmer">Shimmer</option>
          <option value="verse">Verse</option>
        </select>

        <label for="temperature">Temperature (0.6 - 1.2):</label>
        <input 
          type="number" 
          v-model.number="temperature" 
          class="input-control" 
          min="0.6" 
          max="1.2" 
          step="0.1"
        />
      </div>

      <div class="call-settings">
        <label for="concurrentLines">Concurrent Lines:</label>
        <input 
          type="number" 
          id="concurrentLines" 
          v-model.number="maxConcurrentOperators" 
          min="1" 
          max="25"
          class="input-control"
          style="margin-left: 0%;"
          @input="updateSettings"
        />
        <button 
          :class="{'btn-on': callsEnabled, 'btn-off': !callsEnabled}" 
          @click="toggleCalls"
          style="border-radius: 20px; padding: 10px 20px; font-weight: bold; border: none; "
        >
          {{ callsEnabled ? '✅ Operators On' : '❌ Operators Off' }}
        </button>
      </div>

      <div class="container-body" id="promptContainer">

        <div class="call-buttons">
          <button class="btn solid mb-2" @click="initiateCall('Russell')">Call Russell</button>
          <button class="btn solid mb-2" @click="initiateCall('Kaylee')">Call Kaylee</button>
          <button class="btn solid mb-3" @click="initiateCall('Chuck')">Call Chuck</button>
        </div>

        <textarea
          v-model="prompt"
          class="prompt-input"
          placeholder="Edit your AI prompt here..."
        ></textarea>
      </div> -->

      <!-- <div class="button-wrapper">
        <button class="btn solid save-button" @click="savePrompt">Save Changes</button>
      </div> -->
    </UIBox>

    <b-modal v-model="notificationModal" title="Prompt Saved" hide-footer>
      <div class="form-container">
        <div class="row notification-text">Prompt Saved Successfully.</div>
        <div class="button-group">
          <button class="btn-save" type="button" @click="notificationModal = false">OK</button>
        </div>
      </div>
    </b-modal>

    <b-modal v-model="showPriorityHuntModal" size="lg" title="Priority Hunt" hide-footer>
      <div class="form-container w-100">
        <div class="d-flex flex-column align-items-start">
          <label for="huntSeconds" class="m-0 hunt-seconds-label">Seconds to Show Notification Before Adding Next Sales Rep:</label>
          <div class="form-group mt-2 mb-0 w-100">
            <input 
              type="number" 
              v-model.number="huntSeconds" 
              class="form-control hunt-seconds" 
              min="1" 
              step="1"
            />
          </div>
        </div>

        <div class="drag-drop-container">
          <div class="priority-list">
            <h4>({{ prioritySalesReps.length }}) Selected Sales Reps</h4>
            <draggable v-model="prioritySalesReps" group="sales" class="flex-grow-1">
              <div v-for="rep in prioritySalesReps" :key="rep.id" class="draggable-item">
                {{ rep.name }}
              </div>
            </draggable>
          </div>

          <div class="available-salesreps">
            <h4>({{ availableSalesReps.length }}) All Active Sales Reps</h4>
            <draggable v-model="availableSalesReps" group="sales" class="flex-grow-1">
              <div v-for="rep in availableSalesReps" :key="rep.id" class="draggable-item">
                {{ rep.name }}
              </div>
            </draggable>
          </div>
        </div>

        <b-button class="btn solid icon mt-3 float-right" @click="savePriorityHunt">Save</b-button>
      </div>
    </b-modal>

    <b-modal v-model="showPromptTitleModal" size="md" title="Prompt Title" hide-footer>
      <div class="form-container w-100">
        <div class="d-flex flex-column align-items-start">
          <div class="form-group mt-2 mb-0 w-100">
            <input 
              type="text"
              v-model.number="selectedPromptTitle.title"
              class="form-control"
              :disabled="saving"
            />
          </div>
        </div>

        <b-button class="btn solid icon mt-3 float-right" @click="savePromptTitle(selectedPromptTitle.id)" :disabled="saving">Save</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios';
import draggable from 'vuedraggable';
import UIBox from '../components/uibox.vue';
import ButtonUI from '../components/page/ButtonUI.vue';
import PaginleftdoubleSVG from '../components/SVG-icons/paginleftdoubleSVG.vue';
import PaginleftSVG from '../components/SVG-icons/paginleftSVG.vue';
import PaginrightdoubleSVG from '../components/SVG-icons/paginrightdoubleSVG.vue';
import PaginrightSVG from '../components/SVG-icons/paginrightSVG.vue';
import AddSVG from '../components/SVG-icons/addSVG.vue';
import CaretDownSVG from '../components/SVG-icons/caretDownSVG.vue';
import AuthService from '@/_services/AuthService';
import MagicPenSVG from '../components/SVG-icons/magicPenSVG.vue';
import PenSVG from '../components/SVG-icons/penSVG.vue';
import CheckedSVG from '../components/SVG-icons/checkedSVG.vue';
import UncheckedSVG from '../components/SVG-icons/unCheckedSVG.vue';

export default {
  components: {
    UIBox, ButtonUI, draggable, PaginleftdoubleSVG, PaginleftSVG, PaginrightdoubleSVG, PaginrightSVG, AddSVG,
    CaretDownSVG, MagicPenSVG, PenSVG, CheckedSVG, UncheckedSVG
  },
  props: {
    userTimezone: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      prompt: '', // Holds the AI prompt text
      voice: 'coral', // Default voice setting
      temperature: 0.8, // Default temperature
      notificationModal: false, // Controls the success notification modal
      maxConcurrentOperators: 0,
      linesPerSalesRep: 0,
      status: 0,
      initialValues: {
        prompt: '',
        voice: 'coral',
        temperature: 0.8,
        maxConcurrentOperators: 0,
        linesPerSalesRep: 0,
        callsEnabled: false,
      },
      showPriorityHuntModal: false,
      showPromptTitleModal: false,
      huntSeconds: 3,
      prioritySalesReps: [],
      availableSalesReps: [],
      selectedPromptTab: 'prompting',
      inlineLoading: true,
      saving: false,
      generateTitle: false,
      calls: [
        {
            "id": 1,
            "operator_name": "AI Operator 132",
            "sales_rep_name": "Angie Sengco",
            "direction": "Outgoing",
            "from": "+6677889900",
            "to": "+1122334455",
            "status": "Failed",
            "start_time": "2025-02-26T15:21:48.741317",
            "end_time": null,
            "duration": NaN,
            "recording_url": null,
            "session_id": "session_0",
            "client_state": "OptionalData",
            "bridged_call_id": null,
            "conference_id": "conf_13"
        },
        {
            "id": 2,
            "operator_name": "AI Operator 40",
            "sales_rep_name": "Anup Datta",
            "direction": "Outgoing",
            "from": "+6677889900",
            "to": "+6677889900",
            "status": "Completed",
            "start_time": "2025-02-26T10:28:48.741317",
            "end_time": null,
            "duration": NaN,
            "recording_url": null,
            "session_id": "session_1",
            "client_state": null,
            "bridged_call_id": null,
            "conference_id": "conf_4"
        },
        {
            "id": 3,
            "operator_name": "AI Operator 32",
            "sales_rep_name": "Christopher Walker",
            "direction": "Incoming",
            "from": "+1234567890",
            "to": "+1234567890",
            "status": "Ringing",
            "start_time": "2025-02-26T02:21:48.741317",
            "end_time": "2025-02-26T02:31:01.741317",
            "duration": 553.0,
            "recording_url": "https://recordings.telnyx.com/call_2.wav",
            "session_id": "session_2",
            "client_state": "OptionalData",
            "bridged_call_id": "call_2",
            "conference_id": "conf_16"
        },
        {
            "id": 4,
            "operator_name": "AI Operator 24",
            "sales_rep_name": "Jeoffrey Garrera",
            "direction": "Outgoing",
            "from": "+5566778899",
            "to": "+0987654321",
            "status": "In Progress",
            "start_time": "2025-02-26T10:35:48.741317",
            "end_time": null,
            "duration": NaN,
            "recording_url": "https://recordings.telnyx.com/call_3.wav",
            "session_id": "session_3",
            "client_state": null,
            "bridged_call_id": null,
            "conference_id": "conf_12"
        },
        {
            "id": 5,
            "operator_name": "AI Operator 11",
            "sales_rep_name": "Maria Camille Garcia",
            "direction": "Incoming",
            "from": "+5566778899",
            "to": "+1234567890",
            "status": "No Answer",
            "start_time": "2025-02-26T14:30:48.741317",
            "end_time": "2025-02-26T14:36:16.741317",
            "duration": 328.0,
            "recording_url": "https://recordings.telnyx.com/call_4.wav",
            "session_id": "session_4",
            "client_state": "OptionalData",
            "bridged_call_id": "call_14",
            "conference_id": "conf_20"
        },
        {
            "id": 6,
            "operator_name": "AI Operator 47",
            "sales_rep_name": "Anup Datta",
            "direction": "Incoming",
            "from": "+1234567890",
            "to": "+1122334455",
            "status": "Canceled",
            "start_time": "2025-02-26T01:59:48.741317",
            "end_time": null,
            "duration": NaN,
            "recording_url": "https://recordings.telnyx.com/call_5.wav",
            "session_id": "session_5",
            "client_state": null,
            "bridged_call_id": "call_14",
            "conference_id": null
        },
        {
            "id": 7,
            "operator_name": "AI Operator 20",
            "sales_rep_name": "Jeoffrey Garrera",
            "direction": "Incoming",
            "from": "+0987654321",
            "to": "+1122334455",
            "status": "Completed",
            "start_time": "2025-02-26T12:38:48.741317",
            "end_time": "2025-02-26T12:42:34.741317",
            "duration": 226.0,
            "recording_url": null,
            "session_id": "session_6",
            "client_state": "OptionalData",
            "bridged_call_id": "call_8",
            "conference_id": null
        },
        {
            "id": 8,
            "operator_name": "AI Operator 45",
            "sales_rep_name": "Christopher Walker",
            "direction": "Outgoing",
            "from": "+5566778899",
            "to": "+5566778899",
            "status": "Completed",
            "start_time": "2025-02-26T05:35:48.741317",
            "end_time": null,
            "duration": NaN,
            "recording_url": null,
            "session_id": "session_7",
            "client_state": "OptionalData",
            "bridged_call_id": null,
            "conference_id": "conf_11"
        },
        {
            "id": 9,
            "operator_name": "AI Operator 44",
            "sales_rep_name": "Anup Datta",
            "direction": "Outgoing",
            "from": "+0987654321",
            "to": "+5566778899",
            "status": "Completed",
            "start_time": "2025-02-26T16:34:48.741317",
            "end_time": "2025-02-26T16:35:39.741317",
            "duration": 51.0,
            "recording_url": "https://recordings.telnyx.com/call_8.wav",
            "session_id": "session_8",
            "client_state": "OptionalData",
            "bridged_call_id": "call_10",
            "conference_id": null
        },
        {
            "id": 10,
            "operator_name": "AI Operator 40",
            "sales_rep_name": "Jeoffrey Garrera",
            "direction": "Outgoing",
            "from": "+5566778899",
            "to": "+5566778899",
            "status": "Failed",
            "start_time": "2025-02-26T14:33:48.741317",
            "end_time": null,
            "duration": NaN,
            "recording_url": "https://recordings.telnyx.com/call_9.wav",
            "session_id": "session_9",
            "client_state": null,
            "bridged_call_id": null,
            "conference_id": "conf_3"
        },
        {
            "id": 11,
            "operator_name": "AI Operator 20",
            "sales_rep_name": "Christopher Walker",
            "direction": "Incoming",
            "from": "+0987654321",
            "to": "+1234567890",
            "status": "Busy",
            "start_time": "2025-02-26T17:05:48.741317",
            "end_time": "2025-02-26T17:15:14.741317",
            "duration": 566.0,
            "recording_url": null,
            "session_id": "session_10",
            "client_state": "OptionalData",
            "bridged_call_id": null,
            "conference_id": null
        },
        {
            "id": 12,
            "operator_name": "AI Operator 54",
            "sales_rep_name": "Angie Sengco",
            "direction": "Outgoing",
            "from": "+6677889900",
            "to": "+1234567890",
            "status": "Initiated",
            "start_time": "2025-02-26T02:55:48.741317",
            "end_time": null,
            "duration": NaN,
            "recording_url": "https://recordings.telnyx.com/call_11.wav",
            "session_id": "session_11",
            "client_state": null,
            "bridged_call_id": "call_15",
            "conference_id": "conf_14"
        },
        {
            "id": 13,
            "operator_name": "AI Operator 20",
            "sales_rep_name": "Jeoffrey Garrera",
            "direction": "Incoming",
            "from": "+6677889900",
            "to": "+1234567890",
            "status": "Initiated",
            "start_time": "2025-02-26T02:51:48.741317",
            "end_time": null,
            "duration": NaN,
            "recording_url": "https://recordings.telnyx.com/call_12.wav",
            "session_id": "session_12",
            "client_state": "OptionalData",
            "bridged_call_id": null,
            "conference_id": "conf_6"
        },
        {
            "id": 14,
            "operator_name": "AI Operator 38",
            "sales_rep_name": "Maria Camille Garcia",
            "direction": "Outgoing",
            "from": "+1122334455",
            "to": "+5566778899",
            "status": "Busy",
            "start_time": "2025-02-26T01:27:48.741317",
            "end_time": "2025-02-26T01:34:05.741317",
            "duration": 377.0,
            "recording_url": "https://recordings.telnyx.com/call_13.wav",
            "session_id": "session_13",
            "client_state": "OptionalData",
            "bridged_call_id": null,
            "conference_id": null
        },
        {
            "id": 15,
            "operator_name": "AI Operator 1",
            "sales_rep_name": "Jeoffrey Garrera",
            "direction": "Incoming",
            "from": "+0987654321",
            "to": "+1234567890",
            "status": "Initiated",
            "start_time": "2025-02-26T14:27:48.741317",
            "end_time": "2025-02-26T14:32:20.741317",
            "duration": 272.0,
            "recording_url": "https://recordings.telnyx.com/call_14.wav",
            "session_id": "session_14",
            "client_state": "OptionalData",
            "bridged_call_id": "call_19",
            "conference_id": "conf_14"
        },
        {
            "id": 16,
            "operator_name": "AI Operator 20",
            "sales_rep_name": "Jeoffrey Garrera",
            "direction": "Outgoing",
            "from": "+1122334455",
            "to": "+5566778899",
            "status": "Completed",
            "start_time": "2025-02-26T15:26:48.741317",
            "end_time": "2025-02-26T15:36:32.741317",
            "duration": 584.0,
            "recording_url": "https://recordings.telnyx.com/call_15.wav",
            "session_id": "session_15",
            "client_state": "OptionalData",
            "bridged_call_id": null,
            "conference_id": "conf_11"
        },
        {
            "id": 17,
            "operator_name": "AI Operator 48",
            "sales_rep_name": "-",
            "direction": "Outgoing",
            "from": "+0987654321",
            "to": "+1122334455",
            "status": "Busy",
            "start_time": "2025-02-26T12:41:48.741317",
            "end_time": null,
            "duration": NaN,
            "recording_url": null,
            "session_id": "session_16",
            "client_state": "OptionalData",
            "bridged_call_id": null,
            "conference_id": null
        },
        {
            "id": 18,
            "operator_name": "-",
            "sales_rep_name": "Jeoffrey Garrera",
            "direction": "Incoming",
            "from": "+1234567890",
            "to": "+1122334455",
            "status": "Failed",
            "start_time": "2025-02-26T12:39:48.741317",
            "end_time": null,
            "duration": NaN,
            "recording_url": "https://recordings.telnyx.com/call_17.wav",
            "session_id": "session_17",
            "client_state": null,
            "bridged_call_id": "call_15",
            "conference_id": "conf_8"
        },
        {
            "id": 19,
            "operator_name": "AI Operator 67",
            "sales_rep_name": "Christopher Walker",
            "direction": "Outgoing",
            "from": "+1122334455",
            "to": "+0987654321",
            "status": "Initiated",
            "start_time": "2025-02-26T14:02:48.741317",
            "end_time": null,
            "duration": NaN,
            "recording_url": "https://recordings.telnyx.com/call_18.wav",
            "session_id": "session_18",
            "client_state": null,
            "bridged_call_id": null,
            "conference_id": null
        },
        {
            "id": 20,
            "operator_name": "AI Operator 44",
            "sales_rep_name": "-",
            "direction": "Outgoing",
            "from": "+0987654321",
            "to": "+0987654321",
            "status": "Failed",
            "start_time": "2025-02-26T15:20:48.741317",
            "end_time": "2025-02-26T15:30:27.741317",
            "duration": 579.0,
            "recording_url": "https://recordings.telnyx.com/call_19.wav",
            "session_id": "session_19",
            "client_state": "OptionalData",
            "bridged_call_id": null,
            "conference_id": "conf_1"
        }
      ],
      prompts: [],
      selectedPrompt: { id: null, prompt: '' },
      selectedPromptTitle: { id: null, title: '' },
      leadsIndustries: [
        { id: "photographer", name: "Photographer" },
        { id: "real_estate_agent", name: "Real estate agent" },
        { id: "beauty_salon", name: "Beauty salon" },
        { id: "barber_shop", name: "Barber shop" },
        { id: "real_estate_agency", name: "Real estate agency" },
        { id: "notary_public", name: "Notary public" },
        { id: "hair_salon", name: "Hair salon" },
        { id: "lawn_care_service", name: "Lawn care service" },
      ],
      leadsStates: [
        { id: "california", name: "California" },
        { id: "pennsylvania", name: "Pennsylvania" },
        { id: "nevada", name: "Nevada" },
        { id: "alabama", name: "Alabama" },
        { id: "georgia", name: "Georgia" },
        { id: "indiana", name: "Indiana" },
        { id: "florida", name: "Florida" },
      ],
      leadsTimezones: [
        { id: "pst", name: "PST" },
        { id: "est", name: "EST" },
        { id: "cst", name: "CST" },
        { id: "mst", name: "MST" },
        { id: "hst", name: "HST" },
      ],
      selectedLeadsIndustries: [],
      selectedLeadsStates: [],
      selectedLeadsTimezones: [],
    };
  },
  computed: {
    sortedPrompts() {
      return [...this.prompts].sort((a, b) => new Date(b.id) - new Date(a.id));
    },
  },
  watch: {
    voice(newVoice) {
      if (newVoice !== this.initialValues.voice) {
        this.updateSettings();
      }
    },
    temperature(newTemperature) {
      if (newTemperature !== this.initialValues.temperature) {
        this.updateSettings();
      }
    },
    callsEnabled(newVal) {
      if (newVal !== this.initialValues.callsEnabled) {
        this.updateSettings();
      }
    },
  },
  methods: {
    async toggleCalls() {
      this.callsEnabled = !this.callsEnabled;
      this.$forceUpdate();
      this.updateSettings();
    },
    async updateSettings() {
      this.$nextTick(async () => {
        try {
        const userId = localStorage.getItem('userId');
        await axios.post('https://123avatars.com/v2/update-settings', {
          userId: userId,
          maxConcurrentOperators: this.maxConcurrentOperators,
          linesPerServer: this.linesPerServer,
          linesPerSalesRep: this.linesPerSalesRep,
          status: this.callsEnabled ? 1 : 0,
          voice: this.voice,
          temperature: this.temperature,
          prompt: this.prompt
        });
        console.log('Settings updated successfully');
      } catch (error) {
        console.error('Error updating settings:', error);
      }
      });
    },
    async initiateCall(person) {
      try {
        await axios.post('https://123avatars.com/v2/init-call', {
          name: person,
          voice: this.voice,
          temperature: this.temperature
        });
        console.log(`Call Initiated to ${person} with voice ${this.voice} and temperature ${this.temperature}`);
      } catch (error) {
        console.error('Error initiating call:', error);
      }
    },
    async fetchPrompt() {
      try {
        const userId = localStorage.getItem('userId');
        const response = await axios.get(`https://123avatars.com/v2/prompt?userId=${userId}`);
        const data = response.data;

        // Set both the variables and the initial values
        this.prompt = data.prompt || '';
        this.voice = data.voice || 'coral';
        this.temperature = data.temperature || 0.8;
        this.maxConcurrentOperators = (data.maxConcurrentOperators / 10) || 0;
        this.linesPerServer = data.linesPerServer || 0;
        this.linesPerSalesRep = data.linesPerSalesRep || 0;
        this.callsEnabled = data.status === 1;

        // Store initial values
        this.initialValues = {
          prompt: this.prompt,
          voice: this.voice,
          temperature: this.temperature,
          maxConcurrentOperators: this.maxConcurrentOperators,
          callsEnabled: this.callsEnabled,
        };
      } catch (error) {
        console.error('Error fetching the prompt:', error);
      }
    },
    // async savePrompt() {
    //   try {
    //     const userId = localStorage.getItem('userId');
    //     await axios.post('https://123avatars.com/v2/prompt', {
    //       userId,
    //       prompt: this.prompt,
    //       voice: this.voice,
    //       temperature: this.temperature
    //     });
    //     this.notificationModal = true;
    //   } catch (error) {
    //     console.error('Error saving the prompt:', error);
    //   }
    // },
    async fetchSalesReps() {
      try {
        this.inlineLoading = true;

        const response = await axios.get('https://123avatars.com/v2/users?filter=Roaming_Closer');

        this.availableSalesReps = response.data
          .map(rep => ({
            id: rep.sub,
            name: rep.firstName + ' ' + rep.lastName
          }));

        await this.loadLatestPriorityHunt();
      } catch (error) {
        console.error('Error fetching sales reps:', error);
      } finally {
        this.inlineLoading = false;
      }
    },
    async savePriorityHunt() {
      try {
        if (this.prioritySalesReps.length <= 0) {
          return this.$toasted.show("Please select at least one sales rep", {
            theme: "outline",
            position: "top-right",
            type: "error",
            icon: "close",
            duration: 2000,
          });
        }

        await this.updateSettings();
        await axios.post('https://123avatars.com/v2/priority-hunt', {
          seconds: this.huntSeconds,
          salesRepsOrder: this.prioritySalesReps.map(rep => rep.id)
        });

        this.$toasted.show("Priority Hunt saved successfully!", {
          theme: "bubble",
          position: "top-right",
          type: "success",
          icon: "check",
          duration: 2000,
        });

        this.showPriorityHuntModal = false;
      } catch (error) {
        console.error('Error saving priority hunt: ', error);
        this.$toasted.show("Failed to save Priority Hunt. Please try again!", {
          theme: "outline",
          position: "top-right",
          type: "error",
          icon: "alert-circle",
          duration: 3000,
        });
      }
    },
    async loadLatestPriorityHunt() {
      try {
        const response = await axios.get('https://123avatars.com/v2/priority-hunt');

        if (response.data) {
          this.huntSeconds = response.data.seconds;

          const selectedReps = response.data.salesRepsOrder.map(id => 
            this.availableSalesReps.find(rep => rep.id === id)
          ).filter(rep => rep !== undefined);

          this.prioritySalesReps = selectedReps;

          this.availableSalesReps = this.availableSalesReps.filter(rep => 
            !response.data.salesRepsOrder.includes(rep.id)
          );
        }
      } catch (error) {}
    },
    formatDate(timestamp) {
      const abbrTimezone = this.getTimezoneFromAbbreviation(this.userTimezone);

      const date = new Date(timestamp);

      const formatter = new Intl.DateTimeFormat("en-US", {
        timeZone: abbrTimezone,
        month: "numeric",
        day: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true
      });

      return formatter.format(date);
    },
    getTimezoneFromAbbreviation(abbreviation) {
      const timezones = {
        'EST': 'America/New_York', // Eastern Standard Time
        'EDT': 'America/New_York', // Eastern Daylight Time
        'CST': 'America/Chicago', // Central Standard Time
        'CDT': 'America/Chicago', // Central Daylight Time
        'MST': 'America/Denver', // Mountain Standard Time
        'MDT': 'America/Denver', // Mountain Daylight Time
        'PST': 'America/Los_Angeles', // Pacific Standard Time
        'PDT': 'America/Los_Angeles', // Pacific Daylight Time
        'AKST': 'America/Anchorage', // Alaska Standard Time
        'AKDT': 'America/Anchorage', // Alaska Daylight Time
        'HST': 'Pacific/Honolulu', // Hawaii Standard Time
        'HDT': 'Pacific/Honolulu' // Hawaii Daylight Time (though Hawaii does not observe DST)
      };

      return timezones[abbreviation] || 'America/Los_Angeles';
    },
    async loadPrompting() {
      try {
        const response = await axios.get(`https://123avatars.com/v2/prompting?userTimezone=${this.userTimezone}`);

        if (response.data) this.prompts = response.data.data;
      } catch (error) {}
    },
    selectPrompt(prompt) {
      if(this.saving) return;
      this.selectedPrompt = { ...prompt };
    },
    selectPromptTitle(prompt) {
      if(this.saving) return;

      this.showPromptTitleModal = true;
      this.selectedPromptTitle = { ...prompt };
    },
    addNewPrompt() {
      if(this.saving) return;
      this.selectedPrompt = { id: null, prompt: '' };
    },
    async savePrompt() {
      if(this.saving) return;
      this.saving = true;

      const activePrompt = this.prompts.find(prompt => prompt.is_active == 1);

      const response = await axios.post('https://123avatars.com/v2/prompting', {
        prompt: this.selectedPrompt.prompt,
        prevPrompt: activePrompt.prompt,
        userTimezone: this.userTimezone
      });

      this.prompts.forEach(prompt => { prompt.is_active = 0; });
      this.prompts.push({ ...response.data.row, title: "" });

      const index = this.prompts.length - 1;

      await this.typeWriterEffect(response.data.row.title, (value) => {
        this.prompts[index].title = value;
      });

      this.saving = false;

      this.addNewPrompt();
    },
    async setActivePrompt(id) {
      if(this.saving) return;

      const selectedPrompt = this.prompts.find(prompt => prompt.id === id);
      if(selectedPrompt.is_active) return;

      this.saving = true;

      this.prompts = this.prompts.map(prompt => 
        prompt.id === id ? { ...prompt, is_active: 1 } : { ...prompt, is_active: 0 }
      );

      await axios.post('https://123avatars.com/v2/prompting/activate', {
        id,
        prompt: selectedPrompt.prompt
      });

      this.saving = false;
    },
    async savePromptTitle(id) {
      if(this.saving) return;

      this.saving = true;

      await axios.post('https://123avatars.com/v2/prompting/title', {
        id,
        title: this.selectedPromptTitle.title
      });

      this.prompts = this.prompts.map(prompt => 
        prompt.id === id ? { ...prompt, title: this.selectedPromptTitle.title } : { ...prompt }
      );

      this.showPromptTitleModal = false;

      this.saving = false;
    },
    async generatePromptTitle() {
      if(this.saving || this.generateTitle) return;

      const activePrompt = this.prompts.find(prompt => prompt.is_active == 1);

      this.saving = true;
      this.generateTitle = true;

      const response = await axios.post('https://123avatars.com/v2/prompting/generate-title', {
        prompt: this.selectedPrompt.prompt,
        prevPrompt: activePrompt.prompt,
      });

      await this.typeWriterEffect(response.data.title, (value) => this.selectedPrompt.title = value);

      this.saving = false;
      this.generateTitle = false;
    },
    async typeWriterEffect(text, setValue, speed = 10) {
      let currentValue = "";
      setValue(currentValue);
      for (let i = 0; i < text.length; i++) {
        currentValue += text[i];
        setValue(currentValue);
        await new Promise(resolve => setTimeout(resolve, speed));
      }
    },
    roleColor() {
      const colors = {
        admins: '#A73A18',
        managers: '#2653C7',
        operators: '#46A57C',
        roaming_closer: '#0B7D8A',
      };
      return colors[this.role?.toLowerCase()] || '#46A57C';
    },
    async initUsers() {
      this.role = await AuthService.getUserRole();
      if (this.role === null) {
        this.role = 'Operators';
      }
      this.userId = await AuthService.getCognitoSub();
    },
    toggleLeadsIndustry(industry) {
      if (this.selectedLeadsIndustries.includes(industry)) {
        this.selectedLeadsIndustries = this.selectedLeadsIndustries.filter((i) => i !== industry);
      } else {
        this.selectedLeadsIndustries.push(industry);
      }
    },
    toggleLeadsState(state) {
      if (this.selectedLeadsStates.includes(state)) {
        this.selectedLeadsStates = this.selectedLeadsStates.filter((s) => s !== state);
      } else {
        this.selectedLeadsStates.push(state);
      }
    },
    toggleLeadsTimezone(state) {
      if (this.selectedLeadsTimezones.includes(state)) {
        this.selectedLeadsTimezones = this.selectedLeadsTimezones.filter((s) => s !== state);
      } else {
        this.selectedLeadsTimezones.push(state);
      }
    },
  },
  async mounted() {
    this.initUsers();
    this.fetchSalesReps();
    this.loadPrompting();
    await this.fetchPrompt();
  },
};
</script>

<style lang="scss" scoped>
#prompt-editor {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.settings-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.input-control {
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 1rem;
}

.prompt-input {
  width: 100%;
  height: 300px;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  resize: none;
  box-sizing: border-box;
}

.container-body {
  width: 100%;
}

.save-butt{
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
}

.notification-text {
  text-align: center;
  font-size: 1.5em;
  padding: 10px;
}

.btn-on {
  background-color: #4CAF50;
  color: white;
  box-shadow: 0 0 10px rgba(0, 255, 0, 0.7);
}

.btn-off {
  background-color: #F44336;
  color: white;
  box-shadow: 0 0 10px rgba(255, 0, 0, 0.7);
}

::v-deep(.header .title) {
  width: 100%;
}

.drag-drop-container {
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
}

.priority-list, .available-salesreps {
  display: flex;
  width: calc(50% - 15px);
  flex-direction: column;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  padding: 20px;
  border-radius: 10px;
}

.priority-list h4, .available-salesreps h4 {
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 700;
}

.draggable-item {
  padding: 10px;
  font-size: 15px;
  font-weight: 400;
  border: 2px solid rgba(0, 0, 0, 0.1);
  margin: 15px 0;
  cursor: -webkit-grab;
  cursor: grab;
  background: white;
  border-radius: 10px;
  transition: background 0.35s;
}

.draggable-item:last-child {
  margin-bottom: 0;
}

.draggable-item:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.btn-save {
  padding: 0.5rem 1.5rem;
  background: #46A57C;
  color: white;
  border: none;
  border-radius: 6px;
  margin-top: 15px;
}

input.form-control {
  padding: 12.5px;
  border: 2px solid var(--interactive);
  border-radius: 10px;
}

input.form-control, select.form-control {
  font-size: 17px;
  color: black;
}

.form-control.hunt-seconds {
  border: 2px solid var(--interactive);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.35s, background 0.35s;
}

.form-control.hunt-seconds:hover {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  background-color: rgba(0, 0, 0, 0.02);
}

.hunt-seconds-label {
  font-size: 14px;
  font-weight: 700;
}

.prompt-tabs {
  display: flex;
  gap: 10px;
}

.loader-wrapper.inline-loader {
  position: absolute;
  background-color: white;
  left: 0;
}

.loader-wrapper.inline-loader .loader {
  border-color: var(--interactive) rgba(255, 255, 255, 0);
}

.table {
  font-weight: 400;
}

.table th, .table td {
  padding-top: 20px;
  padding-bottom: 20px;
}

.call-status {
  padding: 5px 15px;
  font-weight: 500;
  border-radius: 15px;
  white-space: nowrap;
}

.call-status.initiated {
  color: #4A90E2;
  background-color: rgba($color: #4A90E2, $alpha: 0.15);
}

.call-status.ringing {
  color: #F5A623;
  background-color: rgba($color: #F5A623, $alpha: 0.15);
}

.call-status.in-progress {
  color: #2ECC71;
  background-color: rgba($color: #2ECC71, $alpha: 0.15);
}

.call-status.completed {
  color: #95A5A6;
  background-color: rgba($color: #95A5A6, $alpha: 0.15);
}

.call-status.no-answer {
  color: #7F8C8D;
  background-color: rgba($color: #7F8C8D, $alpha: 0.15);
}

.call-status.busy {
  color: #E67E22;
  background-color: rgba($color: #E67E22, $alpha: 0.15);
}

.call-status.failed {
  color: #E74C3C;
  background-color: rgba($color: #E74C3C, $alpha: 0.15);
}

.call-status.canceled {
  color: #FF6B81;
  background-color: rgba($color: #FF6B81, $alpha: 0.15);
}

.call-status.outgoing {
  color: #3498DB;
  background-color: rgba($color: #3498DB, $alpha: 0.15);
}

.call-status.incoming {
  color: #27AE60;
  background-color: rgba($color: #27AE60, $alpha: 0.15);
}

.prompting-container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 40px;
}
.prompting-container .left-panel {
  width: 25%;
}
.prompting-container .left-panel .left-panel-header {
  padding-bottom: 12.5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 3px solid black;
}
.prompting-container .left-panel .left-panel-header h5 {
  font-size: 17px;
  font-weight: 700;
}
.prompting-container .left-panel h6 {
  margin: 0;
  font-size: 16px;
  font-weight: 400;
}
.prompting-container .left-panel p {
  font-size: 14px;
  font-weight: 400;
  opacity: 0.5;
}
.prompting-container .right-panel {
  width: 75%;
}
.prompting-container .right-panel .form-control {
  padding: 12.5px;
  font-size: 17px;
  border: 2px solid var(--interactive);
  border-radius: 10px;
}
.prompting-container ul {
  list-style: none;
  padding: 0;
}
.prompting-container li {
  cursor: pointer;
  padding: 10px;
  padding-left: 0;
  border-bottom: 1px solid rgba($color: #000000, $alpha: 0.35);
}
.prompting-container li svg {
  transform: rotate(-90deg);
  margin-right: 7.5px;
  transition: opacity 0.35s;
}
.prompting-container li svg.hide {
  opacity: 0;
}
.prompting-container li svg.loading {
  opacity: 0.5;
}
.prompting-container li svg.hide:hover {
  opacity: 0.5;
}
.force-hide {
  opacity: 0 !important;
}
.prompting-container li:last-child {
  border: none;
}
.prompting-container li p {
  margin: 0;
}
.prompting-container input, .prompting-container textarea {
  display: block;
  width: 100%;
  margin-bottom: 30px;
}
.generate-title-btn {
  position: relative;
  background-image: linear-gradient(90deg, #1179fc, #dc82fc);
  border: none;
  overflow: hidden;
}
.generate-title-btn svg,
.generate-title-btn span {
  z-index: 1;
}
.generate-title-btn::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: 0.3);
  transition: background 0.35s;
}
.generate-title-btn:hover::after {
  background-color: rgba($color: #000000, $alpha: 0.45);
}
.generate-title-btn:hover svg,
.generate-title-btn.loading svg {
  animation: shake 0.5s infinite;
}
@keyframes shake {
  0% { transform: translate(0.25px, 0.25px) rotate(0deg); }
  10% { transform: translate(-0.25px, -0.5px) rotate(-1deg); }
  20% { transform: translate(-1px, 0px) rotate(1deg); }
  30% { transform: translate(0.25px, 0.5px) rotate(0deg); }
  40% { transform: translate(0.25px, -0.25px) rotate(1deg); }
  50% { transform: translate(-0.25px, 0.5px) rotate(-1deg); }
  60% { transform: translate(-1px, 0.25px) rotate(0deg); }
  70% { transform: translate(1px, 0.25px) rotate(-1deg); }
  80% { transform: translate(-0.25px, -0.25px) rotate(1deg); }
  90% { transform: translate(0.25px, 0.5px) rotate(0deg); }
  100% { transform: translate(0.25px, -0.5px) rotate(-1deg); }
}
.pt-edit {
  opacity: 0;
  margin: 0;
  height: 15px;
  transform: scaleX(-1);
  transition: opacity 0.35s;
}
.prompting-container li:hover .pt-edit {
  opacity: 1;
}
.prompting-container li .pt-edit:hover {
  opacity: 0.7;
}

.leads-industries-filter,
.leads-states-filter {
  display: flex;
  gap: 15px;
}

.checkbox-container {
  cursor: pointer;
  display: flex;
  align-items: center;

  td {
    padding: 0;
    display: flex;
  }

  label {
    cursor: pointer;
    font-weight: 400;
  }
}
</style>