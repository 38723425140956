<template>
  <div id="operator-to-tracking-report" class="mt-3">
    <UIBox>
      <template v-slot:title>
        <span class="bold">
          <span class="font-bold"> Operator TO Tracking Report </span>
        </span>
      </template>
      <div class="flex justify-between w-full">
        <div class="flex">
          <b-button
            class="to-report-btn"
            :class="{ active: activeButton === 'units' }"
            @click="setActive('units')"
            >Show Units</b-button
          >
          <b-button
            class="to-report-btn"
            :class="{ active: activeButton === 'income' }"
            @click="setActive('income')"
            >Show Income</b-button
          >
        </div>
        <b-button class="to-report-btn active" @click="showOperatorPayscaleModal"
          >Operator Payscale</b-button
        >
      </div>
      <b-table striped hover :items="trackingReport" :fields="fields" class="mb-2">
        <template #head()="{ field }">
          <div>
            <div>{{ field.label }}</div>
            <div v-if="field.date" style="font-size: 0.9em; color: gray">
              {{ field.date }}
            </div>
          </div>
        </template>
        <template #cell(total)="{ value, item }">
          <div
            @click="showRecruiterBreakdownModal"
            class="cursor-pointer ml-3 hover:font-bold"
          >
            {{ value }}
          </div>
        </template>
        <template #cell()="{ field, value }">
          <div v-if="!field.hide">
            {{ value }}
          </div>
        </template>
      </b-table>
      <b-modal
        id="recruiter-breakdown-modal"
        title="Recruiter Breakdown | Ash"
        v-model="showRecruiterBreakdown"
        size="lg"
        centered
      >
        <b-table
          striped
          hover
          :items="recruiterBreakdown"
          :fields="breakdownFields"
          class="mb-3"
        ></b-table>
        <div class="text-end">
          <b-button variant="secondary" @click="closeRecruiterBreakdownModal"
            >Close</b-button
          >
        </div>
      </b-modal>
      <b-modal
        id="operator-payscale-modal"
        title="Operator Payscale"
        v-model="showOperatorPayscale"
        size="lg"
        centered
        hide-header-close
        hide-footer
      >
        <div class="flex flex-col w-full">
          <div class="flex flex-col gap-5 w-full overflow-x-auto">
            <b-table
              :items="operatorPayscale[0]"
              :fields="payscaleFields[0]"
              striped
              hover
            >
              <template #head()="{ field }" class="">
                <div class="w-10 flex flex-nowrap whitespace-nowrap">{{ field.key }}</div>
              </template>
              <template #cell()="{ item, field, index }">
                <div class="ml-3 w-10 flex flex-nowrap" v-if="field.key === 'TO #1'">
                  ${{ item[field.key] }}
                </div>
                <div class="w-10 flex flex-nowrap" v-else>${{ item[field.key] }}</div>
              </template>
            </b-table>
            <b-table
              :items="operatorPayscale[1]"
              :fields="payscaleFields[1]"
              striped
              hover
            >
              <template #head()="{ field }">
                <div class="w-fit flex flex-nowrap whitespace-nowrap">{{ field.key }}</div>
              </template>
              <template #cell()="{ item, field, index }">
                <div class="relative flex items-center user-color">
                  <span v-if="field.key === 'TO #11'" class="absolute left-2 ml-2">$</span>
                  <span v-else class="absolute left-2">$</span>
                  <span
                    class="pl-3 pr-2 w-fit flex flex-nowrap input-editable"
                    contenteditable="true"
                    @input="
                      $event.target.textContent = $event.target.textContent.replace(
                        /[^0-9]/g,
                        ''
                      )
                    "
                    @blur="
                      updateOperatorPayscale(field.key, $event.target.textContent.trim())
                    "
                  >
                    {{ item[field.key] }}
                  </span>
                </div>
              </template>
            </b-table>
          </div>
          <div class="mt-3 flex justify-end">
            <b-button
              variant="primary"
              class="btn-modal"
              @click="closeOperatorPayscaleModal"
              >Close</b-button
            >
          </div>
        </div>
      </b-modal>
    </UIBox>
  </div>
</template>

<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import AuthService from "../../_services/AuthService.js";
import UIBox from "@/components/uibox.vue";
import PaginleftdoubleSVG from "../../components/SVG-icons/paginleftdoubleSVG.vue";
import PaginleftSVG from "../../components/SVG-icons/paginleftSVG.vue";
import PaginrightSVG from "../../components/SVG-icons/paginrightSVG.vue";
import PaginrightdoubleSVG from "../../components/SVG-icons/paginrightdoubleSVG.vue";
import CloseSVG from "../../components/SVG-icons/closeSVG.vue";

export default {
  components: {
    DatePicker,
    UIBox,
    PaginleftdoubleSVG,
    PaginleftSVG,
    PaginrightdoubleSVG,
    PaginrightSVG,
    CloseSVG,
  },
  props: {
    salesRepId: String,
    toCallsFilter: String,
  },
  data() {
    return {
      activeButton: "units",
      showRecruiterBreakdown: false,
      showOperatorPayscale: false,
      fields: [
        { key: "operator", label: "Operator's Name" },
        { key: "mon", label: "Mon" },
        { key: "tue", label: "Tue" },
        { key: "wed", label: "Wed" },
        { key: "thurs", label: "Thurs" },
        { key: "fri", label: "Fri" },
        { key: "sat", label: "Sat" },
        { key: "sun", label: "Sun" },
        { key: "thisWeek", label: "This Week" },
        { key: "lastWeek", label: "Last Week" },
        { key: "thisMonth", label: "This Month" },
        { key: "lastMonth", label: "Last Month" },
        { key: "ytd", label: "Year-to-Date" },
        { key: "all", label: "All" },
      ],
      trackingReport: [],
      breakdownFields: [
        { key: "idNumber", label: "" },
        { key: "operatorName", label: "Operator Name" },
        { key: "calls", label: "Calls" },
        { key: "tos", label: "TO's" },
        { key: "commission", label: "Commission" },
      ],
      recruiterBreakdown: [],
      payscaleFields: [],
      operatorPayscale: [],
      currentWeek: "",
      loading: false,
      role: "",
      userId: "",
      currentPage: 1,
      rowsPerPage: 50,
      totalRows: 0,
      leads: [],
      filteredRows: [],
      showOverlay: false,
      showNotesPopup: false,
      notesCompanyName: "",
      operators: [],
      messages: [],
    };
  },
  computed: {
    visiblePages() {
      const pages = [];
      let startPage = Math.max(this.currentPage - 2, 1);
      let endPage = Math.min(startPage + 4, this.totalPages);

      if (endPage - startPage < 4) {
        startPage = Math.max(endPage - 4, 1);
      }

      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }

      return pages;
    },
    totalPages() {
      return Math.ceil(this.totalRows / this.rowsPerPage);
    },
  },
  methods: {
    async initUser() {
      this.role = await AuthService.getUserRole();
      this.userId = await AuthService.getCognitoSub();
    },
    setActive(buttonType) {
      this.activeButton = buttonType;
    },
    showRecruiterBreakdownModal() {
      this.showRecruiterBreakdown = true;
    },
    closeRecruiterBreakdownModal() {
      this.showRecruiterBreakdown = false;
    },
    showOperatorPayscaleModal() {
      this.showOperatorPayscale = true;
    },
    closeOperatorPayscaleModal() {
      this.showOperatorPayscale = false;
    },
    convertToLocalTimeFormatted(utcDateTime) {
      const local = new Date(utcDateTime);
      let hours = local.getHours();
      const minutes = local.getMinutes();
      const ampm = hours >= 12 ? "PM" : "AM";
      hours = hours % 12;
      hours = hours ? hours : 12;
      const minutesStr = minutes < 10 ? "0" + minutes : minutes;
      return `${hours}:${minutesStr} ${ampm}`;
    },
    formatPhoneNumber(phoneNumber) {
      phoneNumber = phoneNumber.toString();
      const areaCode = phoneNumber.slice(0, 3);
      const centralOfficeCode = phoneNumber.slice(3, 6);
      const stationCode = phoneNumber.slice(6, 10);
      return `(${areaCode}) ${centralOfficeCode}-${stationCode}`;
    },
    sortTable(property) {
      const direction = this.sortDirection === "asc" ? "desc" : "asc";
      this.leads.sort((a, b) => {
        let x = a[property];
        let y = b[property];
        if (property === "date") {
          x = new Date(a.start_time);
          y = new Date(b.start_time);
        }
        if (direction === "asc") {
          return x > y ? 1 : x < y ? -1 : 0;
        } else {
          return x < y ? 1 : x > y ? -1 : 0;
        }
      });
      this.sortDirection = direction;
      this.filteredRows = this.filteredLeadRows();
    },
    async fetchTrackingReport() {
      this.loading = true;
      try {
        console.log("this.salesRepId", this.salesRepId);

        const response = await axios.get("https://123avatars.com/v2/to-tracking-report", {
          params: {
            currentPage: this.currentPage,
            rowsPerPage: this.rowsPerPage,
          },
        });
        this.trackingReport = response.data.trackingReport;
        this.recruiterBreakdown = response.data.recruiterBreakdown;
      } catch (error) {
        console.error("Error fetching tracking report:", error);
      } finally {
        this.loading = false;
      }
    },
    updateFieldLabels() {
      const today = new Date();
      const currentDay = today.getDay();
      const monday = new Date(today);
      monday.setDate(today.getDate() - currentDay + 1);

      const days = ["Mon", "Tue", "Wed", "Thurs", "Fri", "Sat", "Sun"];

      const currentWeek = `${String(monday.getMonth() + 1).padStart(2, "0")}/${String(
        monday.getDate()
      ).padStart(2, "0")}/${String(monday.getFullYear()).slice(-2)}`;
      const todayIndex = currentDay === 0 ? 6 : currentDay - 1;
      this.fields = this.fields.map((field) => {
        if (days.includes(field.key.charAt(0).toUpperCase() + field.key.slice(1))) {
          const dayIndex = days.findIndex((d) => d.toLowerCase() === field.key);
          const date = new Date(monday);
          date.setDate(monday.getDate() + dayIndex);
          const formattedDate = `${String(date.getMonth() + 1).padStart(2, "0")}/${String(
            date.getDate()
          ).padStart(2, "0")}`;
          const hide = dayIndex > todayIndex;

          console.log("hide", hide);
          return { ...field, date: formattedDate, hide };
        }
        return field;
      });

      this.currentWeek = currentWeek;
    },
    async fetchOperatorPayscale() {
      this.loading = true;
      try {
        const response = await axios.get(
          "https://123avatars.com/v2/operator-payscale",
          {}
        );

        const operatorPayscale = response.data.results;

        const updatedFields = [
          operatorPayscale.slice(0, 10).map((item) => `TO #${item.to_num}`),
          operatorPayscale.slice(10).map((item) => `TO #${item.to_num}`),
        ];

        const operatorPayscaleArray = [
          [
            operatorPayscale.slice(0, 10).reduce((acc, item) => {
              acc[`TO #${item.to_num}`] = `${item.amount}`;
              return acc;
            }, {}),
          ],
          [
            operatorPayscale.slice(10).reduce((acc, item) => {
              acc[`TO #${item.to_num}`] = `${item.amount}`;
              return acc;
            }, {}),
          ],
        ];

        this.payscaleFields = updatedFields;
        this.operatorPayscale = operatorPayscaleArray;
      } catch (error) {
        console.error("Error fetching operator payscale:", error);
      } finally {
        this.loading = false;
      }
    },
    async updateOperatorPayscale(to_num, value) {
      const numericToNum = to_num.match(/\d+/)[0];

      axios
        .put(`https://123avatars.com/v2/operator_payscale/${to_num}`, {
          value,
        })
        .then(() => {
          console.log(`Operator payscale for ${to_num} updated successfully.`);
        })
        .catch((error) => {
          console.error(`Error updating operator payscale for ${to_num}:`, error);
        });
    },
  },
  mounted() {
    this.initUser();
    this.updateFieldLabels();
    this.fetchTrackingReport();
    this.fetchOperatorPayscale();
  },
};
</script>

<style scoped lang="scss">
$interactive: var(--interactive);
$interactive-hover: var(--interactive-hover);
$color_white: #ffffff;
$black: #000000;

.btn-modal {
  background-color: $interactive !important;
  color: $color_white !important;
  border: none;
  &:hover {
    background-color: $interactive-hover !important;
  }
}

.input-editable {
  padding: 8px;

  &:focus {
    padding: 5px;
    border: 2px solid $interactive !important;
    background-color: white;
    border-radius: 10px;
  }
}
.user-color {
  color: $interactive !important;
  font-weight: bold
}
</style>
