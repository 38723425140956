<template>
  <div id="inbound-text-popup-container">
    <div v-for="message in messages" :key="message.id" :data-id="message.id" class="inbound-message d-flex align-items-center">
      <div :class="`message-icon message-icon${role.toLowerCase()}`">{{ getMessageIcon(message) }}</div>
      <div class="d-flex flex-column">
        <span class="message-heading">
          <b v-if="message.owner_name && message.owner_name.trim() != ''">{{ message.owner_name }}</b>
          <b v-else>{{ message.company_name }}</b>
          <span v-if="message.owner_name && message.owner_name.trim() != ''"> from <b>{{ message.company_name }}</b></span>
        </span>
        <span class="message-body">{{ truncateText(stripHtml(message.text), 50) }}</span>
      </div>
      <div class="d-flex flex-column align-self-stretch align-items-end justify-content-between ml-auto">
        <span class="message-date">{{ formatTimestamp(message.timestamp) }}</span>
        <chatSVG v-if="message.type == '2'" :size="18" :fill="roleColor()" />
        <emailSVG v-else-if="message.type == '4'" :size="18" :fill="roleColor()" />
      </div>
      <div class="small-buttons-container">
        <ButtonUI
          outline
          variant="icon"
          @click="callProspect(message)"
        >
          <launchindialerSVG :size="14" :fill="roleColor()" />
        </ButtonUI>
        <ButtonUI
          red
          outline
          variant="icon"
          @click="removeMessage(message.id)"
        >
          <closeSVG :size="14" :fill="'red'" />
        </ButtonUI>
      </div>
    </div>
  </div>
</template>

<script>
import AuthService from "../../_services/AuthService.js";
import closeSVG from "../../components/SVG-icons/closeSVG.vue";
import launchindialerSVG from '../../components/SVG-icons/launchindialerSVG.vue';
import emailSVG from '../../components/SVG-icons/emailSVG.vue';
import chatSVG from '../../components/SVG-icons/chatSVG.vue';
import ButtonUI from '../../components/page/ButtonUI.vue';
import SocketService from '../../components/sales/socketService.js';

export default {
  components: {
    AuthService, closeSVG, launchindialerSVG, emailSVG, chatSVG, ButtonUI
  },
  props: {
    userTimezone: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      messages: [],
      messageQueue: [],
      role: "",
    };
  },
  methods: {
    roleColor() {
      const colors = {
        admins: '#A73A18',
        managers: '#2653C7',
        operators: '#46A57C',
        roaming_closer: '#0B7D8A',
      };
      return colors[this.role?.toLowerCase()] || '#46A57C';
    },
    async initUsers() {
      this.role = await AuthService.getUserRole();
      if (this.role === null) {
        this.role = 'Operators';
      }
      this.userId = await AuthService.getCognitoSub();

      this.configureSockets();
    },
    getMessageIcon(message) {
      return message.owner_name && message.owner_name.trim() != '' ? message.owner_name.charAt(0) : message.company_name.charAt(0);
    },
    formatTimestamp(timestamp) {
      const abbrTimezone = this.getTimezoneFromAbbreviation(this.userTimezone);

      const now = new Date();
      const messageTime = new Date(timestamp);

      const nowUnixTimestamp = now.getTime();
      const messageTimeUnixTimestamp = messageTime.getTime();

      const diffInSeconds = Math.floor((nowUnixTimestamp - messageTimeUnixTimestamp) / 1000);

      if (diffInSeconds < 60) return "Just now";
      if (diffInSeconds < 3600) return `${Math.floor(diffInSeconds / 60)}m ago`;
      if (diffInSeconds < 86400) return `${Math.floor(diffInSeconds / 3600)}h ago`;

      const formatter = new Intl.DateTimeFormat("en-US", {
        timeZone: abbrTimezone,
        month: "numeric",
        day: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true
      });

      return formatter.format(messageTime);
    },
    getTimezoneFromAbbreviation(abbreviation) {
      const timezones = {
        'EST': 'America/New_York', // Eastern Standard Time
        'EDT': 'America/New_York', // Eastern Daylight Time
        'CST': 'America/Chicago', // Central Standard Time
        'CDT': 'America/Chicago', // Central Daylight Time
        'MST': 'America/Denver', // Mountain Standard Time
        'MDT': 'America/Denver', // Mountain Daylight Time
        'PST': 'America/Los_Angeles', // Pacific Standard Time
        'PDT': 'America/Los_Angeles', // Pacific Daylight Time
        'AKST': 'America/Anchorage', // Alaska Standard Time
        'AKDT': 'America/Anchorage', // Alaska Daylight Time
        'HST': 'Pacific/Honolulu', // Hawaii Standard Time
        'HDT': 'Pacific/Honolulu' // Hawaii Daylight Time (though Hawaii does not observe DST)
      };

      return timezones[abbreviation] || 'America/Los_Angeles';
    },
    updateTimestamps() {
      console.log('updateTimestamps');
      this.$forceUpdate();
    },
    removeMessage(id) {
      const index = this.messages.findIndex(msg => msg.id === id);
      if (index === -1) return;

      const messageElement = document.querySelector(`.inbound-message[data-id="${id}"]`);
      if (messageElement) {
        messageElement.classList.add("fade-out");
      }

      setTimeout(() => {
        this.messages = this.messages.filter(msg => msg.id !== id);

        if (this.messageQueue.length > 0) {
          const nextMessage = this.messageQueue.shift();
          this.messages.push(nextMessage);
          this.playNotificationAudio();
          this.setupMessageTimeout(nextMessage.id);
        }
      }, 650);
    },
    scheduleRemoval(id) {
      return setTimeout(() => {
        this.removeMessage(id);
      }, 5000);
    },
    async playNotificationAudio() {
      const audioElement = document.createElement('audio');
      audioElement.src = 'https://123avatars.com/uploads/notification-popup-audio.mp3';
      audioElement.autoplay = true;

      document.body.appendChild(audioElement);

      try {
        await audioElement.play();
      } catch (error) {
        console.error("Audio playback failed: ", error);
      }

      audioElement.onended = () => {
        audioElement.remove();
      };
    },
    callProspect(row) {
      console.log(`emit ${row.phone.replace('+', '')} ${row.lead_id} ${this.userId}`);
      this.$emit('open-call-prospect', {
        salesRepId: row.manager_id,
        leadId: row.lead_id,
        phone: row.phone,
        fromInbox: false,
        makeCall: false
      });
    },
    stripHtml(text) {
      return text.replace(/<\/?[^>]+(>|$)/g, "");
    },
    truncateText(text, maxLength) {
      return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
    },
    configureSockets() {
      SocketService.connect();
      SocketService.register(this.userId);
      SocketService.on('new-inbound-message', this.handleNewInboundMessage);
    },
    handleNewInboundMessage(message) {
      if (this.messages.length < 4) {
        this.messages.push(message);
        this.playNotificationAudio();
        this.setupMessageTimeout(message.id);
      } else {
        this.messageQueue.push(message);
      }
    },
    setupMessageTimeout(id) {
      this.$nextTick(() => {
        const messageElement = document.querySelector(`.inbound-message[data-id="${id}"]`);
        let timeoutId = this.scheduleRemoval(id);

        if (messageElement) {
          messageElement.addEventListener('mouseenter', () => {
            clearTimeout(timeoutId);
          });

          messageElement.addEventListener('mouseleave', () => {
            timeoutId = this.scheduleRemoval(id);
          });
        }
      });
    },
  },
  mounted() {
    this.initUsers();
    this.updateInterval = setInterval(this.updateTimestamps, 30000);
  },
  beforeDestroy() {
    clearInterval(this.updateInterval);
  }
};
</script>

<style scoped lang="scss">
$interactive: var(--interactive);

#inbound-text-popup-container {
  margin-top: 15px;
  gap: 15px;
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 10;
  top: 100%;
  right: 15px;

  .inbound-message {
    position: relative;
    padding: 15px;
    gap: 15px;
    font-size: 14px;
    backdrop-filter: blur(5px);
    background-color: rgba($color: #fff, $alpha: 0.5);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
    border-radius: 20px;
    color: black;
    transform: translateX(100%);
    transition: transform 0.65s ease-out, opacity 0.65s ease-out, background 0.35s;
    animation: inboundMessage 0.65s forwards;

    .message-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 45px;
      height: 45px;
      border-radius: 50%;
      font-size: 20px;
      font-weight: 600;
      color: $interactive;

      &.message-iconroaming_closer {
        background-color: rgba($color: #0B7D8A, $alpha: 0.2);
      }
    }

    .message-heading {
      font-size: 15px;
    }

    &:hover {
      background-color: rgba($color: #fff, $alpha: 0.85);
    }

    .small-buttons-container {
      visibility: hidden;
      opacity: 0;
      position: absolute;
      top: 0;
      right: 20px;
      transform: translateY(-50%);
      display: flex;
      gap: 5px;
      transition: visibility 0.35s, opacity 0.35s;

      .btn {
        border-color: var(--interactive);
        color: var(--interactive);
        background-color: rgba($color: #fff, $alpha: 0.6);
        padding: 0;
        width: 30px;
        min-width: unset;
        height: 30px;
        border-radius: 50%;
        transition: background 0.35s;

        svg {
          width: 18px;
          height: auto;
          fill: var(--interactive);
        }

        &:hover {
          background-color: var(--interactive);

          svg {
            fill: white;
          }
        }

        &.red {
          border-color: red;
          color: red;

          svg {
            width: 12px;
            height: auto;
            fill: red;

            ::v-deep(path) {
              fill: red;
            }
          }

          &:hover {
            background-color: red;

            svg {
              fill: white;

              ::v-deep(path) {
                fill: white;
              }
            }
          }
        }
      }
    }

    &:hover {
      .small-buttons-container {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}

.fade-out {
  transform: translateX(100%);
  opacity: 0;
}

@keyframes inboundMessage {
  from {transform: translateX(100%);}
  to {transform: translateX(0);}
}
</style>