<template>
  <div id="live-transfer-container">
    <UIBox classbox="hello" class="m-0" :class="{ loading: isLoading }" classTitle="d-flex align-items-center justify-content-between w-100">
      <template v-slot:title>
        <span class="bold">
          {{ role === 'Roaming_Closer' ? 'My Stats' : 'Live Transfer Tracking' }}
        </span>
        <!-- <ButtonUI outline class="p-1 path-icon" @click="toggleFullscreen" v-if="!isLoading">
          <FullscreenSVG :size="24" />
        </ButtonUI> -->
      </template>
      <template>
        <div
          v-if="role === 'Admins' && managers.length > 0"
          class="horizontal-filter"
        >
          <div class="form-group select">
            <select v-model="selectedManager" class="form-control">
              <option value="all" selected>All Managers</option>
              <option
                v-for="manager in managers"
                :key="manager.id"
                :value="manager.sub"
              >
                {{ manager.name }}
              </option>
            </select>
          </div>

          <ButtonUI
            class="px-3"
            :class="outline"
            @click="processTransfers"
          >
            Process Transfers
          </ButtonUI>
        </div>
        <div v-if="isLoading" class="loader-wrapper">
          <div class="loader">
            <span></span>
          </div>
        </div>
        <div v-if="!isLoading" :class="`sales-table-container sales-table-container${role ? role.toLowerCase() : ''}`">
          <table class="sales-table">
            <!-- Header Row 1 -->
            <thead class="sticky-header sticky-header-top">
              <tr>
                <th rowspan="2" class="sticky-header sticky-header-left index-6"></th>
                <th v-for="rep in salesReps" :key="'headers-' + rep.id" colspan="6" class="sticky-header">
                  {{ rep.name }}
                </th>
                <th class="sticky-header sticky-header-right index-6" colspan="4">Team</th>
              </tr>
              <!-- Header Row 2 -->
              <tr>
                <template v-for="rep in salesReps">
                  <th class="sticky-header">Non-Qualified<br>Live Transfers</th>
                  <th class="sticky-header">Qualified<br>Live Transfers</th>
                  <th class="sticky-header">Total Talk Time</th>
                  <th class="sticky-header">Calls</th>
                  <th class="sticky-header">Sales</th>
                  <th class="sticky-header">Transfers per Sale</th>
                </template>
                <th class="sticky-header sticky-header-right" colspan="4">
                  <div class="d-flex align-items-center justify-content-center">
                    <div style="width: 115px;">Total Non-Qualified<br>Live Transfers</div><span class="m-4">/</span><div style="width: 115px;">Total Qualified<br>Live Transfers</div>
                  </div>
                </th>
              </tr>
            </thead>

            <!-- Body Rows -->
            <tbody>
              <tr v-for="(day, index) in salesData" :key="index">
                <td class="sticky-header sticky-header-left">{{ day.date }}</td>
                <template v-for="rep in salesReps">
                  <td>
                    <span 
                      v-if="role === 'Admins' || role === 'Managers' || (role === 'Roaming_Closer' && rep.id == userId)" 
                      v-b-modal.liveTransfersInfo 
                      class="text-decoration-underline" 
                      @click="openModal(day.date, rep.id, rep.name, 0)"
                    >
                      {{ day[rep.id]?.nonQualified || 0 }}
                    </span>
                    <span v-else>
                      {{ day[rep.id]?.nonQualified || 0 }}
                    </span>
                  </td>

                  <td>
                    <span 
                      v-if="role === 'Admins' || role === 'Managers' || (role === 'Roaming_Closer' && rep.id == userId)" 
                      v-b-modal.liveTransfersInfo 
                      class="text-decoration-underline" 
                      @click="openModal(day.date, rep.id, rep.name, 1)"
                    >
                      {{ day[rep.id]?.qualified || 0 }}
                    </span>
                    <span v-else>
                      {{ day[rep.id]?.qualified || 0 }}
                    </span>
                  </td>

                  <td><span>{{ convertSeconds(day[rep.id]?.totalTalkTime) || 0 }}</span></td>
                  <td><span>{{ day[rep.id]?.totalCalls || 0 }}</span></td>
                  <td><span>{{ day[rep.id]?.personalSales || 0 }}</span></td>
                  <td><span>{{ day[rep.id]?.transfersPerSales || 0 }}</span></td>
                </template>
                <td class="sticky-header sticky-header-right" colspan="4">
                  <div class="d-flex align-items-center justify-content-center">
                    <div 
                      v-if="role === 'Admins' || role === 'Managers'" 
                      v-b-modal.liveTransfersInfo 
                      class="text-decoration-underline" 
                      @click="openModal(day.date, 'all', '', 0)"
                    >
                      {{ calculateDailyTotal(day, 'nonQualified') }}
                    </div>
                    <div v-else>{{ calculateDailyTotal(day, 'nonQualified') }}</div>
                    <span class="m-4">/</span>
                    <div 
                      v-if="role === 'Admins' || role === 'Managers'" 
                      v-b-modal.liveTransfersInfo 
                      class="text-decoration-underline" 
                      @click="openModal(day.date, 'all', '', 1)"
                    >
                      {{ calculateDailyTotal(day, 'qualified') }}
                    </div>
                    <div v-else>{{ calculateDailyTotal(day, 'qualified') }}</div>
                  </div>
                </td>
              </tr>
            </tbody>

            <!-- Footer Row (Totals) -->
            <tfoot class="sticky-header sticky-header-bottom">
              <tr>
                <td class="sticky-header sticky-header-left">Total</td>
                <template v-for="rep in salesReps">
                  <td>
                    <span 
                      v-if="role === 'Admins' || role === 'Managers' || (role === 'Roaming_Closer' && rep.id == userId)" 
                      v-b-modal.liveTransfersInfo 
                      class="text-decoration-underline" 
                      @click="openModal('all', rep.id, rep.name, 0)"
                    >
                      {{ calculateRepTotal(rep.id, 'nonQualified') }}
                    </span>
                    <span v-else>
                      {{ calculateRepTotal(rep.id, 'nonQualified') }}
                    </span>
                  </td>

                  <td>
                    <span 
                      v-if="role === 'Admins' || role === 'Managers' || (role === 'Roaming_Closer' && rep.id == userId)" 
                      v-b-modal.liveTransfersInfo 
                      class="text-decoration-underline" 
                      @click="openModal('all', rep.id, rep.name, 1)"
                    >
                      {{ calculateRepTotal(rep.id, 'qualified') }}
                    </span>
                    <span v-else>
                      {{ calculateRepTotal(rep.id, 'qualified') }}
                    </span>
                  </td>

                  <td><span>{{ convertSeconds(calculateRepTotal(rep.id, 'totalTalkTime')) }}</span></td>
                  <td><span>{{ calculateRepTotal(rep.id, 'totalCalls') }}</span></td>
                  <td><span>{{ calculateRepTotal(rep.id, 'personalSales') }}</span></td>
                  <td><span>{{ calculateRepTotal(rep.id, 'transfersPerSales') }}</span></td>
                </template>
                <td class="sticky-header sticky-header-right" colspan="2">
                  <div class="d-flex align-items-center justify-content-center">
                    <div 
                      v-if="role === 'Admins' || role === 'Managers'" 
                      v-b-modal.liveTransfersInfo 
                      class="text-decoration-underline" 
                      @click="openModal('all', 'all', '', 0)"
                    >
                      {{ calculateGrandTotal('nonQualified') }}
                    </div>
                    <div v-else>{{ calculateGrandTotal('nonQualified') }}</div>
                    <span class="m-4">/</span>
                    <div 
                      v-if="role === 'Admins' || role === 'Managers'" 
                      v-b-modal.liveTransfersInfo 
                      class="text-decoration-underline" 
                      @click="openModal('all', 'all', '', 1)"
                    >
                      {{ calculateGrandTotal('qualified') }}
                    </div>
                    <div v-else>{{ calculateGrandTotal('qualified') }}</div>
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </template>
    </UIBox>

    <!-- Fullscreen Table -->
    <div v-if="isFullscreen" class="fullscreen-overlay">
      <ButtonUI outline class="p-1 path-icon close-fullscreen-btn" @click="toggleFullscreen">
        <EditFullscreenSVG :size="24" />
      </ButtonUI>

      <div :class="`sales-table-container sales-table-container${role ? role.toLowerCase() : ''}`">
        <table class="sales-table">
          <!-- Header Row 1 -->
          <thead class="sticky-header sticky-header-top">
            <tr>
              <th v-for="rep in salesReps" :key="'headers-' + rep.id" colspan="2" class="sticky-header">
                {{ rep.name }}
              </th>
              <th class="sticky-header sticky-header-right index-6" colspan="2">Team</th>
            </tr>
            <!-- Header Row 2 -->
            <tr>
              <template v-for="rep in salesReps">
                <th class="sticky-header">Non-Qualified<br>Live Transfers</th>
                <th class="sticky-header">Qualified<br>Live Transfers</th>
              </template>
              <th class="sticky-header sticky-header-right" colspan="2">
                <div class="d-flex align-items-center justify-content-center">
                  <div style="width: 115px;">Total Non-Qualified<br>Live Transfers</div><span class="m-4">/</span><div style="width: 115px;">Total Qualified<br>Live Transfers</div>
                </div>
              </th>
            </tr>
          </thead>

          <!-- Body Rows -->
          <tbody>
            <tr v-if="lastRecord">
              <template v-for="rep in salesReps">
                <td>
                  <span 
                    v-if="role === 'Admins' || role === 'Managers'" 
                    v-b-modal.liveTransfersInfo 
                    class="text-decoration-underline" 
                    @click="openModal(lastRecord.date, rep.id, rep.name, 0)"
                  >
                    {{ lastRecord[rep.id]?.nonQualified || 0 }}
                  </span>
                  <span v-else>
                    {{ lastRecord[rep.id]?.nonQualified || 0 }}
                  </span>
                </td>

                <td>
                  <span 
                    v-if="role === 'Admins' || role === 'Managers'" 
                    v-b-modal.liveTransfersInfo 
                    class="text-decoration-underline" 
                    @click="openModal(lastRecord.date, rep.id, rep.name, 1)"
                  >
                    {{ lastRecord[rep.id]?.qualified || 0 }}
                  </span>
                  <span v-else>
                    {{ lastRecord[rep.id]?.qualified || 0 }}
                  </span>
                </td>
              </template>
              <td class="sticky-header sticky-header-right" colspan="2">
                <div class="d-flex align-items-center justify-content-center">
                  <div>{{ calculateDailyTotal(lastRecord, 'nonQualified') }}</div><span class="m-4">/</span><div>{{ calculateDailyTotal(lastRecord, 'qualified') }}</div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- <table class="fullscreen-table">
        <thead>
          <tr>
            <th v-for="rep in salesReps" :key="'headers-' + rep.id" colspan="2"> {{ rep.name }} </th>
            <th colspan="2">Team</th>
          </tr>

          <tr>
            <template v-for="rep in salesReps">
              <th>Non-Qualified<br>Live Transfers</th>
              <th>Qualified<br>Live Transfers</th>
            </template>
            <th colspan="2">
              <div class="d-flex align-items-center justify-content-center">
                <div style="width: 115px;">Total Non-Qualified<br>Live Transfers</div><span class="m-4">/</span><div style="width: 115px;">Total Qualified<br>Live Transfers</div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="lastRecord">
            <template v-for="rep in salesReps">
              <td>{{ lastRecord[rep.id]?.nonQualified || 0 }}</td>
              <td>{{ lastRecord[rep.id]?.qualified || 0 }}</td>
            </template>
            <td>
              <div class="d-flex align-items-center justify-content-center">
                <div>{{ calculateDailyTotal(lastRecord, 'nonQualified') }}</div>
                <span class="m-4">/</span>
                <div>{{ calculateDailyTotal(lastRecord, 'qualified') }}</div>
              </div>
            </td>
          </tr>
        </tbody>
      </table> -->
    </div>

    <b-modal
      v-if="role === 'Admins' || role === 'Managers' || role === 'Roaming_Closer'"
      id="liveTransfersInfo"
      :title="modalProps.liveTransfersItems.length + (modalProps.isQualified ? ' Qualified' : ' Non-Qualified') + 'Live Transfers' + (modalProps.rep.id == 'all' ? '' : ' by ' + modalProps.rep.name) + (modalProps.selectedDate == 'all' ? '' : ' on ' + modalProps.rep.name)"
      @hide="handleBeforeModalClose"
    >
      <template #modal-header="{ close }" class="header">
        <div style="color: white;"><span class="bold">{{ modalProps.liveTransfersItems.length }} {{ modalProps.isQualified ? 'Qualified' : 'Non-Qualified' }}</span> Live Transfers{{ modalProps.rep.id == 'all' ? '' : ' by ' }}<span class="bold" v-if="modalProps.rep.id != 'all'">{{ modalProps.rep.name }}</span>{{ modalProps.selectedDate == 'all' ? '' : ' on ' }}<span class="bold" v-if="modalProps.selectedDate != 'all'">{{ modalProps.selectedDate }}</span></div>
        <button class="btn" @click="close">
          <closeSVG fill="#fff" />
        </button>
      </template>
      <div class="table-fullwidth">
        <table class="table">
          <thead>
            <tr>
              <th v-for="{key} in modalProps.liveTransfersFields" class="text-nowrap" :class="{ 'text-center': key === 'Play' }">{{ key }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!modalProps.liveTransfersItems || modalProps.liveTransfersItems.length === 0">
              <td :colspan="modalProps.liveTransfersFields.length" class="text-center">{{ !modalProps.liveTransfersItems ? `Loading...` : `No ${modalProps.isQualified ? 'qualified' : 'non-qualified'} live transfers found.` }}</td>
            </tr>
            <template v-else>
              <tr v-for="(liveTransfer, index) in modalProps.liveTransfersItems">
                <td class="text-nowrap">{{ liveTransfer.operatorName }}</td>
                <td class="text-nowrap">{{ liveTransfer.salesRepName }}</td>
                <td class="text-nowrap">{{ liveTransfer.managerName }}</td>
                <td class="text-nowrap">{{ formatDateTime(liveTransfer.start_time) }}</td>
                <td class="text-nowrap">{{ formatDateTime(liveTransfer.start_time, true) }}</td>
                <td class="text-nowrap">{{ convertSeconds(liveTransfer.duration) }}</td>
                <td class="text-nowrap">{{ convertSeconds(liveTransfer.bargeDuration) }}</td>
                <td>
                  <VueAudioPlayer ref="audioPlayer" 
                    :ref="`player${index}`"
                    :audio-list="[{ src: liveTransfer.recordingUrl }]"
                    :before-play="(next) => handleBeforePlay(next, index)" :show-play-loading="false" :theme-color="`${roleColor()}`"
                    :isLoop="false"
                  >
                  </VueAudioPlayer>
                </td>
                <td class="text-nowrap">{{ liveTransfer.gptQualified === 1 ? 'Yes' : 'No' }}</td>
                <td class="text-nowrap">{{ liveTransfer.snippet }}</td>
                <td>
                  <div class="transcript-cell-content">
                    {{ liveTransfer.transcript }}
                  </div>
                </td>
                <td class="text-nowrap">{{ liveTransfer.company_name }}</td>
                <td class="text-nowrap">{{ liveTransfer.state }}</td>
                <td class="text-nowrap">{{ liveTransfer.timezone }}</td>
                <td class="text-nowrap">{{ liveTransfer.shelf_life }}</td>
                <td class="text-nowrap">{{ convertSeconds(liveTransfer.total_talk_time) }}</td>
                <td class="text-nowrap">{{ liveTransfer.calls }}</td>
                <td class="text-nowrap">{{ liveTransfer.texts }}</td>
                <td class="text-nowrap">-</td>
              </tr>
              <tr>
                <td class="font-weight-bold">Total</td>
                <td colspan="4"></td>
                <td class="font-weight-bold">{{ convertSeconds(calculateLiveTransfersTotal('duration')) }}</td>
                <td colspan="5"></td>
                <td class="font-weight-bold">{{ convertSeconds(calculateLiveTransfersTotal('total_talk_time')) }}</td>
                <td class="font-weight-bold">{{ calculateLiveTransfersTotal('calls') }}</td>
                <td class="font-weight-bold">{{ calculateLiveTransfersTotal('texts') }}</td>
                <td class="font-weight-bold">-</td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <template #modal-footer="{ close }">
        <div class="buttons">
          <b-button class="btn solid icon" @click="close">Close</b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import AuthService from "../../_services/AuthService.js";
import UIBox from '../../components/uibox.vue';
import closeSVG from "../../components/SVG-icons/closeSVG.vue";
import FullscreenSVG from "../../components/SVG-icons/fullscreenSVG.vue";
import EditFullscreenSVG from "../../components/SVG-icons/editFullscreenSVG.vue";
import ButtonUI from '../../components/page/ButtonUI.vue';
import { setTimeout } from "core-js";
import axios from "axios";

export default {
  components: {
    AuthService, UIBox, closeSVG, FullscreenSVG, EditFullscreenSVG, ButtonUI
  },
  props: {
    userTimezone: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isLoading: true,
      salesReps: [],
      salesData: [],
      role: "",
      userId: "",
      modalProps: {
        selectedDate: "",
        rep: {},
        isQualified: false,
        liveTransfersItems: false,
        liveTransfersFields: [
          {
            key: 'Operator Name',
          },
          {
            key: 'Sales Rep Name',
          },
          {
            key: 'Manager Name',
          },
          {
            key: 'Call Date',
          },
          {
            key: 'Call Time',
          },
          {
            key: 'Total Duration',
          },
          {
            key: 'Rep Duration',
          },
          {
            key: 'Play',
          },
          {
            key: 'GPT Qualified',
          },
          {
            key: 'Snippet', 
          },
          {
            key: 'Transcript',
          },
          {
            key: 'Company Name',
          },
          {
            key: 'State',
          },
          {
            key: 'Timezone',
          },
          {
            key: 'Age of Lead',
          },
          {
            key: 'Total Talk Time',
          },
          {
            key: 'Calls',
          },
          {
            key: 'Texts',
          },
          {
            key: 'Emails',
          },
        ],
      },
      currentPlayingIndex: -1,
      selectedManager: 'all',
      managers: [],
      isFullscreen: false,
      cancelToken: null,
      fetchTimeout: null,
      isActive: true,
    };
  },
  methods: {
    async processTransfers() {
      try {
        const response = await axios.post('https://123avatars.com/v2/process-transfers', {});
        // handle success (e.g., show success message, refresh data, etc.)
        console.log('Transfers processed:', response.data);
      } catch (error) {
        // handle error (e.g., show error notification)
        console.error('Error processing transfers:', error);
      }
    },
    calculateDailyTotal(day, type) {
      return this.salesReps.reduce(
        (total, rep) => total + (day[rep.id]?.[type] || 0),
        0
      );
    },
    calculateRepTotal(repId, type) {
      return this.salesData.reduce(
        (total, day) => total + (day[repId]?.[type] || 0),
        0
      );
    },
    calculateLiveTransfersTotal(type) {
      return this.modalProps.liveTransfersItems.reduce(
        (total, item) => total + (parseInt(item[type]) || 0),
        0
      );
    },
    calculateGrandTotal(type) {
      return this.salesData.reduce((total, day) => {
        return (
          total +
          this.salesReps.reduce(
            (repTotal, rep) => repTotal + (day[rep.id]?.[type] || 0),
            0
          )
        );
      }, 0);
    },
    async initUsers() {
      this.role = await AuthService.getUserRole();
      if (this.role === null) {
        this.role = 'Operators';
      }
      this.userId = await AuthService.getCognitoSub();
    },
    async fetchSalesData(silentRefresh = false) {
      if (!this.isActive) return;

      try {
        if (this.cancelToken) {
          this.cancelToken.cancel("Request aborted due to new request.");
        }

        this.cancelToken = axios.CancelToken.source();

        if (!silentRefresh) this.isLoading = true;

        const params = {
          role: this.role,
          sub: this.userId,
          selectedManager: this.selectedManager,
        };

        const response = await axios.get(
          `https://123avatars.com/v2/live-transfer-tracking`,
          {
            params,
            cancelToken: this.cancelToken.token,
          }
        );

        this.salesData = response.data.salesData;
        this.salesReps = response.data.salesReps;
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error("Error fetching sales data:", error);
        }
      } finally {
        if (!silentRefresh) this.isLoading = false;

        clearTimeout(this.fetchTimeout);
        if (!this.isLoading) {
          this.fetchTimeout = setTimeout(() => {
            this.fetchSalesData(true);
          }, 5000);
        }
      }
    },
    stopFetching() {
      this.isActive = false;

      if (this.cancelToken) {
        this.cancelToken.cancel("Component unmounted, request aborted.");
      }

      if (this.fetchTimeout) {
        clearTimeout(this.fetchTimeout);
      }
    },
    openModal(date, id, name, isQualified) {
      this.stopAudio();

      this.modalProps.selectedDate = date;
      this.modalProps.rep.id = id;
      this.modalProps.rep.name = name;
      this.modalProps.isQualified = isQualified;

      this.fetchLiveTransfers();
    },
    resetScroll() {
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
    },
    async fetchLiveTransfers() {
      try {
        this.modalProps.liveTransfersItems = false;

        const params = new URLSearchParams({
          type: this.modalProps.isQualified,
          sub: this.modalProps.rep.id,
          date: this.modalProps.selectedDate,
          selectedManager: this.selectedManager
        });

        const response = await fetch(`https://123avatars.com/v2/live-transfer-tracking/live-transfers?${params}`);
        const data = await response.json();

        this.modalProps.liveTransfersItems = data.liveTransfers;
      } catch (error) {
        console.error('Error fetching live transfers:', error);
      }
    },
    formatDateTime(date, isTime = false) {
      const dateObj = new Date(date);
      const formatOptions = isTime ? {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
      } : {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
      };

      return new Intl.DateTimeFormat('en-US', {
        timeZone: this.getTimezoneFromAbbreviation(this.userTimezone),
        ...formatOptions
      }).format(dateObj);
    },
    getTimezoneFromAbbreviation(abbreviation) {
      const timezones = {
        'EST': 'America/New_York', // Eastern Standard Time
        'EDT': 'America/New_York', // Eastern Daylight Time
        'CST': 'America/Chicago', // Central Standard Time
        'CDT': 'America/Chicago', // Central Daylight Time
        'MST': 'America/Denver', // Mountain Standard Time
        'MDT': 'America/Denver', // Mountain Daylight Time
        'PST': 'America/Los_Angeles', // Pacific Standard Time
        'PDT': 'America/Los_Angeles', // Pacific Daylight Time
        'AKST': 'America/Anchorage', // Alaska Standard Time
        'AKDT': 'America/Anchorage', // Alaska Daylight Time
        'HST': 'Pacific/Honolulu', // Hawaii Standard Time
        'HDT': 'Pacific/Honolulu' // Hawaii Daylight Time (though Hawaii does not observe DST)
      };

      return timezones[abbreviation] || 'America/Los_Angeles';
    },
    convertSeconds(seconds) {
      if (seconds >= 3600) {
        const hours = Math.floor(seconds / 3600);
        const remainingMinutes = Math.floor((seconds % 3600) / 60);
        return `${hours}h ${remainingMinutes}m`;
      } else if (seconds >= 60) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}m ${remainingSeconds}s`;
      } else {
        return `${seconds}s`;
      }
    },
    roleColor() {
      const colors = {
        admins: '#A73A18',
        managers: '#2653C7',
        operators: '#46A57C',
        roaming_closer: '#0B7D8A',
      };
      return colors[this.role?.toLowerCase()] || '#46A57C';
    },
    handleBeforePlay(next, index) {
      this.stopAudio();
      this.currentPlayingIndex = index;

      next();
    },
    playNext() {
      try {
        // Stop current player if exists
        if (this.currentPlayingIndex >= 0 && this.$refs[`player${this.currentPlayingIndex}`]) {
          try {
            this.$refs[`player${this.currentPlayingIndex}`][0].pause();
          } catch (error) {
          }
        }

        // Move to next item
        this.currentPlayingIndex++;

        // Check if we've reached the end
        if (this.currentPlayingIndex >= this.modalProps.liveTransfersItems.length) {
          this.currentPlayingIndex = -1;
          // Stop current playing audio/video
          if (this.$refs[`player${this.currentPlayingIndex}`]) {
            this.$refs[`player${this.currentPlayingIndex}`][0].pause();
          }
          return;
        }

        // Get the player for this index
        const player = this.$refs[`player${this.currentPlayingIndex}`][0];
        if (player) {
          if (player.duration > 0) {
            player.play();
          } else {
            this.playNext();    
          }
        }
      } catch (error) {
        this.playNext();
      }
    },
    stopAudio() {
      if (this.currentPlayingIndex >= 0 && this.$refs[`player${this.currentPlayingIndex}`]) {
        try {
          this.$refs[`player${this.currentPlayingIndex}`][0].pause();
          this.$refs[`player${this.currentPlayingIndex}`][0].currentTime = 0; // Reset time
        } catch (error) {
          console.warn("Error stopping audio:", error);
        }
      }
      this.currentPlayingIndex = -1; // Reset playing index
    },
    handleBeforeModalClose() {
      this.stopAudio();
    },
    async fetchManagers() {
      try {
        const response = await fetch('https://123avatars.com/v2/managers');
        this.managers = await response.json();
      } catch (error) {
        console.error('Error fetching managers:', error);
      }
    },
    toggleFullscreen() {
      this.isFullscreen = !this.isFullscreen;
      if (this.isFullscreen) {
        document.documentElement.requestFullscreen();
      } else {
        if (document.fullscreenElement) document.exitFullscreen();
      }
    },
  }, 
  computed: {
    lastRecord() {
      return this.salesData.length > 0 ? this.salesData[0] : [];
    }
  },
  watch: {
    selectedManager() {
      this.fetchSalesData();
    },
  },
  async mounted() {
    this.isActive = true;
    this.resetScroll();

    await this.initUsers();
    await this.fetchManagers();

    if (this.role === 'Managers') this.selectedManager = this.userId;
    await this.fetchSalesData();
  },
  beforeDestroy() {
    this.stopFetching();
  }
};
</script>

<style scoped lang="scss">

.horizontal-filter {
  display: flex;
  align-items: center;
  gap: 1rem; /* Adjust spacing as needed */
}

$interactive: var(--interactive);

.sales-table-container {
  width: 100%;
  position: relative;
  overflow-x: auto;
}

.sales-table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
}

.sales-table th,
.sales-table td {
  white-space: nowrap;
  border: 1px solid #000;
  padding: 10px 20px;
  font-weight: 400;
}

.sticky-header {
  position: sticky;

  &.sticky-header-left {
    left: 0;
    z-index: 0;

    &.index-6 {
      z-index: 6;
    }
  }

  &.sticky-header-right {
    right: 0;
    z-index: 0;

    &.index-6 {
      z-index: 6;
    }
  }

  &.sticky-header-top {
    top: 0;
    z-index: 5;
  }

  &.sticky-header-bottom {
    bottom: 0;
    z-index: 5;
  }
}

.sales-table-containeradmins tfoot,
.sales-table-containeradmins .sticky-header {
  background-color: #f2e2dd;
}

.sales-table-containerroaming_closer tfoot,
.sales-table-containerroaming_closer .sticky-header {
  background-color: #dbecee;
}

.sales-table-containermanagers tfoot,
.sales-table-containermanagers .sticky-header {
  background-color: #dfe5f7;
}

.sales-table-containeroperators tfoot,
.sales-table-containeroperators .sticky-header {
  background-color: #e3f2eb;
}

#live-transfer-container {
  ::v-deep(.hello){
    height: calc(100vh - 105px - 60px);
    display: flex;
    flex-direction: column;
  }

  ::v-deep(.hello .content) {
    gap: 0;
    overflow: hidden;
    flex-wrap: unset;
  }

  ::v-deep(.hello.loading .content) {
    position: relative;
    flex-grow: 1;
  }

  ::v-deep(.hello.loading .content .loader-wrapper) {
    position: absolute;
    background-color: transparent;
  }

  ::v-deep(.hello.loading .content .loader-wrapper .loader) {
    border-color: $interactive rgba(255, 255, 255, 0);
  }
}

::v-deep(#liveTransfersInfo .modal-dialog) {
  max-width: unset;
  margin: 1.75rem;
}

.text-decoration-underline {
  cursor: pointer;
  text-decoration: underline;
}

/* Fullscreen overlay */
.fullscreen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f7fff9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 20px;

  td {
    font-size: 28px;
    font-weight: 700;
  }
}

/* Fullscreen table */
.fullscreen-table {
  width: 90%;
  border-collapse: collapse;
  text-align: center;
  font-size: 24px;
}

.fullscreen-table th,
.fullscreen-table td {
  border: 1px solid black;
  padding: 15px;
}

/* Close Fullscreen Button */
.close-fullscreen-btn {
  position: absolute;
  top: 20px;
  right: 20px;
}

.transcript-cell-content {
  max-width: 500px;       /* Increase from 300px to 400px for a wider display */
  width: 100%;
  white-space: pre-wrap;
  word-wrap: break-word;
  max-height: 150px;      
  overflow-y: auto;       
  padding: 4px;           
  border: 1px solid #ddd;
}
/* More specific selector to override the parent's nowrap */
.sales-table td .transcript-cell-content {
  white-space: pre-wrap !important; 
}

</style>