<template>
  <svg class="magicPenSVG" :style="{ width: size + 'px', height: 'auto' }" viewBox="0 0 20 20" fill="none">
    <path d="M19.6482 3.67224C19.8833 3.93308 20 4.23338 20 4.57143C20 4.90948 19.8833 5.19605 19.6482 5.43115L5.42943 19.6482C5.19434 19.8833 4.90776 20 4.56971 20C4.23166 20 3.93136 19.8833 3.67053 19.6482L0.35178 16.3278C0.116688 16.0669 0 15.7683 0 15.4286C0 15.0888 0.116688 14.8039 0.35178 14.5689L14.5706 0.35178C14.8057 0.116688 15.0905 0 15.4303 0C15.7701 0 16.0686 0.116688 16.3295 0.35178L19.6499 3.67224H19.6482ZM3.12484 6.24968L2.0695 4.18018L0 3.12484L2.0695 2.0695L3.12484 0L4.18018 2.0695L6.24968 3.12484L4.18018 4.18018L3.12484 6.24968ZM8.74989 3.74946L8.12527 2.50021L6.87602 1.87559L8.12527 1.25097L8.74989 0.001716L9.37452 1.25097L10.6238 1.87559L9.37452 2.50021L8.74989 3.74946ZM14.0232 7.92964L17.4208 4.56971L15.4286 2.57743L12.0686 5.97512L14.0214 7.92793L14.0232 7.92964ZM16.8752 11.2501L17.9305 13.3196L20 14.3749L17.9305 15.4303L16.8752 17.4998L15.8198 15.4303L13.7503 14.3749L15.8198 13.3196L16.8752 11.2501Z" :fill="fill"/>
  </svg>
</template>

<script>
export default {
   name: 'magicPenSVG',
   props: {
    size: {
      type: [Number, String],
      default: 20
    },
    fill: {
      type: String,
      default: '#1D6F81'
    }
  }
}
</script>