<template>
  <div class="todays-stats">
    <div class="ts-container">
      <span class="text-center">Calls</span>
      <h4 v-if="isLoading" class="text-center"><i class="fa-solid fa-circle-notch spin"></i></h4>
      <h4 v-else class="text-center">{{ todaysStats.calls.toLocaleString('en-US') }}</h4>
    </div>
    <div class="ts-container">
      <span class="text-center">Talk Time</span>
      <h4 v-if="isLoading" class="text-center"><i class="fa-solid fa-circle-notch spin"></i></h4>
      <h4 v-else class="text-center">{{ convertSeconds(todaysStats.talkTime) }}</h4>
    </div>
    <div class="ts-container">
      <span class="text-center">Transfers</span>
      <h4 v-if="isLoading" class="text-center"><i class="fa-solid fa-circle-notch spin"></i></h4>
      <h4 v-else class="text-center">{{ todaysStats.transfers.toLocaleString('en-US') }}</h4>
    </div>
    <div class="ts-container">
      <span>Personal Sales</span>
      <h4 v-if="isLoading" class="text-center"><i class="fa-solid fa-circle-notch spin"></i></h4>
      <h4 v-else class="text-center">{{ todaysStats?.personalSales || 0 }}</h4>
    </div>
    <div class="ts-container">
      <span>All Sales</span>
      <h4 v-if="isLoading" class="text-center"><i class="fa-solid fa-circle-notch spin"></i></h4>
      <h4 v-else class="text-center">{{ todaysStats?.totalSales || 0 }}</h4>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import AuthService from "../../_services/AuthService.js";

export default {
  components: {
    AuthService
  },
  data() {
    return {
      isLoading: true,
      userId: "",
      role: "",
      todaysStats: {
        calls: 0,
        talkTime: 0,
        transfers: 0,
        personalSales: 0,
        totalSales: 0
      },
      cancelToken: null,
      fetchTimeout: null,
      isActive: true,
    };
  },
  methods: {
    async initUsers() {
      this.role = await AuthService.getUserRole();
      if (this.role === null) {
        this.role = 'Operators';
      }
      this.userId = await AuthService.getCognitoSub();
      this.fetchTodaysStats();
    },
    async fetchTodaysStats(silentRefresh = false) {
      if (!this.isActive) return;

      try {
        if (this.cancelToken) {
          this.cancelToken.cancel("Request aborted due to new request.");
        }

        this.cancelToken = axios.CancelToken.source();

        if (!silentRefresh) this.isLoading = true;

        const params = {
          role: this.role,
          sub: this.userId,
        };

        const response = await axios.get(
          `https://123avatars.com/v2/todays-stats`,
          {
            params,
            cancelToken: this.cancelToken.token,
          }
        );

        const todaysStatsData = response.data;

        if (todaysStatsData.length > 0) this.todaysStats = todaysStatsData[0];
        else this.todaysStats = { calls: 0, talkTime: 0, transfers: 0, personalSales: 0, totalSales: 0 };
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error("Error fetching todays stats:", error);
        }
      } finally {
        if (!silentRefresh) this.isLoading = false;

        clearTimeout(this.fetchTimeout);
        if (!this.isLoading) {
          this.fetchTimeout = setTimeout(() => {
            this.fetchTodaysStats(true);
          }, 5000);
        }
      }
    },
    stopFetching() {
      this.isActive = false;

      if (this.cancelToken) {
        this.cancelToken.cancel("Component unmounted, request aborted.");
      }

      if (this.fetchTimeout) {
        clearTimeout(this.fetchTimeout);
      }
    },
    convertSeconds(seconds) {
      if (seconds >= 3600) {
        const hours = Math.floor(seconds / 3600);
        const remainingMinutes = Math.floor((seconds % 3600) / 60);
        return `${hours}h ${remainingMinutes}m`;
      } else if (seconds >= 60) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}m ${remainingSeconds}s`;
      } else {
        return `${seconds}s`;
      }
    },
  },
  mounted() {
    this.isActive = true;
    this.initUsers();
  },
  beforeDestroy() {
    this.stopFetching();
  }
};
</script>

<style scoped lang="scss">
.todays-stats {
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  margin-left: 25px;
  display: flex;
  gap: 25px;
  white-space: nowrap;
}

.todays-stats .ts-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.todays-stats .ts-container span {
  font-weight: 400;
  opacity: 0.8;
}

.todays-stats .ts-container h4 {
  margin: 0;
}

.spin {
  animation: spin 0.65s infinite;
}

@keyframes spin {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}
</style>